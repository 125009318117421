import { memo, useEffect, useRef, useState } from 'react';
import { getEnv } from "@urecruits/api";
import { store, useTypedSelector } from "../../store";
import {
	setCalendarPopupCandidateScoreboard,
	setUpdateRoundPopupCandidateScoreboard,
	setWorkflowsScreenScoreboardProfile
} from "../../store/reducers/candidateScoreboardScreenReducer";
import { RoundStatuses } from "../../enums/detail-view-job/detail-view-job-matched";
import useClickOutside from "../../hook/useClickOutside";
import fetchData, { patchData, postData } from "../../hook/http";
import { useNavigate, useParams } from "react-router-dom";
import { components, default as ReactSelect } from "react-select";
import { selectCustomStyle } from "../../styles/selectCustomStyle";
import { ICheckBoxList } from "../../types/global/global";
import { AuthGuard } from '@ucrecruits/globalstyle/components/AuthProvider';
import { Button } from '@ucrecruits/globalstyle/src/ucrecruits-globalstyle';
import { decodeToken } from '@ucrecruits/globalstyle/utils/utils';
const plus_ic = require("./../../image/icon/plus_ic.svg");

const getInfo = state => state.candidate_scoreboard.updateRoundPopup
const {API_RECRUITMENT} = getEnv()
const date = state => state.candidate_scoreboard.calendarPopup
const getDataFuncExtra = state => state.candidate_scoreboard.workflows
const getJobFuncExtra = state => state.candidate_scoreboard.job

const UpdatePopupCandidateScoreboard = () => {
	const [errorNotification, setErrorNotification] = useState('')
	const [score, setScore] = useState('');
	const [meetingTitle, setMeetingTitle] = useState('');
	const [meetingTitleError, setMeetingTitleError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [scoreError, setScoreError] = useState('');
	const [timeError, setTimeError] = useState(false);
	const [review, setReview] = useState('');
	const [guests, setGuests] = useState([]);
	const [managerList, setManagerList] = useState<Array<ICheckBoxList>>([]);
	const {open, id, currentWorkflow,scoreboardId} = useTypedSelector(getInfo)
	const {open: openCalendar, startDate, endDate} = useTypedSelector(date)
	const popupRef = useRef();
	const workflows = useTypedSelector(getDataFuncExtra)
	const job = useTypedSelector(getJobFuncExtra)
	const params=useParams()
	const [showStatusDropdown, setShowStatusDropdown] = useState(false);
	const dropdownRef = useRef();
	useClickOutside(dropdownRef, setShowStatusDropdown)
	const [statusWorkflow, setStatusWorkflow] = useState(getStatus(currentWorkflow.status));
	const onSubmit = async(e) => {
		e.preventDefault()
		const workflowData = {
			jobId:params.jobid,
			userId:params.id,
			id,
			rounds: workflows.map(item => {
				return item.title===currentWorkflow.title?{...item,
					status: statusWorkflow.currentStatus,
					date: {start:startDate||item.date.start,end: statusWorkflow.currentStatus==="Completed"? new Date() : endDate||item.date.end},
					interviewers: guests.length>0?guests:item.interviewers,
					score: score||null,
					comments: review||null
				}:(
					statusWorkflow.currentStatus===RoundStatuses.Rejected&&item.status===RoundStatuses.Pending?{...item,status:RoundStatuses.Rejected}:item
				)
			})
		}
		setIsLoading(true)
		try {
		await patchData(`${API_RECRUITMENT}/api/round`, workflowData).then((res)=> {
				store.dispatch(setWorkflowsScreenScoreboardProfile(workflowData.rounds))
			})
			if(startDate||endDate){
				const userId = await decodeToken().then(data => data["https://urecruits.com/userId"]);
				const slot = startDate.toLocaleString("en-US", {
					hour: "numeric",
					minute: "numeric",
					hour12: true,
				  })
				const body = {
					date: startDate?.toISOString().split('T')[0],
					slot,
					eventTitle: job?.title,
					remindMe: false,
					jobId: params.jobid,
					candidateId:params.id,
					eventId:currentWorkflow?.eventId||null,
					userId: userId,
					utcStartDate:startDate?.toISOString(),
					utcEndDate:endDate?.toISOString(),
					interviewers: guests?.map(i=>({id:i.userId,email:i.email,name:i.label})),
					timeBefore: ``,
					recruiterId: userId,
					description: currentWorkflow?.title,
					timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
				  };
				  await postData(`${API_RECRUITMENT}/api/calendar/book-appointment`, body).then((res) =>console.log(res,"res"))

			}
			const globalStatus=()=>{
				if(workflows[workflows.length-1]?.title===currentWorkflow.title&&statusWorkflow.currentStatus===RoundStatuses.Completed){
					return RoundStatuses.Completed
				}
				if(statusWorkflow.currentStatus===RoundStatuses.Rejected) return RoundStatuses.Rejected
				return RoundStatuses.Progress
			}
			await patchData(`${API_RECRUITMENT}/api/subscribe/company`, {
				status:globalStatus(),
				id:scoreboardId
			}).then((res)=> {})
			store.dispatch(setUpdateRoundPopupCandidateScoreboard({currentWorkflow:{},open:false, id:-1,scoreboardId:-1}))
		
		} catch (error) {
			console.log(error,"error");
			
		}
		finally{
setIsLoading(false)
		}
	
	}
	useEffect(() => {
		setReview('')
		setScore('')
	}, [statusWorkflow.currentStatus]);

	useEffect(()=>{
		if(job?.title){
			setMeetingTitle(job?.title)
		}
	},[job])


	useEffect(() => {
		const getData=async()=>{
			const getUserId = async () => await decodeToken().then(data => data["https://urecruits.com/userId"]);
			const userId = await getUserId();
			const managersList = await fetchData(`${API_RECRUITMENT}/api/company/get-lead-users?${userId ? `excludedId=${userId}` : ''}`);
			setManagerList(managersList.map((item) => {
				return {...item,
					label: `${item.firstname} ${item.lastname}`,
					value: item.recruiter?.id,
					email: item.email,
					id: item.recruiter?.id,
					userId:item.id
				};
			}));
		}
		getData().then()
		return () => {
			store.dispatch(setCalendarPopupCandidateScoreboard({
				open: false,
				startDate: undefined,
				endDate: undefined
			}))
		};
	}, []);
	useEffect(() => {
		setTimeError(false)
	}, [endDate, startDate]);


	return (
		<div className="popup">
			<form className="popup__step" onSubmit={e => onSubmit(e)} ref={popupRef}>
				<div className="popup__head">
					<p className="popup__head__headline">
						Update Rounds - <span style={{color:'#099C73FF'}}>{currentWorkflow.title}</span>
					</p>
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
						onClick={() => store.dispatch(setUpdateRoundPopupCandidateScoreboard({
							open: false,
							id: -1,
							currentWorkflow: {},
							scoreboardId:-1
						}))}
						className="popup__head__close"
					>
						<path d="M18 6L12 12M6 18L12 12M12 12L6 6L18 18" stroke="#C1C5CB" strokeWidth="1.5"
						      strokeLinecap="round"
						      strokeLinejoin="round"/>
					</svg>
				</div>
				<div className="popup__body">
					<div className="recruitment__popup-item">
						<div style={{display: 'flex', alignItems: 'center'}}>
							<p className="recruitment__popup-item__label" style={{marginBottom: 0, marginRight: '10px'}}>
								Status:
							</p>
							<div className='table-dropdown-with-color-btn' style={{height: '39px', maxWidth: "125px"}}>
								<div className={
									`${showStatusDropdown ? 'table-dropdown-with-color-btn__wrap active' :
										'table-dropdown-with-color-btn__wrap'}`
								}
								     style={{transform: 'translate(0px, 0px)'}}
								     ref={dropdownRef}>
									<button className='manage-team__text manage-team__text--black table-dropdown-with-color-btn__button '
									        type={"button"}
									        onClick={() => setShowStatusDropdown(prev => !prev)}>
										<p className={showStatusDropdown ? '' : statusWorkflow.classname}>{statusWorkflow.currentStatus}</p>
										<SVG/>
									</button>
									<ul className='table-dropdown-with-color-btn__list'>
										{
											[RoundStatuses.Completed, RoundStatuses.Rejected, RoundStatuses.Progress, RoundStatuses.Pending].map((roleValue, indexStatus) => {
												return <li
													className='manage-team__text manage-team__text--black table-dropdown-with-color-btn__item'
													key={indexStatus} onClick={() => {
													setStatusWorkflow(getStatus(roleValue))
													setShowStatusDropdown(false)
												}}>
													{roleValue}
												</li>
											})
										}
									</ul>
								</div>
							</div>
						</div>

					</div>
					{
						(statusWorkflow.currentStatus === RoundStatuses.Completed || statusWorkflow.currentStatus === RoundStatuses.Rejected) &&
            <div className="recruitment__popup-item">
                <label className="recruitment__popup-item__label" htmlFor="title">
                    Score <span> *</span>
                </label>
                <input
                    className="recruitment__popup-item__input" id="title"
                    value={score}
                    pattern={'^[0-9]$|^[1-9][0-9]$|^(100)$'}
                    required
                    onInvalid={(e) => {
											e.preventDefault()
											setScoreError(score === '' ? 'This is required field' : 'Not valid format, score should be number 0-100')
										}}
                    placeholder="Select score"
                    onChange={(e) => {
											setScore(e.target.value)
											setScoreError('')
										}}
                />
							{
								scoreError !== '' && <p className="recruitment__popup-item__error-message">{scoreError}</p>
							}
            </div>
					}
					{
						(statusWorkflow.currentStatus === RoundStatuses.Completed || statusWorkflow.currentStatus === RoundStatuses.Rejected) &&
            <div className="recruitment__popup-item">
                <label className="recruitment__popup-item__label" htmlFor="review">
                    Interview Comments
                </label>
                <textarea
                    className="recruitment__popup-item__input" id="review"
                    value={review}
                    placeholder="Here you can left comments"
                    onChange={(e) => {
											setReview(e.target.value)
										}}
                    style={{minHeight: '140px', resize: "none"}}
                />
            </div>
					}
					{
						statusWorkflow.currentStatus === RoundStatuses.Progress && (currentWorkflow.title === 'HR Audio Video Interview' || currentWorkflow.title === 'Technical Audio Video Interview' || currentWorkflow.title === 'Senior HR Audio Video Interview') && <>
                <div className="recruitment__popup-item">
                    <label className="recruitment__popup-item__label" htmlFor="titlemeet">
                        Title of meeting <span> *</span>
                    </label>
                    <input
                        className="recruitment__popup-item__input" id="titlemeet"
                        value={meetingTitle}
                        required
                        placeholder="Enter title"
                    />
									{
										meetingTitleError &&
                    <p className="recruitment__popup-item__error-message">This is required field</p>
									}
                </div>
                <div className={`recruitment__popup-item`}>
                    <p className="recruitment__popup-item__label">Date & Time<span> *</span></p>
                    <div className="choose-date">
                        <div className='choose-date__inner'>
                            <p className='choose-date__text'>
                                Start:
                                <input value={startDate ? startDate?.toLocaleDateString('en-US', {
																	day: 'numeric',
																	month: 'numeric',
																	minute: 'numeric',
																	year: 'numeric',
																	hour: 'numeric'
																}) : ''}
                                       required
                                       placeholder={'Please select date'}
                                       onInvalid={(e) => {
																	       e.preventDefault()
																	       setTimeError(() => true)
																       }}
                                       type={"text"}/>
                            </p>
                            <p className='choose-date__text'>
                                End:
                                <input value={endDate ? endDate?.toLocaleDateString('en-US', {
																	day: 'numeric',
																	month: 'numeric',
																	minute: 'numeric',
																	year: 'numeric',
																	hour: 'numeric'
																}) : ''}
                                       required
                                       placeholder={'Please select date'}
                                       id={'end'}
                                       onInvalid={(e) => {
																	       e.preventDefault()
																	       setTimeError(() => true)
																       }} type={"text"}/>
                            </p>
                        </div>
                        <button className='button--empty choose-date__button' style={{padding: '10px 20px'}}
                                type={"button"} onClick={() => store.dispatch(setCalendarPopupCandidateScoreboard({
													open: true,
													startDate: startDate,
													endDate: endDate
												}))}>
                            Select date & time
                        </button>
											{
												timeError && <p className="recruitment__popup-item__error-message">This is required field</p>
											}
                    </div>
                </div>
                <div className="recruitment__popup-item">
                    <label className="recruitment__popup-item__label" htmlFor="roleSelect">
					Interviewer(s)
                    </label>
                    <ReactSelect
                        options={managerList}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        onChange={(item: any) => {
					                setGuests(item);
				                }}
                        value={guests}
                        isMulti
                        placeholder="Select Interviewer"
                        styles={selectCustomStyle}
                        id="roleSelect"
						className='recruitment__interviewer-role'
                        instanceId="roleSelect"
                        components={{Menu,
					                Option,
				                }}
                    />
                </div>
            </>
					}
				</div>
				<div className="popup__bottom end">
					<button
						type='reset'
						onClick={() => store.dispatch(setUpdateRoundPopupCandidateScoreboard({
							open: false,
							id: -1,
							currentWorkflow: {},
							scoreboardId:-1
						}))}
						className="popup__cancel"
					>
						Cancel
					</button>
					<Button 
					isLoading={isLoading}
					label="Approve" type='submit'
						className="popup__bottom__publish button--filled"/>
			
					{
						errorNotification.length > 0 && (
							<p className="error-message">{errorNotification}</p>
						)
					}
				</div>
			</form>
		</div>
	)
}

export default memo(UpdatePopupCandidateScoreboard)

const SVG = () => {
	return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M7.9999 5H3.41412C2.52321 5 2.07704 6.07714 2.70701 6.70711L7.2928 11.2929C7.68332 11.6834 8.31648 11.6834 8.70701 11.2929L13.2928 6.70711C13.9228 6.07714 13.4766 5 12.5857 5H7.9999Z"
			fill="#999EA5"/>
	</svg>
}
const getStatus = (status) => {
	if (status === RoundStatuses.Pending) return {
		currentStatus: RoundStatuses.Pending,
		classname: 'table__status-block--yellow'
	}
	if (status === RoundStatuses.Progress) return {
		currentStatus: RoundStatuses.Progress,
		classname: 'table__status-block--yellow'
	}
	if (status === RoundStatuses.Rejected) return {
		currentStatus: RoundStatuses.Rejected,
		classname: 'table__status-block--red'
	}
	if (status === RoundStatuses.Completed) return {
		currentStatus: RoundStatuses.Completed,
		classname: 'table__status-block--green'
	}
}

const Option = (props: any) => {

	return (
		<div>
			<components.Option {...props} className="manage-team-popup__form__checkbox">
				<input
					id={props.value}
					type="checkbox"
					checked={props.isSelected}
					onChange={() => null}
				/>{" "}
				<label htmlFor={props.value}><span/></label>
				<p>{props.label}</p>
			</components.Option>
		</div>
	);
};

const Menu = (props) => {
	const navigate=useNavigate()
	const params=useParams()
	
	
	return (
	  <>
		<components.Menu {...props}>
		  <div>
			{props.selectProps.fetchingData ? (
			  <span className="fetching">Fetching data...</span>
			) : (
			  <div>{props.children}</div>
			)}
			<AuthGuard module='team-members' permission={['add','view']} >
			<button
			  className="add-role"
		  onClick={()=> navigate(`/recruitment/manage-team-members/add-new?redirection-url=/recruitment/job/${params?.jobid}/candidate-scoreboard/${params.id}`)}
			>
					<img src={plus_ic} alt="plus-icon" className='plus-icon' />
			 Add New Team Member
			</button>
			</AuthGuard>
			
		  </div>
		</components.Menu>
	  </>
	);
  };
