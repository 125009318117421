import { memo, useState } from "react";
import SingleItem from "../../Global/table/SingleMobileItem";
import { useNavigate } from "react-router-dom";
import { JobStatus, JobTabs } from "../../../enums/jobs/jobsEnums";
import { store } from "../../../store";
import editIcon from "../../../image/icon/edit_ic.svg";
import deleteIcon from "../../../image/icon/delete_ic.svg";
import match from "../../../image/icon/match.svg";
import approve from "../../../image/icon/approve_job_ic.svg";
import reject from "../../../image/icon/reject_job_ic.svg";
import { setApprovePopupJobs, setDeletePopupJobs, setRejectPopupJobs } from "../../../store/reducers/jobsTableReducer";
import SelectStatus from "./SelectStatus";
import AsyncWorkflows from "./ReactSelectWorkflowsJobTable";
import { useHasPermission, ShowToolTipComponent, useAuth } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
import view from "../../../image/icon/eye-on.svg";

const MobileTableJobs = ({ item }) => {

	const { checkUserPermission } = useHasPermission()
	const [openItem, setOpenItem] = useState(false);
	const navigate = useNavigate()
	const { userId } = useAuth();
	let classEvenOdd = 'odd';

	const actionsButtons = [
		(item.status === 'publish' ?
			{
					onClick: () => navigate(`/job/${item.id}`),
					title: 'View Job',
					icon: view
			}
			:
			null
		),
		(item.status !== 'approval' && item.status !== 'closed' && item.status !== 'rejected' && item.status !== 'draft' ?
			{
					onClick: () => navigate(`/recruitment/job/${item.id}/matched-profiles`),
					title: 'Matched profiles',
					icon: match
			}
			:
			null
		),
		(
			checkUserPermission('job-post', 'edit') && item.status === 'approval' &&
				item.approverId && item.approverId === userId ?
				{
						onClick: (e) => store.dispatch(setApprovePopupJobs({ open: true, name: item.jobTitle, id: item.id })),
						title: 'Approve job',
						icon: approve
				}
				:
				null
		),
		(checkUserPermission('job-post', 'edit') && item.status === 'approval' &&
			item.approverId && item.approverId === userId ?
			{
					onClick: (e) => store.dispatch(setRejectPopupJobs({ open: true, name: item.jobTitle, id: item.id })),
					title: 'Reject job',
					icon: reject
			}
			:
			null
		),
		(
			checkUserPermission('job-post', 'edit') ?
				{
						onClick: () => navigate(`/job/${item.id}/edit/`),
						title: 'Edit details',
						icon: editIcon
				}
				:
				null
		),
		(
			checkUserPermission('job-post','delete') && item.status !== 'approval' ?
			{
					onClick: (e) => {
						store.dispatch(setDeletePopupJobs({
							open: true,
							name: item.jobTitle,
							id: item.id
						}))
					},
					title: 'Delete',
					icon: deleteIcon
			}
			:
			null
		)
	]?.filter(i=>i)


	return <div className='table-mobile__item'>
		<div className={`table-mobile__top ${openItem ? 'active' : ''}`} onClick={() => {
			setOpenItem(prev => !prev)
		}}>
			<div className='table-mobile__top__left'>
				<p className='table-mobile__top__name'>{item.jobTitle}</p>
			</div>
			<ArrowSvg />
		</div>
		<div className={`table-mobile__item__body ${openItem ? 'open' : ''}`}>
			<SingleItem title={JobTabs.JOB_LOCATION + ':'} text={item.jobLocation} textStyle={'table-mobile__item__text'} />
			<SingleItem title={JobTabs.SALARY_RANGE + ':'} text={item.salary} textStyle={'table-mobile__item__text table-mobile__item__text--green'} />
			<SingleItem title={JobTabs.POSTED_ON + ':'} text={item.postedOn} textStyle={'table-mobile__item__text table-mobile__item__text--green'} />
			<SingleItem title={JobTabs.POSTED_BY + ':'} text={item.postedBy} textStyle={'table-mobile__item__text'} />

			<div className='table-mobile__item__wrap'>
				<div className='table-mobile__item__status'>
					{
						item.status === 'publish' && <>
							{
								checkUserPermission('job-post', 'edit') ?
									<SelectStatus status={'publish'} id={item.id} />
									:
									<div className='table-dropdown-with-color-btn'>
										<div className={'table-dropdown-with-color-btn__wrap'}>
											<div
												className='manage-team__text manage-team__text--black table-dropdown-with-color-btn__button '>
												<p className='table__status-block--green'>{JobStatus.PUBLISH}</p>
											</div>
										</div>
									</div>
							}

						</>
					}
					{
						item.status === 'approval' &&
						<div className='table-dropdown-with-color-btn'>
							<div className={'table-dropdown-with-color-btn__wrap'}>
								<div
									className='manage-team__text manage-team__text--black table-dropdown-with-color-btn__button '>
									<p className='table__status-block--yellow'>{JobStatus.Pending}</p>
								</div>
							</div>
						</div>
					}
					{
						item.status === 'draft' && <div className='table-dropdown-with-color-btn'>
							<div className={'table-dropdown-with-color-btn__wrap'}>
								<div
									className='manage-team__text manage-team__text--black table-dropdown-with-color-btn__button '>
									<p className='table__status-block--red'>{JobStatus.DRAFT}</p>
								</div>
							</div>
						</div>
					}
					{
						item.status === 'closed' &&
						<>
							{
								checkUserPermission('job-post', 'edit') ?
									<SelectStatus status={'closed'} id={item.id} />
									:
									<div className='table-dropdown-with-color-btn'>
										<div className={'table-dropdown-with-color-btn__wrap'}>
											<div
												className='manage-team__text manage-team__text--black table-dropdown-with-color-btn__button '>
												<p className='table__status-block--gray'>{JobStatus.CLOSED}</p>
											</div>
										</div>
									</div>
							}
						</>
					}
					{
						item.status === 'rejected' && <div className='table-dropdown-with-color-btn'>
							<div className={'table-dropdown-with-color-btn__wrap'}>
								<div
									className='manage-team__text manage-team__text--black table-dropdown-with-color-btn__button '>
									<p className='table__status-block--red'>{JobStatus.REJECTED}</p>
								</div>
							</div>
						</div>
					}
					{
						item.status === 'onhold' &&
						<>
							{
								checkUserPermission('job-post', 'edit') ?
									<SelectStatus status={'onhold'} id={item.id} />
									:
									<div className='table-dropdown-with-color-btn'>
										<div className={'table-dropdown-with-color-btn__wrap'}>
											<div
												className='manage-team__text manage-team__text--black table-dropdown-with-color-btn__button '>
												<p className='table__status-block--orange'>{JobStatus.ON_HOLD}</p>
											</div>
										</div>
									</div>
							}
						</>
					}
				</div>
			</div>
			<div className='table-mobile__item__wrap '>
				<p className='table-mobile__item__title'>Workflow:</p>
				{
					checkUserPermission('job-post', 'edit') && item.status !== 'closed' ?
						<div className='table-mobile__item__async-select'>
							<ShowToolTipComponent text='Click to change the assign workflow'>
								<AsyncWorkflows item={item} />
							</ShowToolTipComponent>
						</div>
						:
						<div className="manage-team__text">
							{item?.positionWorkflow?.label || ""}
						</div>
				}
			</div>
			<div className="table-mobile__item__body__action">
				{
					actionsButtons?.length > 0 &&
					actionsButtons.map((action, index) => {
						classEvenOdd = classEvenOdd === 'odd' ? 'even' : 'odd'
						return <button onClick={action.onClick}
							className={`table-mobile__item__action-button table-mobile__item__action-button--half ${classEvenOdd}`}
							key={index}>
							<img src={action.icon} alt={action.title}/>
							{action.title}
						</button>
					})
				}
			</div>
		</div>
	</div>
}
export default memo(MobileTableJobs)
const ArrowSvg = () => {
	return <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_6751_20970)">
			<path d="M1 3L5 7L9 3" stroke="#5F6E80" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
		</g>
		<defs>
			<clipPath id="clip0_6751_20970">
				<rect width="10" height="10" fill="white" transform="translate(0 10) rotate(-90)" />
			</clipPath>
		</defs>
	</svg>
}

