import { useEffect, useCallback, useState } from "react";
import { TableCardViewComp } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
import { IColumns } from "@ucrecruits/globalstyle/types/table-types";
import { BackgroundJobsTableHeader } from "../enums";
import { Link } from "react-router-dom";
import { getEnv } from "@urecruits/api";
import { store, useTypedSelector } from "../store";
import {
  setCurrentPage,
  setLimit,
  setLocationFilter,
  setSearchValue,
  setSortByFilter,
  setSortTypeFilter,
  setTotalCount,
  defaultFilters,
  setOrderColumns,
} from "../store/reducers/backgroundJobsReducer";
import { JobsFilters } from "../components/BackgroundScreening";
import { AuthGuard } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";

const { API_RECRUITMENT } = getEnv();

const columns: Array<IColumns> = [
  {
    headerName: BackgroundJobsTableHeader.JOB_TITLE,
    field: "title",
    pinnable: true,
    renderCell: (cellValue, rowData) => {
      const jobTitle = cellValue || "-";
      const jobLocation = rowData.locations.map(
        (location) =>
          `${location?.city ?? ""},${location?.state ?? ""}`
      ).join(" | ")
      return (
        <Link
          to={`/recruitment/background-screening/candidates/${rowData.id}`}
          state={{ jobTitle, jobLocation }}
          className="background__jobs__link"
        >
          {jobTitle}
        </Link>
      );
    },
    mobileRenderCell: (cellValue, rowData) => {
      const jobTitle = cellValue || "-";
      const jobLocation = rowData.locations.map(
        (location) =>
          `${location?.city ?? ""},${location?.state ?? ""}`
      ).join(" | ")
      return (
        <Link
          to={`/recruitment/background-screening/candidates/${rowData.id}`}
          state={{ jobTitle, jobLocation }}
          className="background__jobs__link"
        >
          {jobTitle}
        </Link>
      );
    },
    visibility: true,
    isMobileTitle: true,
    className: "",
  },
  {
    headerName: BackgroundJobsTableHeader.JOB_LOCATION,
    field: "locations",
    renderCell: (cellValue, rowData) => {
      return (
        <p className="background__jobs__location">
          {cellValue.map(
            (location) =>
              `${location?.city ?? ""},${location?.state ?? ""}`
          ).join(" | ")}
        </p>
      );
    },
    mobileRenderCell: (cellValue, rowData) => {
      return (
        <p className="background__jobs__location">
          {cellValue.map(
            (location) =>
              `${location?.city ?? ""},${location?.state ?? ""}`
          ).join(" | ")}
        </p>
      );
    },
    visibility: true,
    className: "",
  },
  {
    headerName: BackgroundJobsTableHeader.OF_OPENINGS,
    field: "numberOpenings",
    renderCell: (cellValue, rowData) => {
      return <p className="background__jobs__count">{cellValue || "-"}</p>;
    },
    mobileRenderCell: (cellValue, rowData) => {
      return <p className="background__jobs__count">{cellValue || "-"}</p>;
    },
    visibility: true,
    className: "",
  },
  {
    headerName: BackgroundJobsTableHeader.OF_SELECTED,
    field: "VerifiedCandidatesCount",
    renderCell: (cellValue, rowData) => {
      return <p className="background__jobs__count">{cellValue || "-"}</p>;
    },
    mobileRenderCell: (cellValue, rowData) => {
      return <p className="background__jobs__count">{cellValue || "-"}</p>;
    },
    visibility: true,
    className: "",
  },
  {
    headerName: BackgroundJobsTableHeader.OF_SENT_BGV,
    field: "sentCandidatesForBGVCount",
    renderCell: (cellValue, rowData) => {
      return <p className="background__jobs__count">{cellValue || "-"}</p>;
    },
    mobileRenderCell: (cellValue, rowData) => {
      return <p className="background__jobs__count">{cellValue || "-"}</p>;
    },
    visibility: true,
    className: "",
  },
  {
    headerName: BackgroundJobsTableHeader.OF_CANDIDATE_YET_TO_SEND,
    field: "NotSentCandidatesForBGVCount",
    renderCell: (cellValue, rowData) => {
      return <p className="background__jobs__count">{cellValue || "-"}</p>;
    },
    mobileRenderCell: (cellValue, rowData) => {
      return <p className="background__jobs__count">{cellValue || "-"}</p>;
    },
    visibility: true,
    className: "",
  },
  {
    headerName: BackgroundJobsTableHeader.OF_REJETED_FROM_BGV,
    field: "rejectedCandidatesCount",
    renderCell: (cellValue, rowData) => {
      return <p className="background__jobs__count">{cellValue || "-"}</p>;
    },
    mobileRenderCell: (cellValue, rowData) => {
      return <p className="background__jobs__count">{cellValue || "-"}</p>;
    },
    visibility: true,
    className: "",
  },
];

const getTable = (store) => store.background_jobs;
const getTableFilters = (store) => store.background_jobs.filters;
const getTableFilterData = (store) => store.background_jobs.filterInfo;
const getTablePagination = (store) => store.background_jobs.pagination;

export const BackgroundScreeningJobs = () => {
  const table = useTypedSelector(getTable);
  const [filterString, setFilterString] = useState<string>("");
  const [isEmptyFilter, setIsEmptyFilter] = useState<boolean>(false);
  const [isFilterSubmit, setIsFilterSubmit] = useState(false);

  useEffect(() => {
    const filters = table.filters;
    if (isFilterSubmit && filters) {
      setFilterString(
        ` ${filters.locations
          ? filters.locations
            .map((x: any) => `&locations=${x.value}`)
            .join("")
          : ""
        }`
      );
    }
  }, [isFilterSubmit]);

  const setSearchCallback = useCallback((value) => {
    store.dispatch(setSearchValue(value));
    store.dispatch(setCurrentPage(1));
  }, []);

  const setCurrentPageCallback = useCallback((value) => {
    store.dispatch(setCurrentPage(value));
  }, []);

  const FilterItems = (state) => {
    return <JobsFilters activeTab={state} />;
  };

  const createAPIString = () => {
    const filters = table.filters;
    const pagination = table.pagination;
    return `${API_RECRUITMENT}/api/background/jobs?limit=${pagination.limit
      }&offset=${(pagination.currentPage - 1) * pagination.limit
      } ${filterString
      }${filters.searchValue ? "&search=" + filters.searchValue : ""
      }${filters.sortBy ? "&sortBy=" + filters.sortBy : ""
      }${filters.sortType ? "&sortType=" + filters.sortType : ""}`;
  };

  const resetFilters = (pagination) => {
    store.dispatch(setSortByFilter(defaultFilters.sortBy));
    store.dispatch(setSortTypeFilter(defaultFilters.sortType));
    store.dispatch(setSearchValue(defaultFilters.searchValue));
    store.dispatch(setLocationFilter(defaultFilters.locations));
    setIsFilterSubmit(true);
  };

  return (
    <>
      <AuthGuard module={['job-post', 'background']} permission='view' option='AND'>
        <h2 className="table-screen-top">Background Screening</h2>
        <TableCardViewComp
          tableViewType={"table"}
          columns={columns}
          rowsData={{ api: createAPIString() }}
          searchField={{
            searchValue: table.filters.searchValue,
            setSearchValue: setSearchCallback,
            placeholder: "Enter job title",
          }}
          emptyTable={{
            title: "No Jobs Found",
            desc: "You have no published jobs. Once jobs are available, they will be displayed here.",
          }}         
          pagination={{
            limit: table.pagination.limit,
            currentPage: table.pagination.currentPage,
            totalCount: table.pagination.totalCount,
            setTotalCount: (count: number) => {
              store.dispatch(setTotalCount(count));
            },
            setCurrentPage: setCurrentPageCallback,
            setLimit: (value: any) => {
              store.dispatch(setLimit(value));
            },
            filters: table.filters,
          }}
          rearrangeColumns={{ setColumnOrder: setOrderColumns }}
          storeName="background_jobs"
          store={store}
          useTypedSelector={useTypedSelector}
          filters={{
            filterData: useTypedSelector(getTableFilterData),
            filter: useTypedSelector(getTableFilters),
            resetFilters,
            filterItems: FilterItems,
            isFilterSubmit,
            setIsFilterSubmit: (value: boolean) => setIsFilterSubmit(value),
            isEmptyFilter,
            setIsEmptyFilter: (value: boolean) => setIsEmptyFilter(value),
          }} />
      </AuthGuard>
    </>
  );
};



