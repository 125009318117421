import {
  getConfig,
  PaginationComponent,
  SearchFieldComponent,
  SmallLoaderComponent,
  SubscriptionGuard,
  TheadItemComponent,
  useSubscription,
} from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
import { getEnv } from "@urecruits/api";
import axios from "axios";
import {
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import FilterWrapper from "../components/Global/table/FilterWrapper";
import MemoOrderSVG from "../components/Global/table/MemoOrderSVG";
import NoResultsSearch from "../components/Global/table/NoResultsSearch";
import TableEmpty from "../components/Global/table/TableEmpty";
import TopFilterButton from "../components/Global/table/TopFilterButton";
import { ManageCandidateTableOrder } from "../components/HOCs/OrderPopupHOCs";
import CreateCandidatePopup from "../components/ManageCandidates/CreateCandidatePopup";
import FilterPopupManageCandidate from "../components/ManageCandidates/FilterPopupManageCandidate";
import ManageCandidateMobileTable from "../components/ManageCandidates/ManageCandidateMobileTable";
import ManageCandidatesTBody from "../components/ManageCandidates/ManageCandidatesTBody";
import ResendPopup from "../components/ManageCandidates/ResendPopup";
import ShowAssignPopup from "../components/ManageCandidates/ShowAssignPopup";
import { loadingEvent } from "../hook/emitter";
import fetchData from "../hook/http";
import useClickOutside from "../hook/useClickOutside";
import useTableClickAndDragScroll from "../hook/useTableClickAndDragScroll";
import { store, useTypedSelector } from "../store";
import {
  defaultFiltersManageCandidate,
  setAssignmentsListManageCandidate,
  setCurrentPageManageCandidate,
  setEducationalManageCandidate,
  setEmptySearchManageCandidate,
  setEmptyTableManageCandidate,
  setExperienceManageCandidate,
  setIsAppliedManageCandidate,
  setLimitManageCandidate,
  setLocationManageCandidate,
  setOrderManageCandidate,
  setSalaryYearManageCandidate,
  setSearchValueManageCandidate,
  setSkillsFilterManageCandidate,
  setSortByManageCandidate,
  setSortTypeManageCandidate,
  setTableItemsManageCandidate,
  setTotalCountManageCandidate,
} from "../store/reducers/manageCandidate";
import { IManageCandidateTableItems } from "../types/redux/manage-candidate";
import { sortingFunc } from "../utils/sortingFuncForTables";

const { API_RECRUITMENT, API_ASSESSMENT } = getEnv();
const getFilters = (state) => state.manage_candidate.filters;
const tableFunc = (state) => state.manage_candidate;
const isAppliedFunc = (state) => state.manage_candidate.isApplied;
const getFilterDataFunc = (state) => state.manage_candidate.filterInfo;
const getLimitFunc = (state) => state.manage_candidate.pagination.limit;
//TODO replace component
const FilterItems = (state) => <FilterPopupManageCandidate activeTab={state} />;

const ManageCandidatesScreen = () => {
  const [orderPopup, setOrderPopup] = useState(false);
  const [horizontalScrollState, setHorizontalScrollState] = useState(false);
  const navigate = useNavigate();
  const orderPopupRef = useRef<null | HTMLButtonElement>(null);
  const tableRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [addNew, setAddNew] = useState(false);

  const table = useTypedSelector(tableFunc);

  const { checkCurrentPackageType } = useSubscription();
  const hasAssessmentPackage = checkCurrentPackageType("assessment");

  const FilterPopupInnerWrap = (setState) => {
    const filters = useTypedSelector(getFilters);
    const filtersData = useTypedSelector(getFilterDataFunc);
    const limit = useTypedSelector(getLimitFunc);
    return (
      <FilterWrapper
        setFilterPopup={setState}
        resetFunc={resetFunc}
        filters={filters}
        filterData={filtersData}
        submitFunc={setManageCandidatesHandler}
        limit={limit}
      >
        {FilterItems}
      </FilterWrapper>
    );
  };

  useLayoutEffect(() => {
    setManageCandidatesHandler(
      table.pagination.currentPage,
      table.pagination.limit,
      { ...table.filters, isApplied: table.isApplied }
    );
    fetchData(`${API_ASSESSMENT}/api/assignment/all`).then((data) => {
      store.dispatch(setAssignmentsListManageCandidate(data?.rows));
    });
    if (hasAssessmentPackage) {
      const tab = table.tabFilter.findIndex((i) => i.id == 9);
      if (tab >= 0) {
        const newFilter = JSON.parse(JSON.stringify(table.tabFilter));
        newFilter[tab].active = true;
        store.dispatch(setOrderManageCandidate(newFilter));
        store.dispatch(setTableItemsManageCandidate([]));
      }
    }
  }, [table.isApplied]);

  useEffect(() => {
    const handleLoadingStart = () => setLoading(true);
    const handleLoadingEnd = () => setLoading(false);

    // Subscribe to the loading events
    loadingEvent.on("loadingStart", handleLoadingStart);
    loadingEvent.on("loadingEnd", handleLoadingEnd);

    return () => {
      loadingEvent.off("loadingStart", handleLoadingStart);
      loadingEvent.off("loadingEnd", handleLoadingEnd);
    };
  }, []);

  useTableClickAndDragScroll(tableRef);
  //TODO add filter array
  const filterArray = [
    table.filters.searchValue,
    table.filters.sortBy,
    table.filters.sortType,
    table.pagination.limit,
    table.filters.location,
    table.filters.education,
    table.filters.skills,
    table.pagination.experience,
    table.filters.salary_year,
  ];

  const setSearchCallback = useCallback((value) => {
    store.dispatch(setSearchValueManageCandidate(value));
    setManageCandidatesHandler(1, table.pagination.limit, {
      ...table.filters,
      searchValue: value,
      isApplied: table.isApplied,
    });
  }, filterArray);

  const emptyTableCallback = useCallback(() => {
    navigate("/");
  }, []);

  const OnIsApplied = () => {
    const tab = table.tabFilter.findIndex((i) => i.id == 2);
    if (tab >= 0) {
      const newFilter = JSON.parse(JSON.stringify(table.tabFilter));
      newFilter[tab].active = !table.tabFilter[tab].active;
      store.dispatch(setOrderManageCandidate(newFilter));
      store.dispatch(setTableItemsManageCandidate([]));
    }
    store.dispatch(setIsAppliedManageCandidate(!table.isApplied));
  };

  const tdOrderCallback = useCallback(
    (value) =>
      sortingFunc(
        table,
        value,
        setSortTypeManageCandidate,
        setSortByManageCandidate,
        setManageCandidatesHandler
      ),
    filterArray
  );
  useClickOutside(orderPopupRef, setOrderPopup);

  const setManageCandidatesHandler = (
    page: number,
    limit: number,
    filters: any
  ) => {
    store.dispatch(setCurrentPageManageCandidate(page));
    const getData = async () => {
      loadingEvent.emit("loadingStart");
      await axios(
        `${API_RECRUITMENT}/api/${filters.isApplied
          ? "subscribe/applied-candidates"
          : "candidate/get/all"
        }?limit=${limit}
			&offset=${(page - 1) * limit}
			&search=${encodeURIComponent(filters.searchValue)}
			${filters.education.map((item) => `&degree=${item}`).join("")}
			${filters.location.map((item) => `&locationId=${item.value}`).join("")}
			${filters.skills.map((item) => `&skills=${item}`).join("")}
			&experienceMin=${filters.experience[0]}
			&experienceMax=${filters.experience[1]}
			&salaryYearMin=${filters.salary_year[0]}
			&salaryYearMax=${filters.salary_year[1]}
			&sortType=${filters.sortType}
			&sortBy=${filters.sortBy}`,
        getConfig()
      ).then(async (res) => {
        store.dispatch(setTotalCountManageCandidate(res.data.count));
        const items = await transformArrayToTableData(
          res.data.rows,
          filters.isApplied
        );
        store.dispatch(setTableItemsManageCandidate(items));
        loadingEvent.emit("loadingEnd");
        if (items.length === 0) {
          if (
            filters.searchValue === "" &&
            filters.location.length === 0 &&
            filters.education.length === 0 &&
            filters.skills.length === 0 &&
            filters.experience[0] === 0 &&
            filters.experience[1] === 50 &&
            filters.salary_year[0] === 0 &&
            filters.salary_year[1] === 1000000 &&
            !filters.isApplied
          ) {
            store.dispatch(setEmptyTableManageCandidate(true));
          } else {
            store.dispatch(setEmptySearchManageCandidate(true));
          }
        } else {
          store.dispatch(setEmptySearchManageCandidate(false));
          store.dispatch(setEmptyTableManageCandidate(false));
        }
      });
    };
    getData().then();
  };
  const setLimitHandler = (value: any) => {
    store.dispatch(setLimitManageCandidate(value));
  };
  const transformArrayToTableData = async (
    array: any,
    isApplied: boolean = false
  ): Promise<Array<IManageCandidateTableItems>> => {
    const requests = array.map(async (item) => {
      try {
        let data = {
          hasActiveAssignment: false,
          assignTask: { id: null, name: "" },
        };
        if (hasAssessmentPackage) {
          const showAssignResponse = await axios(
            `${API_RECRUITMENT}/api/user-assignments/${item?.userId}`,
            getConfig()
          );
          const showAssign = showAssignResponse?.data;

          const hasActiveAssignment =
            showAssign?.hasActiveAssignment || isApplied ? true : false;
          data = {
            hasActiveAssignment,
            ...(showAssign && {
              assignTask: {
                id: showAssign?.assignmentId,
                name: showAssign?.assignmentName,
              },
            }),
          };
        }
        const location = item.location?.city
          ? `${item.location?.city}, ${item.location?.state}`
          : "";
        return {
          id: item.id,
          userId: item.userId,
          firstname: item.user?.firstname,
          middlename: item.user?.middlename,
          lastname: item.user?.lastname,
          avatar: item.user?.avatar,
          salary: item.preferencesExpectedCTC,
          experience: item.experience,
          location,
          email: item.user?.email,
          phone: item.user?.phone,
          status: item?.status,
          ...data,
          ...(isApplied &&
            item.job && {
            job: { id: item.job?.id, title: item.job?.title },
          }),
        };
      } catch (error) {
        console.error(
          `Error fetching assignment for userId ${item.userId}:`,
          error
        );
        return null;
      }
    });

    const result = await Promise.all(requests);
    return result.filter(
      (item) => item !== null
    ) as Array<IManageCandidateTableItems>;
  };
  const resetFunc = (pagination, isApplied = false) => {
    store.dispatch(setSkillsFilterManageCandidate([]));
    store.dispatch(setEducationalManageCandidate([]));
    store.dispatch(setSalaryYearManageCandidate([0, 1000000]));
    store.dispatch(setExperienceManageCandidate([0, 50]));
    store.dispatch(setLocationManageCandidate([]));
    store.dispatch(setSearchValueManageCandidate(""));
    store.dispatch(setSortByManageCandidate("id"));
    store.dispatch(setSortTypeManageCandidate("DESC"));
    store.dispatch(setIsAppliedManageCandidate(false));
    setManageCandidatesHandler(1, pagination, {
      ...defaultFiltersManageCandidate,
      isApplied,
    });
    OnIsApplied();
  };

  return (
    <section>
      {table.resendPopup?.open && (
        <ResendPopup setManageCandidatesHandler={setManageCandidatesHandler} />
      )}
      {table.showAssignPopup.open && (
        <ShowAssignPopup
          setManageCandidatesHandler={setManageCandidatesHandler}
        />
      )}
      <div className="table-screen-top">
        <div className="table-screen-top__wrapper">
          <h2>Manage Candidate Profile</h2>
        </div>
        <SubscriptionGuard planPackageType={"assessment"}>
          <button
            className="button--filled table-screen-top__button"
            onClick={() => setAddNew(true)}
          >
            Add new
          </button>
        </SubscriptionGuard>
      </div>
      <div className="table__wrapper">
        <div className="table__top">
          <div className="manage-team__top__left">
            <TopFilterButton>{FilterPopupInnerWrap}</TopFilterButton>
            <SearchFieldComponent
              searchValue={table.filters.searchValue}
              setSearchValue={setSearchCallback}
              placeholder={"Search candidates"}
            />
            {!hasAssessmentPackage && (
              <button
                onClick={OnIsApplied}
                className={`manage-team__buttons__item ${table.isApplied ? "active" : ""
                  }`}
              >
                Show {table.isApplied ? "All" : "Applied"}
              </button>
            )}
          </div>
          <button
            className={`manage-team__top__svg ${orderPopup ? "active" : ""}`}
            ref={orderPopupRef}
          >
            <MemoOrderSVG setState={setOrderPopup} />
            {
              <CSSTransition
                in={orderPopup}
                timeout={300}
                classNames={"order-popup-mtm"}
                unmountOnExit
                mountOnEnter
              >
                <ManageCandidateTableOrder setOrderPopup={setOrderPopup} />
              </CSSTransition>
            }
          </button>
        </div>
        {loading ? (
          <SmallLoaderComponent />
        ) : table.emptySearch || table.emptyTable ? (
          table.emptySearch ? (
            <NoResultsSearch
              limit={table.pagination.limit}
              resetFunc={resetFunc}
            />
          ) : (
            <TableEmpty
              handler={emptyTableCallback}
              cta={"Go home"}
              title={"No Candidates Found"}
              desc={"There are no candidates registered on the uRecruits platform at this time. Check back later."}
            />
          )
        ) : (
          <>
            <table
              className="table"
              ref={tableRef}
              onScroll={(e: any) => {
                if (e.target.scrollLeft > 10 && !horizontalScrollState)
                  setHorizontalScrollState(() => true);
                else if (e.target.scrollLeft < 10 && horizontalScrollState)
                  setHorizontalScrollState(() => false);
              }}
            >
              <thead className="table__thead">
                <td
                  className={`table__td sticky ${horizontalScrollState ? "moved" : ""
                    } table__td--thead jobs-table__column__middle`}
                >
                  <TheadItemComponent
                    title={table.fixedTab.displayName}
                    handler={null}
                    dbName={""}
                  />
                </td>
                {table.tabFilter.map((item) => {
                  return (
                    item.active && (
                      <td
                        className={`table__td  table__td--thead jobs-table__column__default`}
                        key={item.id}
                      >
                        <TheadItemComponent
                          title={item.displayName}
                          handler={item.dbName ? tdOrderCallback : null}
                          dbName={item.dbName}
                        />
                      </td>
                    )
                  );
                })}
              </thead>
              <ManageCandidatesTBody
                horizontalScrollState={horizontalScrollState}
              />
            </table>

            <div className="table-mobile">
              {table.tableItems
                .filter((i) => !!i)
                .map((item) => {
                  return (
                    <ManageCandidateMobileTable key={item.id} item={item} />
                  );
                })}
            </div>
            <PaginationComponent
              limit={table.pagination.limit}
              currentPage={table.pagination.currentPage}
              totalCount={table.pagination.totalCount}
              setCurrentPage={setManageCandidatesHandler}
              setLimit={setLimitHandler}
              filters={table.filters}
            />
          </>
        )}
      </div>
      {addNew && <CreateCandidatePopup onClose={() => setAddNew(false)} />}
    </section>
  );
};
export default memo(ManageCandidatesScreen);
