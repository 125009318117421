import { Fragment, memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { store, useTypedSelector } from "../../../store";
import fetchData from "../../../hook/http";
import { getEnv } from "@urecruits/api";
import {
  setPopupWithScheduleOn,
} from "../../../store/reducers/assignmentCandidateReducer";
import { AuthGuard } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";

const { API_ASSESSMENT } = getEnv();
const getTitle = (state) => state.manage_assignment.openPreview;

const PopupWithScheduleOn = () => {
  const { jobId } = useParams();
  const popupWithList = useTypedSelector(
    (state) => state.assignment_candidate.popupWithScheduleOn
  );
  const { title } = useSelector(getTitle);

  const [industryData, setIndustryData] = useState([]);
  const [loading, setLoading] = useState(true); 


  const fetchDataAndSetState = async (apiEndpoint, setDataFunction) => {
    try {
      const data = await fetchData(apiEndpoint);
      if (data) {
        setDataFunction(data);
        setLoading(false); 
      }
    } catch (error) {
      console.error(`Error fetching data from ${apiEndpoint}`, error);
    }
  };

  useEffect(() => {
    if (popupWithList?.ids && jobId) {
      fetchDataAndSetState(
        `${API_ASSESSMENT}/api/test-manager/getByJobId/${popupWithList?.ids}/${jobId}`,
        setIndustryData
      );
    }
  }, [popupWithList, jobId]);

  const formatDateForDisplay = (dateString) => {
    return new Date(dateString).toLocaleString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };

  const renderInput = (dd, assessmentType) => {
    const assessmentData = industryData?.filter(
      (data) => data?.assessmentType === assessmentType
    );

    if (assessmentData?.length > 0) {
      return (
        <div className="schedule__input-container">
          <div className="manage-team__text manage-team__text--gray7">Deadline</div>
          <input
            type="text"
            className="manage-team__text"
             style={{
              borderRadius: "4px",
              background: "#F8F9FB",
              padding: "4px 20px",
            }}
            value={new Date(assessmentData[0]?.deadline).toUTCString()}
            aria-disabled
          />
        </div>
      );
    } else {
      return (
        <>
        {!!dd?.date?.start ? (
          <div className="date-container" >
          <div style={{display:"flex",flexDirection:"column"}}>
          <label className="manage-team__text manage-team__text--gray7">Started On</label>
            <input
              type="text"
              className="manage-team__text"
              value={formatDateForDisplay(dd.date?.start)}
              aria-disabled
              style={{
                borderRadius: "4px",
                background: "#F8F9FB",
                padding: "4px 20px",
              }}
            />
          </div>
            {dd?.date?.end && (
              <>
          <div style={{display:"flex",flexDirection:"column"}}>
              <label className="manage-team__text manage-team__text--gray7">Completed On</label>
                <input
                className="manage-team__text"
                type="text"
                value={formatDateForDisplay(dd.date.end)}
                aria-disabled
                style={{
                  borderRadius: "4px",
                  background: "#F8F9FB",
                  padding: "4px 20px",
                }}
              />
              </div>
              </>
            )}
          </div>
        ) : (
          <input
            type="text"
            className="manage-team__text"
            value={"Not started yet"}
            aria-disabled
            style={{
              borderRadius: "4px",
              background: "#F8F9FB",
              padding: "4px 20px",
            }}
          />
        )}
      </>
      );
    }
  };


  return (
    <AuthGuard module='assessment' permission='view'>
    <div className="schedule">
      <div className="schedule__step">
        <div className="schedule__head">
          <div className="assessment__head__headline">
            Assessment Type -{" "}
            <span className="schedule__text--green">
              <b>{title}</b>
            </span>
          </div>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() =>
              store.dispatch(
                setPopupWithScheduleOn({
                  open: false,
                  id: -1,
                  data: null,
                  title: null,
                  ids: null,
                })
              )
            }
            className="schedule__head__close"
          >
            <path
              d="M18 6L12 12M6 18L12 12M12 12L6 6L18 18"
              stroke="#C1C5CB"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div className="schedule__body">
        {loading ? (
            <Loader /> 
          ) :<>
          {popupWithList?.data?.length > 0 &&
            popupWithList.data.map((dd, index) => (
              <Fragment key={index}>
                <p className="manage-team__text manage-team__text--gray7" style={{ marginBottom: "10px" }}>
                  {dd.title}
                </p>
                {industryData?.length > 0 &&
                dd?.title === "Functional/Domain Assessment"
                  ? renderInput(dd, "Domain Assessment")
                  : dd?.title === "Technical/Coding Assessment"
                  ? renderInput(dd, "Coding Assessment")
                  : renderInput(dd, "")}
                <hr />
              </Fragment>
            ))}</>}
          
        </div>
        <div className="schedule__bottom end"></div>
      </div>
    </div>
    </AuthGuard>
  );
};

export default memo(PopupWithScheduleOn);


const Loader = () => (
  <div className="assessment__loader">
  </div>
);