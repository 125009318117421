import { store, useTypedSelector } from "../../../store";
import { JobsOffersTableTabsEnums } from "../../../enums/jobsOffers/JobsOffersEnums";
import { selectSearchFuncLocationLimit } from "@urecruits/api";
import { setLocationFilterJobsOffers } from "../../../store/reducers/jobsOffersReducer";
import { memo } from "react";
import { FilterWithLimitComponent } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";

const getFiltersFunc = state => state.offers_jobs.filters;

const FilterItemsJobsOffers = ({ activeTab }) => {
	const filter = useTypedSelector(getFiltersFunc);

	return (
		<>
			{
				activeTab === JobsOffersTableTabsEnums.JOB_LOCATION && (
					<div className="table-filter-popup__right__content">
						<FilterWithLimitComponent
							filter={{
								onChangeValue: (option) => {
									store.dispatch(setLocationFilterJobsOffers(option));
								},
								value: filter.location
							}}
							placeHolder="Search by job location"
							setLimitSearchfunction={selectSearchFuncLocationLimit}
						/>
						{/* <AsyncSelect
							isMulti
							components={{
								Option,
							}}
							cacheOptions
							loadOptions={(inputValue) =>
								inputValue.length > 0 ? selectSearchFuncLocation(inputValue) : selectSearchFuncLocation("")
							}
							defaultOptions
							value={filter.location}
							onChange={(option: any) => {
								store.dispatch(setLocationFilterJobsOffers(option));
							}}
							hideSelectedOptions={false}
							closeMenuOnSelect={false}
							placeholder="Search by job location"
							styles={selectCustomStyle}
							id="locationSelect"
							instanceId="locationSelect"
						/> */}
					</div>
				)
			}
		</>
	);
};

export default memo(FilterItemsJobsOffers);