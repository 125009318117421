import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import DraftsOutlinedIcon from "@mui/icons-material/DraftsOutlined";
import MarkEmailReadOutlinedIcon from "@mui/icons-material/MarkEmailReadOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton, Tooltip } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import Snackbar from "@mui/material/Snackbar";
import { getEnv } from "@urecruits/api";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import ".././styles/_mail-box-screen.scss";
import fetchData, { postData } from "../api/fetchDataApi";
import { Dropdown } from "../components/DropDown/DropDown";
import MailPreview from "../components/MailPreview/MailPreview";
import SendMail from "../components/SendMail/SendMail";
import Table from "../components/Table/MailBoxTable";
import mailPreviewIcon from "../image/Group_455.svg";
import DialogCreate from "../screen/UnauthorizedModal";
import { DashboardEmptyDataComponent } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";

const { API_RECRUITMENT } = getEnv();

const items: number[] = [5, 10, 20, 50, 100];
const dateEnum: { value: string; title: string }[] = [
  {
    value: "1 day",
    title: "1 Day",
  },
  {
    value: "3 days",
    title: "3 Days",
  },
  {
    value: "1 week",
    title: "1 Week",
  },
  {
    value: "2 weeks",
    title: "2 Weeks",
  },
  {
    value: "1 month",
    title: "1 Month",
  },
  {
    value: "2 month",
    title: "2 Month",
  },
  {
    value: "6 month",
    title: "6 Month",
  },
  {
    value: "1 year",
    title: "1 Year",
  },
];

interface Data {
  id: number;
  subject: string;
  date: string;
  from: string;
}

type selectedMailIdType = {
  id: string[];
  flag: boolean;
};

export type IsMailPreviewOpenType = {
  mailId: string;
  state: boolean;
  isDraft: boolean;
};

const MailBoxScreen = () => {
  const [selectedMailId, setSelectedMailId] = useState<
    readonly selectedMailIdType[]
  >([]);
  const [isMailRead, setIsMailRead] = useState<boolean>(false);
  const [tabState, setTabState] = useState("inbox");
  const [tabStateChanged, setTabStateChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [toastNotification, setToastNotification] = useState({
    state: false,
    message: "",
  });
  const [searchData, setSearch] = useState("");
  const [provider, setProvider] = useState("gmail");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc");
  const [sortOrderBy, setSortOrderBy] = useState<keyof Data>("date");
  const [pageNo, setPageNo] = useState(1);

  const [rowPerPage, setRowPerPage] = useState(10);
  const [mailDuration, setMailDuration] = useState("1 year");
  const [isMailPreviewOpen, setIsMailPreviewOpen] =
    useState<IsMailPreviewOpenType>({
      mailId: "",
      state: false,
      isDraft: false,
    });
  const navigate = useNavigate();
  useEffect(() => {
    if (selectedMailId.length === 1) setIsMailRead(selectedMailId[0].flag);
    else {
      const isMailUnread = selectedMailId.filter((item) => item.flag === false);
      if (isMailUnread.length > 0) setIsMailRead(false);
    }
  }, [selectedMailId]);

  const [mailList, setMailList] = useState({
    data: [],
    totalCount: 100,
  });

  const totalNoOfPage = mailList.totalCount / rowPerPage;
  const getProvider = async () => {
    setLoading(true);
    const apiUrl = `${API_RECRUITMENT}/api/integrations/provider`;
    try {
      await fetchData(apiUrl).then((result) => {
        result.mailbox !== "" && setProvider(result.mailbox);
      });
    } catch (error) {
      setToastNotification({
        message: error.message,
        state: true,
      });
      setLoading(false);
    }
  };

  const onDialog = () => {
    if (
      localStorage.getItem("prevRoute") ===
      "/recruitment/position-workflow/create"
    ) {
      localStorage.setItem("prevRoute", "");
      navigate("/recruitment/position-workflow/create");
    } else {
      navigate("/coding-assessments");
    }
  };

  const fetchMailData = async () => {
    setLoading(true);
    try {
      const apiUrl = `${API_RECRUITMENT}/api/${provider}/get-mails?sortBy=${sortOrderBy}&sortType=${sortOrder}&limit=${rowPerPage}&offset=${
        (pageNo - 1) * rowPerPage
      }&search=${searchData}&newer_than=${mailDuration}&source=${tabState}`;
      await fetchData(apiUrl).then((result) => {
        if (result) setLoading(false);
        setMailList(result);
      });
    } catch (error) {
      setToastNotification({
        message: error.message,
        state: true,
      });
      setLoading(false);
    }
  };

  const sendData = async () => {
    setLoading(true);
    const apiUrl = `${API_RECRUITMENT}/api/${provider}/mark-as-read`;
    const body = {
      messageIds: selectedMailId.map((item) => item.id),
      flag: !isMailRead,
    };
    try {
      return await postData(apiUrl, body).then((result) => {
        setLoading(false);
        return result;
      });
    } catch (error) {
      setToastNotification({
        message: error.message,
        state: true,
      });
      setLoading(false);
    }
  };

  const deleteMailHandler = async (mailList) => {
    setLoading(true);
    const apiUrl = `${API_RECRUITMENT}/api/${provider}/delete-mail`;
    const body = {
      id: mailList.map((mail) => mail.id),
    };
    let result;
    try {
      await postData(apiUrl, body).then((result) => {
        setToastNotification({
          message: "Mail delete successfully",
          state: true,
        });
        setLoading(false);
      });
    } catch (error) {
      setToastNotification({
        message: error.message,
        state: true,
      });
      setLoading(false);
    }
    return result;
  };

  const handleToastNotificationClose = () => {
    setToastNotification({
      state: false,
      message: "",
    });
  };

  useEffect(() => {
    getProvider();
  }, []);

  useEffect(() => {
    setIsMailPreviewOpen({ mailId: "", state: false, isDraft: false });
  }, [tabState]);

  useEffect(() => {
    if (provider) {
      fetchMailData();
    }
  }, [
    provider,
    searchData,
    pageNo,
    rowPerPage,
    sortOrder,
    sortOrderBy,
    tabState,
    mailDuration,
  ]);

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleToastNotificationClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <>
      {provider === "" && <DialogCreate onDialog={onDialog} />}
      <Snackbar
        open={toastNotification.state}
        onClose={handleToastNotificationClose}
        message={toastNotification.message}
        action={action}
        sx={{
          "& .css-1eqdgzv-MuiPaper-root-MuiSnackbarContent-root": {
            background:
              "linear-gradient(125.2deg, #099c73 8.04%, #015462 127.26%)",
            color: "white",
          },
        }}
      />
      <div className="mail-box-screen">
        <div className="mail-box-header">
          <div className="mail-box-sub-header">
            <h2>Mail box</h2>
            <div
              className={`${
                isMailPreviewOpen.state
                  ? "mail-box-action"
                  : "  mail-box-action active"
              }`}
            >
              <button
                onClick={() => {
                  setSortOrder("asc");
                  setSortOrderBy("subject");
                  setPageNo(1);
                  setRowPerPage(10);
                  setTabState("inbox");
                }}
                className={`buttons ${tabState === "inbox" ? "active" : ""}`}
              >
                Inbox
              </button>
              <button
                onClick={() => {
                  setSortOrder("asc");
                  setSortOrderBy("subject");
                  setPageNo(1);
                  setRowPerPage(10);
                  setTabState("sent");
                }}
                className={`buttons ${tabState === "sent" ? "active" : ""} `}
              >
                Sent
              </button>
              {provider !== "gmail" && (
                <button
                  onClick={() => {
                    setSortOrder("asc");
                    setSortOrderBy("subject");
                    setPageNo(1);
                    setRowPerPage(10);
                    setTabState("draft");
                  }}
                  className={`buttons ${tabState === "draft" ? "active" : ""} `}
                >
                  Draft
                </button>
              )}
            </div>
          </div>{" "}
          <SendMail action="new" />
        </div>
        <div className="mail-box">
          <div
            className={`${
              !isMailPreviewOpen.state
                ? " mail-box-container active"
                : "mail-box-container"
            }`}
          >
            <div className="mail-box-table-header">
              <div className="mail-box-search">
                <div className="mail-box-search-bar">
                  <input
                    className="search-bar-input"
                    onChange={(event) => setSearch(event.target.value)}
                    placeholder="Search by Subject, Email, Date"
                  ></input>
                  <div className="search-icon">
                    <SearchIcon sx={{ color: "white" }} />
                  </div>
                </div>
                <Dropdown
                  items={dateEnum}
                  width={90}
                  value={mailDuration}
                  setValue={(value) => {
                    setMailDuration(value);
                    setPageNo(1);
                  }}
                />
              </div>
              <div
                className={`mail-box-table-header_action-icon ${
                  selectedMailId.length > 0 ? "active" : ""
                }`}
              >
                <Tooltip title={isMailRead ? "mark as unread" : "mark as read"}>
                  <IconButton
                    onClick={async () => {
                      setIsMailRead((prev) => !prev);
                      const result = await sendData();
                      if (result) fetchMailData();
                    }}
                  >
                    {isMailRead && <MarkEmailReadOutlinedIcon />}
                    {!isMailRead && <DraftsOutlinedIcon />}
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton
                    onClick={() => {
                      const result = deleteMailHandler(selectedMailId);
                      result.then(() => {
                        fetchMailData();
                      });
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
            <div className="mail-box-table">
              {
                mailList.totalCount === 0 ?
                <DashboardEmptyDataComponent/>
                :
              <Table
                setSelectedMailId={setSelectedMailId}
                selectedMailId={selectedMailId}
                mailList={mailList.data}
                totalCount={mailList.totalCount}
                loading={loading}
                sortOrder={sortOrder}
                sortOrderBy={sortOrderBy}
                pageNo={pageNo}
                rowPerPage={rowPerPage}
                setSortOrder={setSortOrder}
                setSortOrderBy={setSortOrderBy}
                setPageNo={setPageNo}
                setRowPerPage={setRowPerPage}
                setIsMailPreviewOpen={setIsMailPreviewOpen}
                tabState={tabState}
              />
}
            </div>
            {mailList?.data?.length > 0 && (
              <div className="mail-box-table-pagination">
                <div className="pagination-result">{`Showing results ${
                  pageNo === 1 ? 1 : rowPerPage * (pageNo - 1) + 1
                }-${
                  rowPerPage * pageNo > mailList.totalCount
                    ? mailList.totalCount
                    : rowPerPage * pageNo
                } of ${mailList.totalCount} `}</div>
                <div className="table-pagination">
                  <Pagination
                    count={Math.ceil(totalNoOfPage || 1)}
                    shape="rounded"
                    onChange={(event, value) => {
                      setPageNo(value);
                    }}
                    sx={{
                      "& .css-10w330c-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected":
                        {
                          background: "#099C73 !important",
                        },
                    }}
                  />
                </div>
                <div className="pagination-dropdown">
                  <span className="pagination-dropdown-left">Show</span>
                  <Dropdown
                    items={items}
                    width={64}
                    value={10}
                    setValue={(value) => {
                      setRowPerPage(value);
                      setPageNo(1);
                    }}
                  />
                  <span className="pagination-dropdown-right">on page</span>
                </div>
              </div>
            )}
          </div>
          <div
            className={`mail-box-preview ${
              isMailPreviewOpen.state ? "active" : ""
            }`}
          >
            <div className="mail-box-preview_header">
              <div>Mail Preview</div>
              <div>
                <IconButton
                  onClick={() =>
                    setIsMailPreviewOpen((state) => {
                      return {
                        mailId: state.mailId,
                        state: false,
                        isDraft: tabState === "draft",
                      };
                    })
                  }
                  className={`${
                    tabState === "draft" && isMailPreviewOpen.state
                      ? "mail-box-preview_close-icon draft"
                      : "mail-box-preview_close-icon"
                  }`}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
            {isMailPreviewOpen.state ? (
              <MailPreview
                selectedMailId={isMailPreviewOpen.mailId}
                setIsMailPreviewOpen={setIsMailPreviewOpen}
                state={tabState}
              />
            ) : (
              <div className="mail-preview-icon">
                <img src={mailPreviewIcon} alt="not found" />
                <span className="mail-box-text">Choose a mail to see </span>
                <span className="mail-box-text">its preview</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MailBoxScreen;
