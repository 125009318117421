import { useState, memo } from "react";
import { useNavigate } from "react-router-dom";
import SingleItem from "../../Global/table/SingleMobileItem";
import { UsersOffersTableTabsEnums } from "../../../enums/usersOffers/UsersOffersEnums";
import SelectOfferStatus from "../../JobsOffers/Table/SelectOfferStatus";
import { OfferLetterStatus } from "../../../enums/usersOffers/UsersOffersEnums";
import approve from "../../../image/icon/approve_job_ic.svg";
import reject from "../../../image/icon/reject_job_ic.svg";
import { AuthGuard, useHasPermission } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
import { RoundStatus, RoundType } from "../../../enums";
import ItemNotAvailable from "@ucrecruits/globalstyle/components/Table/ItemNotAvailable";

const userAuthId = localStorage.getItem("auth0Id");
const MobileTableUsersOffers = ({ item, setSelectedOffer, setModal }) => {
  const [openItem, setOpenItem] = useState(false);
  const navigate = useNavigate();
  const { checkUserPermission } = useHasPermission();

  const isOfferRound = item.round && item.round["title"] === RoundType.CONDITIONAL_OFFFER_LETTER_GENERATION && item.round["status"] !== RoundStatus.PENDING

  return (
    <div className="table-mobile__item">
      <div
        className={`table-mobile__top ${openItem ? "active" : ""}`}
        onClick={() => {
          setOpenItem((prev) => !prev);
        }}
      >
        <div className="table-mobile__top__left">
          <p className="table-mobile__top__name">{item.userName}</p>
        </div>
        <ArrowSvg />
      </div>
      <div className={`table-mobile__item__body ${openItem ? "open" : ""}`}>
        <div className='table-mobile__item__body__data'>
          {!isOfferRound ? <ItemNotAvailable text="Not Available Now" /> : ""}
          <SingleItem
            title={UsersOffersTableTabsEnums.CTC + ":"}
            text={item.candidateCTC || "-"}
            textStyle={"table-mobile__item__text"}
          />
          <SingleItem
            title={UsersOffersTableTabsEnums.OFFER_LETTER_APPROVED_BY + ":"}
            text={item.approverName}
            textStyle={"table-mobile__item__text table-mobile__item__text--green"}
          />
          <SingleItem
            title={UsersOffersTableTabsEnums.JOINING_DATE + ":"}
            text={item.joiningDate}
            textStyle={"table-mobile__item__text"}
          />
          <SingleItem
            title={UsersOffersTableTabsEnums.OFFER_LETTER_STATUS + ":"}
            text={""}
            textStyle={"table-mobile__item__text"}
          />
          <div className="table-mobile__item__wrap ">
            <SelectOfferStatus
              id={item.id}
              status={item.letterStatus}
              dropdown={false}
            />
          </div>
          <SingleItem
            title={UsersOffersTableTabsEnums.CANDIDATE_ACTION_STATUS + ":"}
            text={item.candidateStatus || "-"}
            textStyle={"table-mobile__item__text"}
          />
          <div className="table-mobile__item__body__action">
            {item.letterStatus === OfferLetterStatus.NO_GENERATED && (
              <>
                <AuthGuard module={'offer'} permission='view'>
                  <div className="table-mobile__item__action-button table-mobile__item__action-button--half">
                    <EyeHideIcon />
                    Not Generated
                  </div>
                </AuthGuard>
                {isOfferRound &&
                  <AuthGuard module={'offer'} permission={['add', 'edit']}>
                    <button
                      className="table-mobile__item__action-button table-mobile__item__action-button--half"
                      onClick={() =>
                        navigate(
                          `/recruitment/offers/${item.jobId}/${item.userId}/${item.roundId}/${item.subscribeId}`
                        )
                      }
                    >
                      <GenerateIcon />
                      Generate
                    </button>
                  </AuthGuard>
                }
              </>
            )}
            {item.letterStatus === OfferLetterStatus.DRAFT && (
              <>
                <div className="table-mobile__item__action-button">
                  <EyeHideIcon />
                  Not Generated
                </div>
                {checkUserPermission('offer', 'edit') &&
                  <button
                    className="table-mobile__item__action-button table-mobile__item__action-button--half"
                    onClick={() =>
                      navigate(`/recruitment/offers/edit/${item.offerId}`)
                    }
                  >
                    <EditIcon />
                    Edit Fields
                  </button>
                }
                {checkUserPermission('offer', 'delete') &&
                  <button
                    className="table-mobile__item__action-button table-mobile__item__action-button--half"
                    onClick={() => {
                      setSelectedOffer(item);
                      setModal("delete-offer");
                    }}
                  >
                    <DeleteIcon />
                    Delete
                  </button>
                }
              </>
            )}
            {item.letterStatus === OfferLetterStatus.PENDING && (
              <>
                <button
                  className="table-mobile__item__action-button show-action"
                  onClick={() =>
                    navigate(
                      `/recruitment/offers/preview/${item.jobId}/${item.userId}`
                    )
                  }
                >
                  <EyeDisplayIcon />
                  See Offer Letter
                </button>

                {item.approverAuthId === userAuthId && checkUserPermission('offer', 'edit') && (
                  <>
                    <button
                      className="table-mobile__item__action-button table-mobile__item__action-button--half"
                      onClick={() => {
                        setModal("offer-approved");
                        setSelectedOffer(item);
                      }}
                    >
                      <img src={approve} alt="approve-icon" />
                      Approve
                    </button>

                    <button
                      className="table-mobile__item__action-button table-mobile__item__action-button--half"
                      onClick={() => {
                        setModal("offer-rejected");
                        setSelectedOffer(item);
                      }}
                    >
                      <img src={reject} alt="reject-icon" />
                      Reject
                    </button>
                  </>
                )}
              </>
            )}

            {(item.letterStatus === OfferLetterStatus.REJECTED ||
              item.letterStatus === OfferLetterStatus.CLOSED) && (
                <>
                  <button
                    className="table-mobile__item__action-button show-action"
                    onClick={() =>
                      navigate(
                        `/recruitment/offers/preview/${item.jobId}/${item.userId}`
                      )
                    }
                  >
                  <EyeDisplayIcon />
                    See Offer Letter
                  </button>
                  <AuthGuard module='offer' permission='edit'>
                    <button
                      className="table-mobile__item__action-button table-mobile__item__action-button--half"
                      onClick={() => {
                        if (
                          !(
                            item.letterStatus === OfferLetterStatus.CLOSED ||
                            item.letterStatus === OfferLetterStatus.REJECTED
                          )
                        ) {
                          setSelectedOffer(item);
                          setModal("close-offer");
                        } else
                          navigate(
                            `/recruitment/offers/${item.jobId}/${item.userId}/${item.roundId}/${item.subscribeId}`
                          );
                      }}
                    >
                      <SendIcon />
                      Resent
                    </button>
                  </AuthGuard>
                  <AuthGuard module='offer' permission='delete'>
                    <button
                      className="table-mobile__item__action-button table-mobile__item__action-button--half"
                      onClick={() => {
                        setSelectedOffer(item);
                        setModal("delete-offer");
                      }}
                    >
                      <DeleteIcon />
                      Delete
                    </button>
                  </AuthGuard>
                </>
              )}

            {(item.letterStatus === OfferLetterStatus.APPROVED ||
              item.letterStatus === OfferLetterStatus.SENT ||
              item.letterStatus === OfferLetterStatus.GENERATED) && (
                <>
                  <button
                    className="table-mobile__item__action-button table-mobile__item__action-button--half show-action"
                    onClick={() =>
                      navigate(
                        `/recruitment/offers/preview/${item.jobId}/${item.userId}`
                      )
                    }
                  >
                    <EyeDisplayIcon />
                    See Offer Letter
                  </button>
                  <AuthGuard module='offer' permission='edit'>
                    <button
                      className="table-mobile__item__action-button table-mobile__item__action-button--half"
                      onClick={() => {
                        if (
                          !(
                            item.letterStatus === OfferLetterStatus.CLOSED ||
                            item.letterStatus === OfferLetterStatus.REJECTED
                          )
                        ) {
                          setSelectedOffer(item);
                          setModal("close-offer");
                        } else
                          navigate(
                            `/recruitment/offers/${item.jobId}/${item.userId}/${item.roundId}/${item.subscribeId}`
                          );
                      }}
                    >
                      <SendIcon />
                      Resent
                    </button>
                  </AuthGuard>
                </>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(MobileTableUsersOffers);
const ArrowSvg = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6751_20970)">
        <path
          d="M1 3L5 7L9 3"
          stroke="#5F6E80"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6751_20970">
          <rect
            width="10"
            height="10"
            fill="white"
            transform="translate(0 10) rotate(-90)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

const EyeDisplayIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.833496 10.0002C0.833496 10.0002 4.16683 3.3335 10.0002 3.3335C15.8335 3.3335 19.1668 10.0002 19.1668 10.0002C19.1668 10.0002 15.8335 16.6668 10.0002 16.6668C4.16683 16.6668 0.833496 10.0002 0.833496 10.0002Z"
        stroke="#737980"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 12.5C11.3807 12.5 12.5 11.3807 12.5 10C12.5 8.61929 11.3807 7.5 10 7.5C8.61929 7.5 7.5 8.61929 7.5 10C7.5 11.3807 8.61929 12.5 10 12.5Z"
        stroke="#737980"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const EyeHideIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1063_31455)">
      <path
        d="M11.7668 11.7668C11.538 12.0125 11.262 12.2095 10.9553 12.3461C10.6486 12.4828 10.3176 12.5562 9.9819 12.5622C9.64623 12.5681 9.3128 12.5063 9.0015 12.3806C8.69021 12.2549 8.40743 12.0677 8.17003 11.8303C7.93264 11.5929 7.74549 11.3101 7.61975 10.9988C7.49402 10.6875 7.43227 10.3541 7.43819 10.0184C7.44411 9.68277 7.51759 9.35172 7.65423 9.04506C7.79087 8.73839 7.98787 8.46239 8.2335 8.23352M14.9502 14.9502C13.5257 16.036 11.7911 16.6376 10.0002 16.6668C4.16683 16.6668 0.833496 10.0002 0.833496 10.0002C1.87007 8.06843 3.30778 6.38069 5.05016 5.05018L14.9502 14.9502ZM8.25016 3.53352C8.82377 3.39925 9.41105 3.33213 10.0002 3.33352C15.8335 3.33352 19.1668 10.0002 19.1668 10.0002C18.661 10.9465 18.0577 11.8375 17.3668 12.6585L8.25016 3.53352Z"
        stroke="#737980"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M0.833496 0.833496L19.1668 19.1668"
        stroke="#737980"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1063_31455">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const EditIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1665 2.49993C14.3854 2.28106 14.6452 2.10744 14.9312 1.98899C15.2171 1.87054 15.5236 1.80957 15.8332 1.80957C16.1427 1.80957 16.4492 1.87054 16.7352 1.98899C17.0211 2.10744 17.281 2.28106 17.4998 2.49993C17.7187 2.7188 17.8923 2.97863 18.0108 3.2646C18.1292 3.55057 18.1902 3.85706 18.1902 4.16659C18.1902 4.47612 18.1292 4.78262 18.0108 5.06859C17.8923 5.35455 17.7187 5.61439 17.4998 5.83326L6.24984 17.0833L1.6665 18.3333L2.9165 13.7499L14.1665 2.49993Z"
        stroke="#737980"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const GenerateIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1063_31461)">
        <path
          d="M11.6665 2.5V5.83333C11.6665 6.05435 11.7543 6.26631 11.9106 6.42259C12.0669 6.57887 12.2788 6.66667 12.4998 6.66667H15.8332"
          stroke="#099C73"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M14.1665 17.5H5.83317C5.39114 17.5 4.96722 17.3244 4.65466 17.0118C4.3421 16.6993 4.1665 16.2754 4.1665 15.8333V4.16667C4.1665 3.72464 4.3421 3.30072 4.65466 2.98816C4.96722 2.67559 5.39114 2.5 5.83317 2.5H11.6665L15.8332 6.66667V15.8333C15.8332 16.2754 15.6576 16.6993 15.345 17.0118C15.0325 17.3244 14.6085 17.5 14.1665 17.5Z"
          stroke="#099C73"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10 9.1665V14.1665"
          stroke="#099C73"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.5 11.6665H12.5"
          stroke="#099C73"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1063_31461">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const DeleteIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 5H4.16667H17.5"
        stroke="#FE4672"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.66675 4.99996V3.33329C6.66675 2.89127 6.84234 2.46734 7.1549 2.15478C7.46746 1.84222 7.89139 1.66663 8.33342 1.66663H11.6667C12.1088 1.66663 12.5327 1.84222 12.8453 2.15478C13.1578 2.46734 13.3334 2.89127 13.3334 3.33329V4.99996M15.8334 4.99996V16.6666C15.8334 17.1087 15.6578 17.5326 15.3453 17.8451C15.0327 18.1577 14.6088 18.3333 14.1667 18.3333H5.83341C5.39139 18.3333 4.96746 18.1577 4.6549 17.8451C4.34234 17.5326 4.16675 17.1087 4.16675 16.6666V4.99996H15.8334Z"
        stroke="#FE4672"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.33325 9.16663V14.1666"
        stroke="#FE4672"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.6667 9.16663V14.1666"
        stroke="#FE4672"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const SendIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6665 2.5V5.83333C11.6665 6.05435 11.7543 6.26631 11.9106 6.42259C12.0669 6.57887 12.2788 6.66667 12.4998 6.66667H15.8332"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10 18.708V17.5M10 15.9303H12.7502M10 15.9303L12.1268 17.9488C12.7598 18.587 13.581 19.0002 14.4666 19.126C15.3523 19.2519 16.2545 19.0836 17.0372 18.6466C17.82 18.2095 18.4409 17.5274 18.8064 16.7029C19.1719 15.8784 19.2623 14.9563 19.0638 14.0755C18.8654 13.1946 18.3889 12.4028 17.7061 11.8193C17.1664 11.358 16.521 11.0467 15.8332 10.9108M10 15.9303V17.5M11.1505 13.6154C11.4477 12.7634 12.011 12.032 12.7555 11.5315C13.5001 11.0309 14.3855 10.7883 15.2784 10.8402C15.4655 10.8511 15.6508 10.8748 15.8332 10.9108M10 17.5H5.83317C5.39114 17.5 4.96722 17.3244 4.65466 17.0118C4.3421 16.6993 4.1665 16.2754 4.1665 15.8333V4.16667C4.1665 3.72464 4.3421 3.30072 4.65466 2.98816C4.96722 2.67559 5.39114 2.5 5.83317 2.5H11.6665L15.8332 6.66667V10.9108"
        stroke="#099C73"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
