import { memo, useState } from "react";
import { store, useTypedSelector } from "../../store";
import { setPopupWithInstruction, setRefetchDomainAssessment } from "../../store/reducers/domainAssessmentReducer";
import { patchData } from "../../hook/http";
import { getEnv } from "@urecruits/api";
import RichTextEditor from "./popup/RichTextEditor";
import { AuthGuard } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
const { API_ASSESSMENT } = getEnv()

const getPopupWithList = (state) => state.domain_assessment.popupWithInstruction;

const PopupWithInstruction = () => {
  const popupWithList = useTypedSelector(getPopupWithList);
  const [isEditable, setIsEditable] = useState(true);
  const [data, setData] = useState(popupWithList?.instruction);

  const handleSubmit = async () => {
    patchData(`${API_ASSESSMENT}/api/domain-questions/instruction/${popupWithList?.id}`, {
      instruction: data
    })
    store.dispatch(setRefetchDomainAssessment())
    store.dispatch(setPopupWithInstruction({ open: false, id: -1, instruction: null, title: null }))
    setIsEditable(true)
  }
  return (
    <AuthGuard module='assessment' permission='view'>
      <div className="instruction_popup">
        <div className="instruction_popup__step">
          <div className="instruction_popup__head">
            <p className="instruction_popup__head__headline">Instructions</p>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() =>
                store.dispatch(setPopupWithInstruction({ open: false, id: -1, instruction: null, title: null }))
              }
              className="instruction_popup__head__close"
            >
              <path
                d="M18 6L12 12M6 18L12 12M12 12L6 6L18 18"
                stroke="#C1C5CB"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className="instruction_popup_body">
            <div className="instruction_popup__title">
              <h6>{popupWithList?.title}</h6>
            </div>
            <RichTextEditor
              placeholder={"Enter instruction here..."}
              setValue={setData}
              value={data}
              readOnly={isEditable}
            />
          </div>
          <AuthGuard module='assessment' permission='edit'>
            <div className="instruction_popup__bottom end">
              {isEditable ? <>
                <button className='instruction_popup__numbered-list__button' onClick={() => {
                  setIsEditable(false)
                }}>
                  Edit
                </button>
              </>
                : <>
                  <button
                    className="popup__bottom__cancel button--empty"
                    onClick={() => { setIsEditable(true) }}
                  >
                    Cancel
                  </button>
                  <button
                    className="popup__bottom__button button--filled"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </>
              }
            </div>
          </AuthGuard>
        </div>
      </div>
    </AuthGuard>
  );
};


export default memo(PopupWithInstruction);
