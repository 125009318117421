import type { PayloadAction } from '@reduxjs/toolkit';
// eslint-disable-next-line no-duplicate-imports
import { createSlice } from "@reduxjs/toolkit";
import { IPositionOption, IPositionWorkflowPopup } from "../../types/redux/position-workflow";
import { WorkFlowEnums } from "../../enums/workflow/WorkFlowEnums";


export const initialStatePopupWorkflowNew: IPositionWorkflowPopup = {
	title: '',
	activeOptions: [],
	inActiveOptions: [
		{
			id: 1,
			settings: false,
			name: WorkFlowEnums.HR_Audio_Video_Interview
		},
		{
			id: 2,
			settings: true,
			name: WorkFlowEnums.Functional_Domain_Assessment
		},
		{
			id: 3,
			settings: true,
			name: WorkFlowEnums.Live_Task_Coding_Assessment
		},
		{
			id: 4,
			settings: true,
			name: WorkFlowEnums.Take_Home_Coding_Assessment
		},
		{
			id: 5,
			settings: false,
			name: WorkFlowEnums.Technical_Audio_Video_Interview
		},
		{
			id: 6,
			settings: false,
			name: WorkFlowEnums.Senior_HR_Audio_Video_Interview
		},
		{
			id: 7,
			settings: false,
			name: WorkFlowEnums.Conditional_Offer_Letter_Generation
		},
		{
			id: 8,
			settings: false,
			name: WorkFlowEnums.Background_Screening
		},
		{
			id: 9,
			settings: false,
			name: WorkFlowEnums.Drug_Screening
		},
	],
	timeSlots: {
		days: [],
		daysAll: false,
		time: []
	},
	domains: {
		assessments: null,
		reviewers: [],
		deadline: null,
		errors: []
	},
	liveCoding: {
		questionType: null,
		questionList: null,
		deadline: null,
		durations: null,
		errors: []
	},
	takeHomeTask: {
		questionType: null,
		questionList: null,
		deadline: null,
		durations: null,
		errors: []
	},
	reviewersList: []
}

export const pwPopup = createSlice({
	name: 'pwPopup',
	initialState: initialStatePopupWorkflowNew,
	reducers: {
		setDefaultValueOfAssessment: (state, action: PayloadAction<IPositionOption>) => {
			const item = action.payload;
			switch (item.name) {
				case WorkFlowEnums.Functional_Domain_Assessment:
					state.domains = {
						assessments: null,
						reviewers: [],
						deadline: null,
						errors: [],
					}
					return;
				case WorkFlowEnums.Live_Task_Coding_Assessment:
					state.liveCoding = {
						questionType: null,
						questionList: null,
						deadline: null,
						durations: null,
						errors: [],
					}
					return;
				case WorkFlowEnums.Take_Home_Coding_Assessment:
					state.takeHomeTask = {
						questionType: null,
						questionList: null,
						deadline: null,
						durations: null,
						errors: [],
					}
					return;
				default:
					return;
			}
		},
		setReviewersPWPopup: (state, action: PayloadAction<Array<any>>) => {
			state.reviewersList = action.payload
		},
		setDefault: (state, action: PayloadAction<IPositionWorkflowPopup>) => {
			return action.payload
		},
		setErrorsLiveCoding: (state, action: PayloadAction<Array<string>>) => {
			state.liveCoding.errors = action.payload
		},
		setErrorsTakeHomeTask: (state, action: PayloadAction<Array<string>>) => {
			state.takeHomeTask.errors = action.payload
		},
		setErrorsDomains: (state, action: PayloadAction<Array<string>>) => {
			state.domains.errors = action.payload
		},
		setTitle: (state, action: PayloadAction<string>) => {
			state.title = action.payload
		},
		changeOrderActiveOptions: (state, action: PayloadAction<Array<IPositionOption>>) => {
			state.activeOptions = action.payload
		},
		addActiveOptions: (state, action: PayloadAction<IPositionOption[]>) => {
			const existingIds = new Set(state.activeOptions.map(option => option.id));
			const newOptions = action.payload.filter(option => !existingIds.has(option.id));

			state.activeOptions = [...state.activeOptions, ...newOptions];
		},
		removeActiveOptions: (state, action: PayloadAction<IPositionOption>) => {
			state.activeOptions = state.activeOptions.filter(x => x.id !== action.payload.id)
		},
		addInActiveOptions: (state, action: PayloadAction<IPositionOption>) => {
			state.inActiveOptions = [...state.inActiveOptions, action.payload].sort((a, b) => {
				if (a.id < b.id) return -1
				if (a.id > b.id) return 1
				return 0
			})
		},
		removeInActiveOptions: (state, action: PayloadAction<IPositionOption[]>) => {
			const removeIds = new Set(action.payload.map(option => option.id));
			state.inActiveOptions = state.inActiveOptions.filter(option => !removeIds.has(option.id));
		},
		setDays: (state, action: PayloadAction<Array<string>>) => {
			state.timeSlots.days = action.payload
		},
		setDaysAll: (state, action: PayloadAction<boolean>) => {
			state.timeSlots.daysAll = action.payload
		},
		setTime: (state, action: PayloadAction<Array<string>>) => {
			state.timeSlots.time = action.payload
		},
		setDeadlineDomains: (state, action: PayloadAction<number>) => {
			state.domains.deadline = action.payload
		},
		setDeadlineLiveCoding: (state, action: PayloadAction<number | null>) => {
			state.liveCoding.deadline = action.payload
		},
		setDurationsLiveCoding: (state, action: PayloadAction<number | null>) => {
			state.liveCoding.durations = action.payload
		},
		setDeadlineTakeHomeTask: (state, action: PayloadAction<number | null>) => {
			state.takeHomeTask.deadline = action.payload
		},
		setDurationsTakeHomeTask: (state, action: PayloadAction<number | null>) => {
			state.takeHomeTask.durations = action.payload
		},
		setQuestionTypeLivecoding: (state, action: PayloadAction<null | string>) => {
			state.liveCoding.questionType = action.payload
		},
		setQuestionListLiveCoding: (state, action: PayloadAction<null | number>) => {
			state.liveCoding.questionList = action.payload
		},
		setQuestionTypeTakeHomeTask: (state, action: PayloadAction<null | string>) => {
			state.takeHomeTask.questionType = action.payload
		},
		setQuestionListTakeHomeTask: (state, action: PayloadAction<null | number>) => {
			state.takeHomeTask.questionList = action.payload
		},
		setDomainAssessment: (state, action: PayloadAction<null | number>) => {
			state.domains.assessments = action.payload
		},
		setReviewersSelectedPWPopup: (state, action: PayloadAction<Array<number>>) => {
			state.domains.reviewers = action.payload
		},
		resetPositionWorkflow: (state) => {
			return initialStatePopupWorkflowNew;
		},
	},
})


export const {
	setReviewersSelectedPWPopup,
	setReviewersPWPopup,
	setDomainAssessment,
	setQuestionTypeLivecoding,
	setQuestionTypeTakeHomeTask,
	setQuestionListLiveCoding,
	setQuestionListTakeHomeTask,
	setErrorsDomains,
	setErrorsLiveCoding,
	setErrorsTakeHomeTask,
	setTitle,
	changeOrderActiveOptions,
	addActiveOptions,
	removeActiveOptions,
	addInActiveOptions,
	removeInActiveOptions,
	setDays,
	setDaysAll,
	setTime,
	setDeadlineDomains,
	setDefault,
	setDeadlineLiveCoding,
	setDurationsLiveCoding,
	setDeadlineTakeHomeTask,
	setDurationsTakeHomeTask,
	resetPositionWorkflow,
	setDefaultValueOfAssessment
} = pwPopup.actions
export default pwPopup.reducer