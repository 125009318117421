import { useState } from "react";
import { GenerateOfferTabs } from "../../../enums/generate-offer/generate-offer";
import { selectCustomStyle } from "../../../styles/selectCustomStyle";
import AsyncSelect from "react-select/async";
import axios from "axios";
import { getEnv } from "@urecruits/api";
import { getConfig, useHasPermission } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
const deleteIcon = require("../../../image/icon/delete_ic.svg");
const { API_RECRUITMENT } = getEnv();

const createTemplateOption =(overLimit=false)=>([
  {
    id: -1,
    label: "+ Create New Template",
    value: -1,
    ...(overLimit &&{overLimit})
  },
]);

const OfferBodyStep = ({
  formState: { errors },
  setValue,
  getValues,
  setIsCreateTemplate,
  setModal,
  register,
  setSelectedTemplateId,
  setCurrentTab,
}) => {
  const [templateSelected, setTemplateSelected] = useState(null);
  const {checkUserPermission} = useHasPermission()

  const handleDeleteTemplate = (e, templateId) => {
    e.stopPropagation();
    setSelectedTemplateId(templateId);
    setModal("delete-template");
  };

  const formatOptionLabel = (option) => {
    const { value, label,overLimit } = option;
    return <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <div>{label}</div>  
      {value !== -1 && (
        <div>
          <img
            src={deleteIcon}
            alt="delete template"
            onClick={(e) => handleDeleteTemplate(e, value)}
          />
        </div>
      )}
    </div>
  };

  const moveToPrevStep = () => {
    setCurrentTab(GenerateOfferTabs.OFFER_DETAILS);
  };

  return (
    <div className="step">
      <div className="step__head">
        <p className="step__head__headline">03. Offer Body</p>
      </div>

      <div className="step__body">
        <div className="step__row">
          <div className="step__item long">
            <p className="step__item__label">
              Select Offer Letter Template<span> *</span>
            </p>

            <AsyncSelect
              cacheOptions={false}
              loadOptions={() => callbackFunc(checkUserPermission('offer','add'))}
              formatOptionLabel={formatOptionLabel}
              defaultOptions
              name="hellosignTemplate"
              value={templateSelected || getValues("hellosignTemplate")}
              onChange={(option: any) => {
                if (option.value === -1) {
                  setIsCreateTemplate({visible:true,overLimit:option.overLimit||false});
                  return null;
                }
                setTemplateSelected(option);
                setValue("hellosignTemplate", option);
              }}
              styles={{
                ...selectCustomStyle,
                valueContainer: (provided, state) => ({
                  ...provided,
                  img: {
                    display: "none",
                  },
                }),
              }}
            />
            {errors.hellosignTemplate && (
              <p className="error-message">
                {errors.hellosignTemplate.message}
              </p>
            )}
          </div>
        </div>

        <div className="step__row">
          <div className="step__item">
            <p className="step__item__label">Signing Title</p>
            <input
              type="text"
              className="step__item__input"
              name="signingTitle"
              id="signingTitle"
              placeholder="Enter Title for email"
              autoComplete="off"
              {...register("signingTitle", { required: false })}
            />
          </div>

          <div className="step__item">
            <p className="step__item__label">Signing Subject</p>
            <input
              type="text"
              className="step__item__input"
              name="signingSubject"
              id="signingSubject"
              placeholder="Enter Subject for email"
              autoComplete="off"
              {...register("signingSubject", { required: false })}
            />
          </div>
        </div>

        <div className="step__row">
          <div className="step__item long">
            <p className="step__item__label">Signing Message</p>
            <input
              type="text"
              className="step__item__input"
              name="signingMessage"
              id="signingMessage"
              placeholder="Enter message for email"
              autoComplete="off"
              {...register("signingMessage", { required: false })}
            />
          </div>
        </div>
      </div>

      <div className="step__bottom">
        <button className="step__bottom__prev" onClick={moveToPrevStep}>
          Back
        </button>
        <button className="step__bottom__next button--empty" type="submit">
          Preview
        </button>
      </div>
    </div>
  );
};

export default OfferBodyStep;

const callbackFunc = async (offerAddPermission=true) => {
  const response = await axios(
    `${API_RECRUITMENT}/api/offer/hellosign/templates`,
    getConfig()
  ).then(({ data }) => data);

  return [
    ...(offerAddPermission && createTemplateOption(response.length >= 2 )),
    ...response.map((item) => {
      return {
        value: item.id,
        label: item.templateName,
        id: item.id,
      };
    }),
  ];
};
