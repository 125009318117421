import { store, useTypedSelector } from "../../../store";
import { memo } from "react";
import send from '../../../image/icon/send_ic.svg';
import defaultAvatar from "../../../image/icon/avatar.svg";
import TableCheckbox from "../../Global/table/TableCheckbox";
import { setSchedulePopupDetailViewJob, setSelectedItemsDetailViewJob } from "../../../store/reducers/jobDetailViewReducerMatched";
import eye from "../../../image/icon/eye-on.svg";
import play from '../../../image/icon/Vector.png';
import { Link, useNavigate, useParams } from "react-router-dom";
import { RoundStatuses } from "../../../enums/detail-view-job/detail-view-job-matched";
import { ShowToolTipComponent } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
import { findActiveRound, isInterview } from "../../../utils";
const videoIc = require('../../../image/icon/video_candidate_profile.svg')

const selectedItemsFunc = state => state.jobs_matched.selectedItems
const tableItemsFunc = state => state.jobs_matched.tableItems
const tableTabsFunc = state => state.jobs_matched.tabFilter

const MatchedProfileTBody = ({ horizontalScrollState, showPopup }: { horizontalScrollState: boolean, showPopup: ({ userId, userName }) => void }) => {
	const selectedItems = useTypedSelector(selectedItemsFunc)
	const tableItems = useTypedSelector(tableItemsFunc)
	const tableTabs = useTypedSelector(tableTabsFunc)
	const navigate = useNavigate()
	const params = useParams()

	const checkHandler = (id) => {
		if (selectedItems.includes(id)) store.dispatch(setSelectedItemsDetailViewJob(selectedItems.filter(x => x !== id)))
		else store.dispatch(setSelectedItemsDetailViewJob([...selectedItems, id]))
	}

	return <>
		<tbody className="table__tbody">
			{
				tableItems.map(item => {
					const activeRound = findActiveRound(item.rounds)
					const activeInterviewRound = isInterview(activeRound)
					return <tr className="table__tr" key={item.id}>
						<td className={`table__td sticky ${horizontalScrollState ? 'moved' : ''} manage-team__column__middle`}>
							<div className='manage-team__name'>
								<TableCheckbox id={item.id}
									handler={() => checkHandler(item.id)}
									checked={selectedItems.includes(item.id)} />
								<img src={item.avatar || defaultAvatar} alt='avatar' className='manage-team__name__avatar' />
								<p className='manage-team__name__text manage-team__text--link' style={{ cursor: 'pointer' }} onClick={() => {
									navigate(`/recruitment/candidate-profile/${item.id}/${params.id}`)
								}}>{`${item.firstname} ${item.middlename || ''} ${item.lastname}`}</p>
							</div>
						</td>
						{
							tableTabs?.filter(value => value.active).map(value => {
								if (value.id === 2) {
									return value.active &&
										<td className="table__td actions jobs-table__column__default" key={value.displayName}>
											<div className='jobs-table__locations'>
												<p className='manage-team__text--gray7 manage-team__text  '
													style={{ fontWeight: 900 }}>{item.percent}%</p>
											</div>
										</td>
								}
								if (value.id === 3) {
									return value.active &&
										<td className="table__td actions jobs-table__column__default" key={value.displayName}>
											<div className='jobs-table__locations'>
												<p className='manage-team__text--gray7 manage-team__text jobs-table__text__no-overflow'>{item.location}</p>
											</div>
										</td>
								}
								if (value.id === 4) {
									return value.active &&
										<td className="table__td actions jobs-table__column__default" key={value.displayName}>
											<div className='jobs-table__locations'>
												<p className='manage-team__text--gray7 manage-team__text '>{item.experience} years</p>
											</div>
										</td>
								}
								if (value.id === 5) {
									return value.active &&
										<td className="table__td actions jobs-table__column__default" key={value.displayName}>
											<div className='jobs-table__locations'>
												<p className='manage-team__text--gray7 manage-team__text '>${item.salary}</p>
											</div>
										</td>
								}
								if (value.id === 6) {
									return value.active &&
										<td className="table__td actions jobs-table__column__default" key={value.displayName}>
											<div className='jobs-table__locations'>
												<p className='manage-team__text--green manage-team__text '>{item.phone}</p>
											</div>
										</td>
								}
								if (value.id === 7) {
									return value.active &&
										<td className="table__td actions jobs-table__column__default" key={value.displayName}>
											<div className='jobs-table__locations'>
												<p className='manage-team__text--green manage-team__text '>{item.email}</p>
											</div>
										</td>
								}
								if (value.id === 9) {
									return value.active &&
										<td className="table__td actions jobs-table__column__default" key={value.displayName}>
											{
												item.workflow && <div className='table-dropdown-with-color-btn'>
													<div className={'table-dropdown-with-color-btn__wrap'}>
														<div
															className='manage-team__text manage-team__text--black table-dropdown-with-color-btn__button '>
															<p className={`table__status-block 
                                    ${item.status === RoundStatuses.Pending ? 'table__status-block--yellow' : ''}
                                    ${item.status === RoundStatuses.Progress ? 'table__status-block--orange' : ''}
                                    ${item.status === RoundStatuses.Completed ? 'table__status-block--green' : ''}
                                    ${item.status === RoundStatuses.Rejected ? 'table__status-block--red' : ''}
                                    `}>{item.status}</p>
														</div>
													</div>
												</div>
											}
										</td>
								}
								if (value.id === 10) {
									return value.active &&
										<td className="table__td actions jobs-table__column__default" key={value.displayName}>
											<p className='manage-team__text--green manage-team__text '>{item.scores} %</p>
										</td>
								}
								if (value.id === 11) {
									console.log({item})
									return value.active &&
										<td className="table__td actions jobs-table__column__default" key={value.displayName}>
											{
												item.status === RoundStatuses.Pending &&
												<ShowToolTipComponent text={item.workflow ? "Start workflow" : "You need to assign a workflow to this job first to start workflow"}>
													<img src={play} alt="Start-workflow-icon" className="manageAssignment__table__icon 'manage-team__text manage-team__text--black" onClick={() => {
														item.workflow ? showPopup({ userId: item.id, userName: item.firstname + " " + item.lastname }) : null
													}} />
												</ShowToolTipComponent>
											}
											{
												activeInterviewRound && <ShowToolTipComponent text={`Schedule meeting for ${activeRound?.title} round`}>
													<img src={videoIc} alt="schedule-meeting-icon" className="manageAssignment__table__icon 'manage-team__text manage-team__text--black" onClick={() => {
														store.dispatch(setSchedulePopupDetailViewJob({
															visible:true,
															candidateId: item.id,
															jobId: params.id,
															roundName: activeRound?.title || '',
															eventId: activeRound?.eventId || null,
															eventTitle: item.job || '',
														}))
													}} />
												</ShowToolTipComponent>
											}
											{
												item.status ? <>
													<ShowToolTipComponent text='View Scoreboard'>
														<img src={eye}
															alt="delete-icon"
															className="manageAssignment__table__icon"
															onClick={(e) => {
																navigate(`/recruitment/job/${params.id}/candidate-scoreboard/${item.id}`);
															}}
														/>
													</ShowToolTipComponent>
													{
														item.summary && (
															<Link to={`/recruitment/job/${params.id}/matched-profiles/${item.id}`}
																className="manageAssignment__table__icon link">
																<ShowToolTipComponent text='View Application form'>
																	<SeeApplyIcon />
																</ShowToolTipComponent>
															</Link>
														)
													}
												</> :

													<ShowToolTipComponent text='View Candidate profile'>
														<img src={eye}
															alt="delete-icon"
															className="manageAssignment__table__icon"
															onClick={(e) => {
																navigate(`/recruitment/candidate-profile/${item.id}`);
															}}
														/>
													</ShowToolTipComponent>
											}
										</td>
								}
							})
						}
					</tr>
				})
			}
		</tbody>
		{
			selectedItems.length > 0 && <div className='table__bulk-email'>
				<p className='table__bulk-email__count'>{selectedItems.length}<span> selected</span></p>
				<button className='table__bulk-email__button'>
					<img src={send} className="table__bulk-email__svg" alt={"bulk email"} />
					Bulk email
				</button>
			</div>
		}
	</>
}
export default memo(MatchedProfileTBody);


const SeeApplyIcon = () => {
	return (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"
			className="manageAssignment__table__icon">
			<path
				d="M11.0007 18.3327H5.00065C4.55862 18.3327 4.1347 18.1571 3.82214 17.8445C3.50958 17.532 3.33398 17.108 3.33398 16.666V3.33268C3.33398 2.89065 3.50958 2.46673 3.82214 2.15417C4.1347 1.84161 4.55862 1.66602 5.00065 1.66602H11.6673L16.6673 6.66602V11.4743"
				stroke="#099C73" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
			<path d="M11.666 1.66602V6.66602H16.666" stroke="#099C73" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
			<path d="M8.33268 7.5H7.49935H6.66602" stroke="#099C73" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
			<path
				d="M5.16602 13.834C5.16602 13.834 7.52965 9.33398 11.666 9.33398C15.8024 9.33398 18.166 13.834 18.166 13.834C18.166 13.834 15.8024 18.334 11.666 18.334C7.52965 18.334 5.16602 13.834 5.16602 13.834Z"
				stroke="#099C73" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
			<path
				d="M11.666 15.334C12.4944 15.334 13.166 14.6624 13.166 13.834C13.166 13.0056 12.4944 12.334 11.666 12.334C10.8376 12.334 10.166 13.0056 10.166 13.834C10.166 14.6624 10.8376 15.334 11.666 15.334Z"
				stroke="#099C73" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
		</svg>

	);
};

