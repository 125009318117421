import { default as ReactSelect } from "react-select";
import { ISelectLocationField } from "../../types/select-location";

const SelectComponent = ({ options, value, placeHolder, onChange, optionLabel, className, customSelectStyle, error, label, ...props }: ISelectLocationField) => {

  const customStyles = {
    ...customSelectStyle,
    menuList: (provided) => ({
      ...provided,
      maxHeight: "150px", 
      overflowY: "auto", 
    }),
  };

  return (
    <div className={className || ''}>
      {
        label && <p className={label.className}>{label.name}{label?.isRequired && <span>*</span>}</p>
      }
      <ReactSelect
        closeMenuOnSelect={true}
        hideSelectedOptions={false}
        options={options || []}
        placeholder={placeHolder || "Select"}
        {...(optionLabel && { getOptionLabel: (option) => option[`${optionLabel}`] })}
        styles={customStyles}
        isClearable
        value={value || null}
        onChange={onChange}
        {...props}
      />
      {
        error && <p className={error.className}>{error.message}</p>
      }
    </div>
  )
}

export default SelectComponent