import { memo, useState } from "react";
import SingleItem from "../../Global/table/SingleMobileItem";
import play from "../../../image/icon/Vector.png";
import { ReviewAndScoreTabs } from "../../../enums/reviewAndScore/ReviewAndScoreEnums";
import { useNavigate } from "react-router-dom";

const WorkflowMobileTBody = ({ item }) => {
  const [openItem, setOpenItem] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="table-mobile__item">
      <div
        className={`table-mobile__top ${openItem ? "active" : ""}`}
        onClick={() => {
          setOpenItem((prev) => !prev);
        }}>
        <div className="table-mobile__top__left">
          <p className="table-mobile__top__name">{`${item?.candidateName}`}</p>
        </div>
        <ArrowSvg />
      </div>
      <div className={`table-mobile__item__body ${openItem ? "open" : ""}`}>
        <SingleItem
          title={ReviewAndScoreTabs.JobTitle + ":"}
          text={item?.jobTitle}
          textStyle={"table-mobile__item__text"}
        />
        <SingleItem
          title={ReviewAndScoreTabs.questionId + ":"}
          text={item?.questionId}
          textStyle={"table-mobile__item__text"}
        />
        <SingleItem
          title={ReviewAndScoreTabs.questionName + ":"}
          text={item?.questionName}
          textStyle={"table-mobile__item__text"}
        />
        <SingleItem
          title={ReviewAndScoreTabs.assessmentType + ":"}
          text={
            item.assessmentType === "live-coding"
              ? "Live Coding"
              : "Take Home Task"
          }
          textStyle={"table-mobile__item__text table-mobile__item__text--green"}
        />
        {
          <button
            className="table-mobile__item__action-button table-mobile__item__action-button--green"
            onClick={() => {
              navigate(`/live-coding/playback/${item.userId}/${item.jobId}/${item.assessmentId}/${item.questionId}/${item?.playbackId}`, { state: item })
            }}>
            <img
              src={play}
              alt="resend-icon"
              className="manageAssignment__table__icon 'manage-team__text manage-team__text--black"
            />
            <p
              className="manage-team__text manage-team__text--black"
              style={{ fontWeight: 900 }}>
              Playback
            </p>
          </button>
        }
      </div>
    </div>
  );
};
export default memo(WorkflowMobileTBody);
const ArrowSvg = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_6751_20970)">
        <path
          d="M1 3L5 7L9 3"
          stroke="#5F6E80"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6751_20970">
          <rect
            width="10"
            height="10"
            fill="white"
            transform="translate(0 10) rotate(-90)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
