import tick from '../../images/icons/tick.svg';
import cross from '../../images/icons/cross.svg';
import { IDomainResult } from '../../types/domain-assessment-result-types';
import editIcon from '../../images/icons/edit_ic.svg';
import deleteIcon from '../../images/icons/delete_ic.svg';
import message from '../../images/icons/message.svg';
import { useRef, useState } from 'react';


const ShowAssessmentAnswers = ({ questions = [], candidateAnswers = [], candidate = false, feedback, editScore }: IDomainResult) => {

  const [isEditable, setIsEditable] = useState<boolean>(false)
  const [isSingleView, setIsSingleView] = useState<boolean>(false)
  const [selectedQuestion, setSelectedQuestion] = useState(0)

  
  return (<div className="domain-assessment-answers">
    <div className='domain-assessment-answers__header'>
      <span>
        {isSingleView && <span style={{ color: "#029CA5" }}>{selectedQuestion + 1}{`/${questions.length}`}</span>}
      </span>
      <button style={{ color: "#099C73" }} onClick={() => {
        setIsSingleView(prev=>!prev)
      }} >{isSingleView ? "Show all questions" : "Show one question"}
      </button>
    </div>
    {isSingleView && questions ?
      <>
        <ShowQuestions key={questions[selectedQuestion].id} candidateAnswers={candidateAnswers} question={questions[selectedQuestion]} candidate={candidate} feedback={feedback} editScore={editScore} isEditable={isEditable} setIsEditable={setIsEditable} />
        {questions.length > 1 &&
          <div className='domain-assessment-answers__single-view'>
            <button disabled={selectedQuestion === 0} onClick={() => setSelectedQuestion(prev => prev - 1)}>
              &larr; Previous
            </button>
            <button disabled={selectedQuestion === (questions.length - 1)} onClick={() => setSelectedQuestion(prev => prev + 1)}>
              Next &rarr;
            </button>
          </div>
        }
      </>
      :
      <>
        {questions.map(question => {
          return <ShowQuestions key={question.id} candidateAnswers={candidateAnswers} question={question} candidate={candidate} feedback={feedback} editScore={editScore} isEditable={isEditable} setIsEditable={setIsEditable} />
        })}
      </>
    }
  </div>)
}
export default ShowAssessmentAnswers


const ShowQuestions = ({candidateAnswers, question, candidate, feedback=null, editScore=null, isEditable, setIsEditable}) => {
  const candidateAnswer = candidateAnswers.find(ans => ans.questionId === question.id);
  const [score, setScore] = useState(() => calculateScore(question.type, question.id, candidateAnswer) || 0);
  const [tempScore, setTempScore] = useState(score); // Temporary score for edits
  const scoreRef = useRef(null);

  // Handlers
  const handleEditFeedback = () => feedback?.setFeedbackPopup(candidateAnswer?.id, candidateAnswer?.feedback);
  const handleDeleteFeedback = () => feedback?.onDeleteFeedback(candidateAnswer?.id);
  const handleScoreEdit = () => {
    setTempScore(score);
    setIsEditable(true);
  };

  const handleScoreConfirm = () => {
    const newScore = Number(scoreRef.current.value);
    editScore?.setScore(candidateAnswer?.id, newScore);
    setScore(newScore);
    setIsEditable(false);
  };

  const handleScoreCancel = () => {
    setTempScore(score); 
    if (scoreRef.current) {
      scoreRef.current.value = score; 
    }
    setIsEditable(false);
  };

  if (candidate) {
    return (
      <div key={question.id} className="domain-assessment-answers__container">
        <p className="domain-assessment-answers__container__question">{question.name}</p>
        <ShowOptions question={question} Answers={candidateAnswers}/>
        <div className="domain-assessment-answers__container__score">
          {!candidateAnswer ? "Not Answered" : null}
          <hr />
          {question?.type === "text" && question?.answers && <p>{question.answers}</p>}
          <div className={`domain-assessment-answers__container__score__content__candidate ${candidateAnswer?.feedback ? '' :'end'}`}>
            {candidateAnswer?.feedback && <p>{candidateAnswer.feedback}</p>}
            <pre>{question?.score && score} /{question?.score || 0}</pre>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div key={question.id} className="domain-assessment-answers__container">
      <p className="domain-assessment-answers__container__question">{question.name}</p>
        <ShowOptions question={question} Answers={candidateAnswers}/>
      <div className="domain-assessment-answers__container__score">
        {!candidateAnswer && "Not Answered"}
        <hr />
        {question?.type === "text" && question?.answers && <p>{question.answers}</p>}

        {/* Feedback & Scoring Section */}
        <div className="domain-assessment-answers__container__score__content">
          <div className="feedback-container">
            {/* Left Feedback Section */}
            <div className="feedback-container__left">
              {candidateAnswer?.feedback ? (
                <div className="show-feedback">
                  <div className="show-feedback__data">{candidateAnswer.feedback}</div>
                  {feedback && (
                    <div className="show-feedback__action">
                      <img src={editIcon} alt="Edit" onClick={handleEditFeedback} />
                      <img src={deleteIcon} alt="Delete" onClick={handleDeleteFeedback} />
                    </div>
                  )}
                </div>
              ) : (
                feedback && (
                  <div className="add-feedback" onClick={handleEditFeedback}>
                    <img src={message} alt="Add Feedback" />
                    <p>Add Feedback</p>
                  </div>
                )
              )}
            </div>

          {/* Right Score Section */}
            <div className="feedback-container__right">
              {editScore && (
                <div className="action">
                  {isEditable ? (
                    <div className='edit-score'>
                      <img src={cross} alt="Cancel" onClick={handleScoreCancel} />
                      <img src={tick} alt="Confirm" onClick={handleScoreConfirm} />
                    </div>
                  ) : (
                    <button onClick={handleScoreEdit}>Edit</button>
                  )}
                </div>
              )}

              <div className="marks">
                {editScore ? (
                  <>
                    <input
                    ref={scoreRef}
                    type="number"
                    defaultValue={tempScore}
                    onChange={(e) => setTempScore(Number(e.target.value))}
                    disabled={!isEditable}
                    max={question?.score}
                    min={0}
                    />
                    <span> /{"    "}{question?.score || 0}</span>
                  </>
                ) : (
                  <pre>{question?.score && score} /  {question?.score || 0}</pre>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const calculateScore = (type: string, id: number, candidateAnswer) => {
  let score = 0;
  if (candidateAnswer) {
    score += candidateAnswer?.score || 0
  }
  return score;
}

const ShowOptions = ({question, Answers=[]}) => {

  const type = question?.type;
  const questionAnswers = type && type === 'text' ? question?.answers : JSON.parse(question?.answers)

  let candidateAnswers = Answers?.find(ans => ans.questionId === question.id)?.answers
  candidateAnswers = candidateAnswers && (type !== 'text' ? JSON.parse(candidateAnswers) : candidateAnswers)

  if (type === 'single' && Array.isArray(questionAnswers)) {

    const answer =
      <div className="single">
        {questionAnswers?.map((item, index) => {
          const hasCandidateAnswer = candidateAnswers && candidateAnswers.find(ans => ans.id === item.id);
          return (
            <div key={`option-${index}`} className={`single__options ${item?.isCorrect ? "selected" : "non-selected"
              }`}>
              <div className='single__options__answer'>
                <input
                  type="radio"
                  className="custom-radio"
                  value={item?.id}
                  disabled
                  checked={hasCandidateAnswer ? true : false}
                />
                <label> {item?.name}</label>
              </div>
              <img src={item?.isCorrect ? tick : cross} />
            </div>
          );
        }
        )}
      </div>

    return answer
  } else if (type === 'multiple' && Array.isArray(questionAnswers)) {
    const answer =
      <div className="multiple">
        {questionAnswers?.map((item, index) => {
          const hasCandidateAnswer = candidateAnswers && candidateAnswers.find(ans => ans.id === item.id);
          return (
            <div
              key={index}
              className={`multiple__options answers ${item?.isCorrect ? "selected" : "non-selected"
                }`}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <input
                  type="checkbox"
                  className="checkbox-checked"
                  value={item?.id}
                  disabled
                  checked={hasCandidateAnswer ? true : false}
                />
                <label> {item?.name}</label>
              </div>
              <img src={item?.isCorrect ? tick : cross} />
            </div>
          );
        }
        )}
      </div>

    return answer
  } else if (type === 'text') {
    return <div className="text">
      {candidateAnswers && candidateAnswers}
    </div>
  }
}