import { getEnv } from "@urecruits/api";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import fetchData, { patchData } from "../hook/http";
import clockIc from "../../assessment/live-coding/assets/icons/clock_ic.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useTypedSelector } from "../store";
import { resetAnswerSheet, setCandidateAnswer, setIsStartTest, setTestData, setTotalQuestions } from "../store/reducers/AnswerSheetReducer";
import { Question } from "../components/reviewAndScore/DomainAssessment/CandidateDomainAssessment/QuestionPreview/question";
import VideoMeetLoader from "../../assessment/live-coding/components/video-meeting/Loader/VideoMeetLoader";
import { useHasPermission, AuthGuard } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
import { WorkFlowEnums } from "../enums/workflow/WorkFlowEnums";
const {API_ASSESSMENT,API_RECRUITMENT}=getEnv()
const getData = store => store.answer_sheet;

const AnswerSheetScreen = (props) => {
  const {checkUserPermission} = useHasPermission()
    const dispatch=useDispatch()
    const[domainAssessmentData, setDomainAssessmentData]=useState(null)
    const {
      testData,
      popupWithInstruction,
      candidateAnswer
    } = useTypedSelector(getData);
    const {resultId,candidateName}=useParams()
    const navigate=useNavigate()
    const [loading, setIsLoading]=useState(false)
    const [isDomainCompleted, setIsDomainCompleted]=useState(false)
    const [assessmentId, setAssessmentId]=useState(null)
    const [industryData, setIndustryData] = useState([]);
    const [departmentData, setDepartmentData] = useState([]);

    let totalScore=0;
    const handleCountScore=async ()=>{
      candidateAnswer?.candidateAnswers?.forEach((dd) => {
        totalScore += dd?.score;
    });
    await patchData(`${API_ASSESSMENT}/api/domain-result/totalScore/${candidateAnswer?.id}`,{
      totalScore:totalScore
    })

    if(candidateAnswer && testData){
      if(candidateAnswer.candidateAnswers?.length && testData.questions?.length){
        const total = testData.questions.reduce((acc,next)=>acc+next.score,0);
        const answered = candidateAnswer.candidateAnswers.reduce((acc,next)=>acc+next.score,0);
        const percentage = Math.floor((answered/total)*100);
        percentage>0 && await patchData(`${API_RECRUITMENT}/api/round/add-score`,{
          score:percentage,
          roundName:WorkFlowEnums.Functional_Domain_Assessment,
          jobId:candidateAnswer?.jobId,
          userId:candidateAnswer?.candidateId
        }).then(data=>console.log(data))
      }
    }
    }

    const element = document.getElementsByTagName('html');
    useEffect(() => {
        if (element && element[0] && element[0].classList) {
            element[0].classList.remove('theme-dark')
        }
    }, [element])

    useEffect(()=>{
    if(checkUserPermission(['assessment','job-post'],'view','AND') && popupWithInstruction?.open===false){      
      setIsLoading(true)
      fetchData(`${API_ASSESSMENT}/api/domain-result/${resultId}`)
      .then((res)=>{   
        dispatch(setCandidateAnswer(res))
        assessmentId=== res?.assessmentId && setIsLoading(false)
        setAssessmentId(res?.assessmentId)
        
      })}
    },[resultId,popupWithInstruction])

    
    useEffect(()=>{
      if(checkUserPermission(['assessment','job-post'],'view','AND') && assessmentId){
         fetchData(`${API_ASSESSMENT}/api/domain-questions/${assessmentId}`)
        .then((res)=>{
          setIsLoading(false)
            setDomainAssessmentData(res)
            dispatch(setTestData(res))
            dispatch(setTotalQuestions(res?.questions?.length))
            dispatch(setIsStartTest(false));
        })
      }
    },[assessmentId])

    useEffect(() => {
      fetchData(`${API_RECRUITMENT}/api/industry/label/`)
        .then((data) => {
          if (data) {
            setIndustryData(data); 
          }
        });
  
      fetchData(`${API_RECRUITMENT}/api/department/label/`)
        .then((data) => {
          if (data) {
            setDepartmentData(data); 
          }
        });
    }, []);
    useEffect(()=>{
      if(candidateAnswer?.candidateId && candidateAnswer?.jobId){
        fetchData(`${API_RECRUITMENT}/api/round/get-one/${candidateAnswer?.candidateId}/${candidateAnswer?.jobId}`).then(data=>{
          const domainAssessment = data.round?.rounds.find(i=>i?.title === WorkFlowEnums.Functional_Domain_Assessment)
          if(domainAssessment){
            setIsDomainCompleted(domainAssessment.status === 'Completed')
          }
        })
      }
    },[candidateAnswer])
  
    const handleIndustry = (item) => {
      const label = industryData.length > 0 && industryData.find((info) => info.id === item)?.label;
      return label;
    };
  
    const handleDepartment = (item) => {
      const label = departmentData.length > 0 && departmentData.find((info) => info.id === item)?.label;
      return label;
    };
    const renderItem = (item) => (
      item && (
        <>
          {item}
          <div className="dot"></div>
        </>
      )
    );
    
    const renderDuration = (duration) => (
      duration && (
        <div>
          <img
            src={clockIc}
            alt="clock ic"
            style={{
              minWidth: "12px",
              width: "12px",
              marginRight: "6px",
            }}
          />
          {`${duration}`}
        </div>
      )
    );

    return (
      <AuthGuard module={['job-post','assessment']} permission='view' option="AND">
        {loading ? (
          <VideoMeetLoader />
        ) : (
          <div className="domain-assessment">
            <div className="table-screen-top">
              <div className="table-screen-top__wrapper">
                <div>
                  <p style={{ fontSize: "16px" }} className="routes">
                    <span
                      onClick={() => {
                        navigate("/recruitment/review-score");
                      }}
                      style={{ color: "black", cursor: "pointer" }}>
                      Review & Score{" "}
                    </span>
                    /
                    <span
                      onClick={() => {
                          window.history.back();                      
                      }}
                      style={{ color: "black", cursor: "pointer" }}>
                      {" "}
                      Domain Assessment{" "}
                    </span>
                    /
                    <span style={{ cursor: "not-allowed" }}>
                      {" "}
                      Scoreboard
                    </span>
                  </p>
                 <h2>
                    {testData?.name}
                  </h2>
                  <span className="sub-content">
                    {renderItem(candidateName)}
                    {renderItem(handleIndustry(testData?.industryId))}
                    {/* {renderItem(handleDepartment(testData?.departmentId))} */}
                    {handleDepartment(testData?.departmentId)}
                    {/* {renderDuration(testData?.duration)} */}
                  </span>
                </div>
              </div>
            </div>
            <div className="domain-assessment__question">
              <Question isDomainCompleted={isDomainCompleted} />
            </div>
            { !isDomainCompleted&& checkUserPermission('assessment','edit') &&
              <AuthGuard module={['assessment','job-post']} permission='view' option="AND">
            <div className={`domain-assessment__footer`}>
              <div>
                <button
                  className="domain-assessment__footer__cancel_btn"
                  onClick={() => {
                    dispatch(resetAnswerSheet())
                    window.history.back();  
                  }}>
                  Cancel
                </button>
              </div>
               <div>
                <button
                  className="domain-assessment__footer__next_btn"
                  onClick={() => {
                      handleCountScore()
                      dispatch(resetAnswerSheet())
                       window.history.back();                  
                  }}>
                  Submit
                </button>
              </div> 
            </div>
            </AuthGuard>}
          </div>
        )}
      </AuthGuard>
    );
};

export default AnswerSheetScreen;