import { memo, useCallback, useLayoutEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
	getConfig,
	PaginationComponent,
	SearchFieldComponent,
	SmallLoaderComponent,
	TheadItemComponent,
	useSubscription
} from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
import { CSSTransition } from "react-transition-group";
import useTableClickAndDragScroll from "../hook/useTableClickAndDragScroll";
import useClickOutside from "../hook/useClickOutside";
import { ManageAssignmentTableOrder } from "../components/HOCs/OrderPopupHOCs";
import { store, useTypedSelector } from "../store";
import { sortingFunc } from "../utils/sortingFuncForTables";
import TopFilterButton from "../components/Global/table/TopFilterButton";
import MemoOrderSVG from "../components/Global/table/MemoOrderSVG";
import FilterWrapper from "../components/Global/table/FilterWrapper";
import { getEnv } from "@urecruits/api";
import NoResultsSearch from "../components/Global/table/NoResultsSearch";
import TableEmpty from "../components/Global/table/TableEmpty";
import {
	defaultFiltersManageAssignmentScreen,
	setCurrentPageManageAssignment,
	setAssessmentTypeManageAssignment,
	setEmptySearchManageAssignment,
	setEmptyTableManageAssignment,
	setJobManageAssignment,
	setLimitManageAssignment,
	setSearchValueManageAssignment,
	setSortByManageAssignment, setSortTypeManageAssignment, setTableItemsManageAssignment, setTotalCountManageAssignment,
	setFiltersInfoManageAssignment,
	setIsLoadingManageAssignment
} from "../store/reducers/manageAssignmentReducer";
import ManageAssignmentTBody from "../components/ManageAssignment/ManageAssignmentTBody";
import PopupWithAssessmentType from "../components/ManageAssignment/PopupWithAssessmentType";
import axios from "axios";
import FilterPopupManageAssignment from "../components/ManageAssignment/FilterPopupManageDomainAssessment";
import DeletePopup from "../components/ManageAssignment/popup/DeletePopup";
import ManageAssignmentMobileTable from "../components/ManageAssignment/ManageAssignmentMobileTable";
import PopupWithInstruction from "../components/DomainAssessment/PopupWithInstruction";
import { AuthGuard, useHasPermission } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
import { ManageAssignmentFilterTabs } from "../enums/manage-assignment/manageAssignment";

const { API_RECRUITMENT, API_ASSESSMENT } = getEnv()

const getFilters = state => state.manage_assignment.filters
const tableFunc = state => state.manage_assignment
const tableFilterFunc = state => state.manage_assignment.tabFilter
const getFilterDataFunc = state => state.manage_assignment.filterInfo
const getLimitFunc = state => state.manage_assignment.pagination.limit
const getPopupWithAssessment = (state) => state.manage_assignment.popupWithInstruction;
const getPopupWithInstruction = (state) => state.domain_assessment.popupWithInstruction;
const getPopupWithDelete = (state) => state.manage_assignment.popupWithDelete;

//TODO replace component
const FilterItems = state => <FilterPopupManageAssignment activeTab={state} />
const FilterPopupInnerWrap = (setState) => {
	const { checkUserPermission } = useHasPermission()
	const filters = useTypedSelector(getFilters)
	const filtersData = useTypedSelector(getFilterDataFunc)

	const limit = useTypedSelector(getLimitFunc)
	return <FilterWrapper setFilterPopup={setState}
		resetFunc={resetFunc}
		filters={filters}
		filterData={filtersData}
		submitFunc={setManageAssignmentScreenHandler}
		limit={limit}
	>
		{FilterItems}
	</FilterWrapper>
}

const ManageAssignmentScreen = () => {

	const { checkUserPermission } = useHasPermission()
	const { checkCurrentPackageType } = useSubscription()
	const popupWithInstruction = useTypedSelector(getPopupWithInstruction);
	const popupWithAssessmentTypes = useTypedSelector(getPopupWithAssessment);
	const popupWithDelete = useTypedSelector(getPopupWithDelete);
	const [orderPopup, setOrderPopup] = useState(false);
	const [horizontalScrollState, setHorizontalScrollState] = useState(false);
	const navigate = useNavigate()
	const orderPopupRef = useRef<null | HTMLButtonElement>(null);
	const tableRef = useRef(null);
	const table = useTypedSelector(tableFunc)
	const filtersData = useTypedSelector(getFilterDataFunc)
	const hasAssessmentPackage = (checkCurrentPackageType(['assessment','fullcycle']))

	useLayoutEffect(() => {
		hasAssessmentPackage && checkUserPermission('assessment', 'view') && setManageAssignmentScreenHandler(table.pagination.currentPage, table.pagination.limit, table.filters)
	}, [popupWithAssessmentTypes?.open, popupWithDelete.open, hasAssessmentPackage]);

	useLayoutEffect(() => {
		if (!checkUserPermission('job-post', 'add') || hasAssessmentPackage) {
			const tabs = filtersData?.tabs?.filter(i => i !== ManageAssignmentFilterTabs.jobId) || filtersData?.filter(i => i !== ManageAssignmentFilterTabs.jobId) || []
			store.dispatch(setFiltersInfoManageAssignment(tabs))
		}
	}, [filtersData.tabs?.length, hasAssessmentPackage])

	//TODO add filter array
	const filterArray = [
		table.filters.searchValue, table.filters.sortBy, table.filters.sortType
	]

	const setSearchCallback = useCallback((value) => {
		store.dispatch(setSearchValueManageAssignment(value))
		setManageAssignmentScreenHandler(1, table.pagination.limit, { ...table.filters, searchValue: value })
	}, filterArray);

	//TODO replace callback
	const emptyTableCallback = useCallback(() => {
		navigate('/manage-assignment')
	}, [],);

	useTableClickAndDragScroll(tableRef)

	const tdOrderCallback = useCallback((value) => sortingFunc(table, value, setSortTypeManageAssignment, setSortByManageAssignment, setManageAssignmentScreenHandler), filterArray,);
	useClickOutside(orderPopupRef, setOrderPopup)
	return <section>
		{
			popupWithInstruction.open && <PopupWithInstruction />
		}
		{
			table?.popupWithInstruction.open && <PopupWithAssessmentType />
		}
		{
			table.popupWithDelete.open && <DeletePopup />
		}
		<div className='table-screen-top'>
			<div className='table-screen-top__wrapper'>
				<h2>Manage Assignments</h2>
			</div>
			<AuthGuard module='assessment' permission='add'>
				<button className='button--filled table-screen-top__button' onClick={() => navigate('/manage-assignment')}>Add New</button>
			</AuthGuard>
		</div>
		<div className="table__wrapper">
			<div className="table__top">
				<div className='manage-team__top__left'>
					<AuthGuard module='assessment' permission='view'>
						<TopFilterButton>
							{
								FilterPopupInnerWrap
							}
						</TopFilterButton>
						<SearchFieldComponent searchValue={table.filters.searchValue} setSearchValue={setSearchCallback}
							placeholder={'Search by assignment title'} />
					</AuthGuard>
				</div>
				<button className={`manage-team__top__svg ${orderPopup ? 'active' : ''}`} ref={orderPopupRef}>
					<MemoOrderSVG setState={setOrderPopup} />
					{
						<CSSTransition in={orderPopup} timeout={300} classNames={'order-popup-mtm'} unmountOnExit mountOnEnter>
							<ManageAssignmentTableOrder setOrderPopup={setOrderPopup} />
						</CSSTransition>
					}
				</button>
			</div>
			{
				table.isLoading
				?
				<SmallLoaderComponent/>
				:
				table.emptySearch || table.emptyTable ? (
					table.emptySearch ? <NoResultsSearch limit={table.pagination.limit} resetFunc={resetFunc} />
						: <TableEmpty
							handler={checkUserPermission('assessment', 'add') ? emptyTableCallback : () => navigate("/")}
							cta={checkUserPermission('assessment', 'add') ? 'Create new Assignment' : "Go to Dashboard"}
							title={'No Assignments Available'}
							desc={checkUserPermission('assessment', 'add')  ?  "You haven't created any assignments yet. Create your Assignment for the jobs."  : ""}  
								/>
				)
					:
					<AuthGuard module={['assessment', 'job-post']} permission='view' option="AND">
						<>
							<table className='table' ref={tableRef} onScroll={(e: any) => {
								if (e.target.scrollLeft > 10 && !horizontalScrollState) setHorizontalScrollState(() => true)
								else if (e.target.scrollLeft < 10 && horizontalScrollState) setHorizontalScrollState(() => false)
							}}>
								<thead className="table__thead">
									<td
										className={`table__td sticky ${horizontalScrollState ? 'moved' : ''} table__td--thead jobs-table__column__middle`}>
										<TheadItemComponent title={table.fixedTab.displayName} handler={null} dbName={''} />
									</td>
									{
										table.tabFilter.map((item) => {
											if (item.displayName === 'Actions' && !checkUserPermission('assessment', 'delete')) {
												return;
											}
											return item.active &&
												<td className={`table__td  table__td--thead jobs-table__column__default`}
													key={item.id}>
													<TheadItemComponent title={item.displayName} handler={item.dbName ? tdOrderCallback : null}
														dbName={item.dbName} />
												</td>
										})
									}

								</thead>
								<ManageAssignmentTBody horizontalScrollState={horizontalScrollState} />
							</table>

							<div className='table-mobile'>
								{
									table.tableItems.map(item => {
										return <ManageAssignmentMobileTable key={item.id} item={item} />
									})
								}
							</div>
							<PaginationComponent
								limit={table.pagination.limit}
								currentPage={table.pagination.currentPage}
								totalCount={table.pagination.totalCount}
								setCurrentPage={setManageAssignmentScreenHandler}
								setLimit={setLimitHandler}
								filters={table.filters}
							/>
						</>
					</AuthGuard>
			}
		</div>
	</section>
}
export default memo(ManageAssignmentScreen)

export const setManageAssignmentScreenHandler = (page: number, limit: number, filters: any) => {
	store.dispatch(setCurrentPageManageAssignment(page))
	store.dispatch(setIsLoadingManageAssignment(true))
	const getData = async () => {
		await axios(`${API_ASSESSMENT}/api/assignment/all?limit=${limit}
		${filters.assessmentType.map(item => `&assignmentType=${item}`).join('')}
		${filters.jobId.map(item => `&jobId=${item?.value}`).join('')}
		&search=${encodeURIComponent(filters.searchValue)}
		&offset=${(page - 1) * limit}
		&sortType=${filters.sortType}
		&sortBy=${filters.sortBy}`, getConfig()).then(res => {
			store.dispatch(setTotalCountManageAssignment(res.data.count))
			const items = transformArrayToTableData(res.data.rows)
			store.dispatch(setTableItemsManageAssignment(items))
			if (items.length === 0) {
				if (filters.searchValue === '' &&
					filters.assessmentType.length === 0 && filters.jobId.length === 0) {
					store.dispatch(setEmptyTableManageAssignment(true))
				} else {
					store.dispatch(setEmptySearchManageAssignment(true))
				}
			} else {
				store.dispatch(setEmptySearchManageAssignment(false))
				store.dispatch(setEmptyTableManageAssignment(false))
			}
		})
	}
	getData().finally(()=>store.dispatch(setIsLoadingManageAssignment(false)))
}
const setLimitHandler = (value: any) => {
	store.dispatch(setLimitManageAssignment(value))
}
const transformArrayToTableData = (array: any[]): any[] => {
	return array.map((item) => {
		const hasOngoingAssignment = item.candidateStatus?.find(i => i.status == 'active')
		return {
			id: item.id,
			title: item.title,
			jobId: item.jobId,
			candidateIds: item?.candidate || [],
			candidates: item?.candidate?.length || 0,
			assessmentIds: getAssessmentTypes(item),
			assessmentType: getMainTypes(item),
			workflowId: item.workflowId,
			hasOngoingAssignment: hasOngoingAssignment ? true : false,
		}
	});
};
const getMainTypes = (item: any): string[] => {
	const mainTypes = [];

	if (item.domainId !== null) {
		mainTypes.push("Domain Assessment");
	}

	if (item.liveCodingId !== null) {
		mainTypes.push("Live Coding Assessment");
	}

	if (item.takeHomeTaskId !== null) {
		mainTypes.push("Take Home Task Assessment");
	}

	return mainTypes;
};
const getAssessmentTypes = (item: any): any[] => {
	const assessments = [
		{ id: item.domainId, label: "Domain Assessment" },
		{ id: item.liveCodingId, label: "Live Coding" },
		{ id: item.takeHomeTaskId, label: "Take Home Task" },
	];

	return assessments.filter((assessment) => assessment.id !== null);
};

const resetFunc = (pagination) => {
	store.dispatch(setAssessmentTypeManageAssignment([]))
	store.dispatch(setJobManageAssignment([]))
	store.dispatch(setSearchValueManageAssignment(''))
	store.dispatch(setSortByManageAssignment('id'))
	store.dispatch(setSortTypeManageAssignment('DESC'))
	setManageAssignmentScreenHandler(1, pagination, defaultFiltersManageAssignmentScreen)
}