import JobsTable from '../components/RecruitmentDashboard/JobsTable';
import PositionWorkflowList from '../components/RecruitmentDashboard/PositionWorkflowList';
import TeamMembers from '../components/RecruitmentDashboard/TeamMembers';
import CompanyLinks from '../components/RecruitmentDashboard/CompanyLinks';

import greetingImg from "../image/greeting_img.svg";
import CalendarDayView from "../components/ScreeningAndHiring/CalendarDayView/CalendarView";
import { getEnv } from "@urecruits/api";
import { useEffect, useState } from "react";
import fetchData from "../hook/http";
import { AuthGuard } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
const { API_RECRUITMENT } =getEnv()

const RecruitmentDashboard = () => {
	const [data, setData] = useState(null);
	const [timeOfDay, setTimeOfDay] = useState('');

	useEffect(() => {
	  const getCurrentTimeOfDay = () => {
		const currentDate = new Date();
		const currentHour = currentDate.getHours();
  
		if (currentHour >= 5 && currentHour < 12) {
		  setTimeOfDay('Morning');
		} else if (currentHour >= 12 && currentHour < 17) {
		  setTimeOfDay('Afternoon');
		} else if (currentHour >= 17 && currentHour < 20) {
		  setTimeOfDay('Evening');
		} else {
		  setTimeOfDay('Night');
		}
	  };
  
	  getCurrentTimeOfDay();
  
	  const intervalId = setInterval(getCurrentTimeOfDay, 60000);
  
	  return () => clearInterval(intervalId);
	}, []);
	useEffect(() => {
		fetchData(`${API_RECRUITMENT}/api/user`).then(
		(info)=>{
		const fullName=`${info?.firstname} ${info?.lastname}`
		setData(fullName)
		}
		);
	}, []);
	
	return (
		<div className="dashboard">
			<h2 className="dashboard__headline">Recruitment Home</h2>
			<div className="dashboard__inner screening">
				<div className="dashboard__top">
					<div className="dashboard__top__left">
						<div className="greeting">
							<div className="greeting__left">
								<p className="greeting__tagline">Good {timeOfDay}, {data}!</p>
								<p className="greeting__headline">Streamline Your Recruitment Process</p>
							</div>
							<div className="greeting__right">
								<img src={greetingImg} alt="greeting" className="greeting__right__img"/>
							</div>
						</div>
						<AuthGuard module='job-post' permission={['view','add']} >
							<JobsTable/>
						</AuthGuard>
					</div>
					<AuthGuard module='job-post' permission='view' >
					<div className="dashboard__top__right">
						<CalendarDayView />
					</div>
					</AuthGuard>
				</div>
				<div className="dashboard__bottom">
					<AuthGuard module='job-post' permission={['view','add']} >
						<PositionWorkflowList position={"left"}/>
					</AuthGuard>
					<AuthGuard module='team-members' permission={['view','add']} >
						<TeamMembers position={"middle"}/>
					</AuthGuard>
					<AuthGuard module='company-profile' permission='view' >
						<CompanyLinks position={"right"}/>
					</AuthGuard>
				</div>
			</div>
		</div>
	);
};

export default RecruitmentDashboard;