import {
	getConfig,
	PaginationComponent,
	SearchFieldComponent,
	SmallLoaderComponent,
	TheadItemComponent
} from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
import { getEnv } from "@urecruits/api";
import axios from "axios";
import { memo, useCallback, useLayoutEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import FilterWrapper from "../components/Global/table/FilterWrapper";
import MemoOrderSVG from "../components/Global/table/MemoOrderSVG";
import NoResultsSearch from "../components/Global/table/NoResultsSearch";
import TableEmpty from "../components/Global/table/TableEmpty";
import TopFilterButton from "../components/Global/table/TopFilterButton";
import { AssignmentCandidateTableOrder } from "../components/HOCs/OrderPopupHOCs";
import FilterPopupAssignmentCandidate from "../components/ManageAssignment/AssignmentCandidate/FilterPopupAssignmentCandidate";
import useClickOutside from "../hook/useClickOutside";
import AssignmentCandidateMobileTable from "../components/ManageAssignment/AssignmentCandidate/AssignmentCandidateMobileTable";
import useTableClickAndDragScroll from "../hook/useTableClickAndDragScroll";
import { store, useTypedSelector } from "../store";
import {
	defaultFiltersAssignmentCandidateScreen,
	setCandidateIdsAssignmentCandidate,
	setCurrentPageAssignmentCandidate,
	setEmptySearchAssignmentCandidate,
	setEmptyTableAssignmentCandidate,
	setIsLoadingAssignmentCandidate,
	setLimitAssignmentCandidate,
	setSearchValueAssignmentCandidate,
	setSortByAssignmentCandidate, setSortTypeAssignmentCandidate, setTableItemsAssignmentCandidate, setTotalCountAssignmentCandidate
} from "../store/reducers/assignmentCandidateReducer";
import { sortingFunc } from "../utils/sortingFuncForTables";
import PopupWithScheduleOn from "../components/ManageAssignment/AssignmentCandidate/PopupWithScheduleOn";
import AssignmentCandidateTBody from "../components/ManageAssignment/AssignmentCandidate/AssignmentCandidateTBody";
import { AuthGuard, useHasPermission } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";

const {API_RECRUITMENT,API_ASSESSMENT} = getEnv()
const paramData={jobId:null,candidateIds:[]}
const getFilters = state => state.assignment_candidate.filters
const tableFunc = state => state.assignment_candidate
const getFilterDataFunc = state => state.assignment_candidate.filterInfo
const getLimitFunc = state => state.assignment_candidate.pagination.limit
const getPopupWithAssessment = (state) => state.assignment_candidate.popupWithScheduleOn;
const getTitle = (state) => state.manage_assignment.openPreview;


//TODO replace component
const FilterItems = state => <FilterPopupAssignmentCandidate activeTab={state}/>
const FilterPopupInnerWrap = (setState) => {
	const filters = useTypedSelector(getFilters)
	const filtersData = useTypedSelector(getFilterDataFunc)
	const limit = useTypedSelector(getLimitFunc)
	const {jobId}= useParams()
	paramData.jobId=jobId

	return <FilterWrapper setFilterPopup={setState}
	                      resetFunc={resetFunc}
	                      filters={filters}
	                      filterData={filtersData}
	                      submitFunc={setManageAssignmentScreenHandler}
	                      limit={limit}
	>
		{FilterItems}
	</FilterWrapper>
}
const AssignmentCandidateScreen = () => {

	const {checkUserPermission} = useHasPermission()
	const popupWithAssessmentTypes = useTypedSelector(getPopupWithAssessment);
	const [orderPopup, setOrderPopup] = useState(false);
	const [horizontalScrollState, setHorizontalScrollState] = useState(false);
	const navigate = useNavigate()
	const orderPopupRef = useRef<null | HTMLButtonElement>(null);
	const tableRef = useRef(null);
	const table = useTypedSelector(tableFunc)
	const previewData = useTypedSelector(getTitle)
	paramData.candidateIds=table?.candidateId
	useLayoutEffect(() => {
		checkUserPermission('job-post','view') && setManageAssignmentScreenHandler(table.pagination.currentPage, table.pagination.limit, table.filters)
	}, [popupWithAssessmentTypes?.open]);

//TODO add filter array
	const filterArray = [
		table.filters.searchValue, table.filters.sortBy, table.filters.sortType
	]

	const setSearchCallback = useCallback((value) => {
		store.dispatch(setSearchValueAssignmentCandidate(value))
		setManageAssignmentScreenHandler(1, table.pagination.limit, {...table.filters, searchValue: value})
	}, filterArray);
//TODO replace callback
	const emptyTableCallback = useCallback(() => {
		navigate('/')
	}, [],);
useTableClickAndDragScroll(tableRef)

	const tdOrderCallback = useCallback((value) => sortingFunc(table, value, setSortTypeAssignmentCandidate, setSortByAssignmentCandidate, setManageAssignmentScreenHandler), filterArray,);
	useClickOutside(orderPopupRef, setOrderPopup)
	return <section>
			{
			table?.popupWithScheduleOn.open &&<PopupWithScheduleOn/>
			}
			{/* {
			table.popupWithDelete.open &&<DeletePopup/>
			} */}
			<div className='table-screen-top'>
			<div className='table-screen-top__wrapper'>
				<div >
					<p style={{fontSize:"16px"}}>
						<span onClick={()=>{
							navigate("/recruitment/manage-assignment")
						}} style={{color:"black",cursor:"pointer"}}>Manage Assignment </span>/
						<span style={{color:"black",cursor:"not-allowed"}}> {previewData?.title}</span>
					</p>
					<h2>{previewData?.title}</h2>
				</div>
			
			</div>
		</div>
		<div className="table__wrapper">
			<div className="table__top">
				<div className='manage-team__top__left'>
					<AuthGuard  module='assessment' permission='view'>
					<TopFilterButton>
						{
							FilterPopupInnerWrap
						}
					</TopFilterButton>
					<SearchFieldComponent searchValue={table.filters.searchValue} setSearchValue={setSearchCallback}
					                      placeholder={'Search Candidate'}/>
					</AuthGuard>
				</div>
				<button className={`manage-team__top__svg ${orderPopup ? 'active' : ''}`} ref={orderPopupRef}>
					<MemoOrderSVG setState={setOrderPopup}/>
					{
						<CSSTransition in={orderPopup} timeout={300} classNames={'order-popup-mtm'} unmountOnExit mountOnEnter>
							<AssignmentCandidateTableOrder setOrderPopup={setOrderPopup}/>
						</CSSTransition>
					}
				</button>
			</div>
			{
				table.isLoading 
				? <SmallLoaderComponent/>
				:
				table.emptySearch || table.emptyTable ? (
						table.emptySearch ? <NoResultsSearch limit={table.pagination.limit} resetFunc={resetFunc}/>
							: <TableEmpty handler={emptyTableCallback} cta={''} title={'No Candidates Assigned'}
							              desc={'There are currently no candidates assigned to this assignment.'}/>
					)
					:
					<>
		<AuthGuard module='assessment' permission='view'>
						<table className='table' ref={tableRef} onScroll={(e: any) => {
							if (e.target.scrollLeft > 10 && !horizontalScrollState) setHorizontalScrollState(() => true)
							else if (e.target.scrollLeft < 10 && horizontalScrollState) setHorizontalScrollState(() => false)
						}}>
							<thead className="table__thead">
							{
								table.tabFilter.map((item) => {
									return item.active &&
                      <td className={`table__td  table__td--thead jobs-table__column__default`}
                          key={item.id}>
                          <TheadItemComponent title={item.displayName} handler={item.dbName ? tdOrderCallback : null}
                                              dbName={item.dbName}/>
                      </td>
								})
							}

							</thead>
							 <AssignmentCandidateTBody horizontalScrollState={horizontalScrollState}/>
						</table>

						<div className='table-mobile'>
							{
								table.tableItems.map(item => {
									return <AssignmentCandidateMobileTable key={item.id} item={item}/>
								})
							}
						</div> 
						<PaginationComponent
							limit={table.pagination.limit}
							currentPage={table.pagination.currentPage}
							totalCount={table.pagination.totalCount}
							setCurrentPage={setManageAssignmentScreenHandler}
							setLimit={setLimitHandler}
							filters={table.filters}
						/>
					</AuthGuard>
					</>
			}
		</div>
	</section>
}
export default memo(AssignmentCandidateScreen)

export const setManageAssignmentScreenHandler = (page: number, limit: number, filters: any) => {
	store.dispatch(setCurrentPageAssignmentCandidate(page))
	store.dispatch(setIsLoadingAssignmentCandidate(true))
	const getData = async () => {
		await axios(`${API_RECRUITMENT}/api/round/get-all/${paramData?.jobId}?limit=${limit}
		&ids=${paramData.candidateIds}
		${filters.candidateId.map(item => `&candidateId=${item?.value}`).join('')}
		&search=${encodeURIComponent(filters.searchValue)}
		&offset=${(page - 1) * limit}
		&sortType=${filters.sortType}
		&sortBy=${filters.sortBy}`, getConfig()).then(res => {
			store.dispatch(setTotalCountAssignmentCandidate(res.data.count))
			const items = transformArrayToTableData(res.data.rows)
			store.dispatch(setTableItemsAssignmentCandidate(items))
			if (items.length === 0) {
				if (filters.searchValue === '' || filters.candidateId.length===0 )
				{
					store.dispatch(setEmptyTableAssignmentCandidate(true))
				} else {
					store.dispatch(setEmptySearchAssignmentCandidate(true))
				}
			} else {
				store.dispatch(setEmptySearchAssignmentCandidate(false))
				store.dispatch(setEmptyTableAssignmentCandidate(false))
			}
		})
	}
	getData().finally(() => store.dispatch(setIsLoadingAssignmentCandidate(false)))
}
const setLimitHandler = (value: any) => {
	store.dispatch(setLimitAssignmentCandidate(value))
}
const transformArrayToTableData = (array: any[]): any[] => {
	return array.map((item) => ({
	  id: item.id,
	  name: `${item?.user?.firstname} ${item?.user?.lastname}`,
	  email: item?.user?.email,
	  userId: item?.user?.id,
	  rounds: item?.rounds,
	}));
  };
  
const resetFunc = (pagination) => {
	store.dispatch(setCandidateIdsAssignmentCandidate([]))
	store.dispatch(setSearchValueAssignmentCandidate(''))
	store.dispatch(setSortByAssignmentCandidate('id'))
	store.dispatch(setSortTypeAssignmentCandidate('DESC'))
	setManageAssignmentScreenHandler(1, pagination, defaultFiltersAssignmentCandidateScreen)
}