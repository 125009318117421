import { memo, useState } from 'react';
import { getEnv } from "@urecruits/api";
import { AuthGuard } from '@ucrecruits/globalstyle/src/ucrecruits-globalstyle';
import { postData } from '../../../hook/http';
const { API_ASSESSMENT, API_RECRUITMENT } = getEnv()

const StartWorkflowPopup = ({onClose,popup,setRefetch,refetch}) => {
    const [errorNotification, setErrorNotification] = useState('')
    const [loading,setLoading] = useState(false)

    const onSubmit = () => {
        if(popup.jobId && popup.userId){
            setLoading(true)
            postData(`${API_RECRUITMENT}/api/subscribe/start-workflow`,{jobId:popup.jobId,userId:popup.userId}).then(data=>{
                onClose();
                setRefetch(!refetch)
            }).catch(error=>{
                setErrorNotification("Failed to start workflow.Try some time later.")
            }).finally(()=>{
                setLoading(false)
            })
        }
    }

    return (
        <AuthGuard module='assessment' permission='delete'>
            {<div className="popup">
                <div className="popup__step">
                    <div className="popup__head">
                        <p className="popup__head__headline">
                            Start Workflow ?
                        </p>
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            onClick={onClose}
                            className="popup__head__close"
                        >
                            <path d="M18 6L12 12M6 18L12 12M12 12L6 6L18 18" stroke="#C1C5CB" strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round" />
                        </svg>
                    </div>
                    <div className="popup__body">
                        <p className="popup__body__text">
                            Are your sure, You want to start the hiring process for the <span>{popup.userName}</span> ?
                        </p>
                    </div>
                    <div className="popup__bottom end">
                        <button
                            onClick={onClose}
                            className="popup__cancel"
                        >
                            Cancel
                        </button>
                        <button
                            className="popup__bottom__publish button--filled"
                            onClick={onSubmit}
                            disabled={loading}
                        >
                            Start
                        </button>
                        {
                            errorNotification.length > 0 && (
                                <p className="error-message">{errorNotification}</p>
                            )
                        }
                    </div>
                </div>
            </div>}
        </AuthGuard>
    )
}

export default memo(StartWorkflowPopup)