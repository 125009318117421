import { memo, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import defaultUser from "../../image/icon/avatar.svg";
import { getEnv } from "@urecruits/api";
import { Skeleton, Stack } from "@mui/material";
import { DashboardEmptyDataComponent } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
import fetchData from "../../hook/http";

const {API_ASSESSMENT,API_RECRUITMENT} = getEnv();

const OffersList = ({position}) => {
	const [data,setData] = useState([])
	const [isLoading,setIsLoading] = useState(true)
	const [isEmptyData,setIsEmptyData] = useState(false)

	useEffect(()=>{
		async function getInfo(){
			try{
			await fetchData(`${API_RECRUITMENT}/api/offer/company/recent`).then(data=>{
				if(!data?.length){
					setIsEmptyData(true)
				}else{
					setData(data);
				}
			})
			setIsLoading(false)
		}catch(error){
			console.log(error)
			setIsEmptyData(true)
			setIsLoading(false)
		}
		}

		getInfo().then()
	},[])

	return (
		<div className={`dashboard-list ${position}`}>
			<div className="dashboard-list__head">
				<p className="dashboard-list__head__headline">Offers</p>
                <div className="dashboard-list__links">
                    <Link to="/recruitment/offers" className="dashboard-list__head__link">View all</Link>
                </div>
			</div>
			{
				isLoading && <Stack height={"100%"}>
                    <Skeleton animation="wave" />
                </Stack>
			}
            {isEmptyData ? <DashboardEmptyDataComponent/>
				:
				<div className="dashboard-list__list">
					{data.length>0 && 
					data.map(offer=>{
						return <ShowListItem  offer={offer} key={offer.id} /> 
					})
					}
				</div>
			}
		</div>
	);
};

export default memo(OffersList);

const ShowListItem = ({offer})=>{

	const navigate = useNavigate()
	const date = new Date(offer.createdAt).toString()

	return <div className="dashboard-list__item" >
	<div className="dashboard-list__item__inner">
        <div className="dashboard-list__item__top">
            <img src={offer.user?.avatar || defaultUser} alt="" className="dashboard-list__item__avatar"/>
            <p className="dashboard-list__item__username">{(offer.user?.firstname || "" ) + " " + (offer.user?.lastname || "")}</p>
            <p style={{textTransform:"capitalize"}} className={`dashboard-list__item__status ${offer.candidateStatus === "accepted" ? "approved" : offer.candidateStatus === 'rejected'? 'rejected' : "pending"}`}>{offer.candidateStatus || 'Pending'}</p>
        </div>
		<div className="dashboard-list__item__bottom">
			{/* {roundData && 
                <p className="dashboard-list__item__dot">{roundData.title}</p>
            } */}
			<p className="dashboard-list__item__dot">{offer.job?.title || ""}</p>
			<p className="dashboard-list__item__text gray"> Sent on {date.substring(4,10)+","+date.substring(11,15)|| ""}</p>
		</div>
	</div>
	{/* <div className="dashboard-list__item__arrow" onClick={()=>navigate(`/recruitment/offers/${offer.jobId}`)}>
		<ArrowRight/>
	</div> */}
</div>
}

// const ArrowRight = memo(() => {

// 	return (
// 		<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
// 			<path d="M3 1L7 5L3 9" stroke="#B0BBCB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
// 		</svg>
// 	);
// });