import { memo, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getEnv } from "@urecruits/api";
import { useTypedSelector } from "../../../../store";
import fetchData from "../../../../hook/http";
import play from "../../../../image/icon/Vector.png";
import { SmallLoaderComponent } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
const {API_RECRUITMENT,API_ASSESSMENT}=getEnv()

const tableItemsFunc=state=>state.candidate_domain_assessment.tableItems
const tableTabsFunc=state=>state.candidate_domain_assessment.tabFilter
const ManageCandidateDomainTBody = ({horizontalScrollState}: { horizontalScrollState: boolean }) => {
	const tableItemsValues=useTypedSelector(tableItemsFunc)
	const tableTabs=useTypedSelector(tableTabsFunc)
  const [tableItems, setTableItems] = useState([]);
  const {jobId}=useParams()
  const navigate= useNavigate()
  const [loading,setLoading]=useState(true)
	setTimeout(()=>{
		setLoading(false)
	},5000)
  const handleTableValues = async () => {
    setLoading(true)
    setTableItems(prev=>[])
     await Promise.all(
      (tableItemsValues || []).map(async (item) => {
        const obj = {
          id: item.id,
          candidate: await handleCandidate(item?.candidateId),
          userId:item.candidateId,
          timeDuration: item?.timeDuration || "",
          score: item?.score || "",
          preview: item?.id,
          createdAt: await handleDate(item?.candidateId),
        };
        setTableItems((prev)=> [...prev, obj]);
      })
    );
    setLoading(false)

  };
  

  useEffect(()=>{
    handleTableValues()
  },[tableItemsValues])

  const handleDate = async (candidateId) => {
    try {
     if(candidateId !==undefined){
		const res = await fetchData(`${API_ASSESSMENT}/api/test-manager/get?jobId=${jobId}&assessmentType=Domain%20Assessment&candidateId=${candidateId}`);
		if(res?.createdAt){
			const date = new Date(res?.createdAt);
			const formattedDate = date.toLocaleString('en-US', { month: 'short', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' });
      return formattedDate;
		}
	 }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCandidate = async (id) => {
    try {
     if(id!==undefined){
		const res = await fetchData(`${API_RECRUITMENT}/api/candidate/public-candidate/user/${id}`);
		const fullName=`${res?.user?.firstname} ${res?.user?.lastname}`
    return fullName
	 }
    } catch (error) {
      console.log(error);
    }
  };
	return (
	<>
		{
			loading?<SmallLoaderComponent/>:
			<tbody className="table__tbody">
		{
			tableItems && tableItems?.map((item,index) => {	
				return (
          <tr className="table__tr" key={item.id}>
            <td
              className={`table__td sticky ${
                horizontalScrollState ? "moved" : ""
              } manage-team__column__middle`}>
              <Link to={`/recruitment/candidate-profile/${item.userId}`} className="manage-team__name">
                <p className="manage-team__name__text manage-team__text--link">{`${
                item?.candidate !== null ? item?.candidate : ""
                }`}</p>
              </Link>
            </td>
            {tableTabs.filter(i=>i.active).map((value, index) => {
              if (value.id === 2) {
                return <td
                      className="table__td actions jobs-table__column__default"
                      key={index}>
                      <div className="jobs-table__locations">
                        <p className="manage-team__text--gray7 manage-team__text jobs-table__text__no-overflow">
                          {`${item?.timeDuration}`}
                        </p>
                      </div>
                    </td>
              }
              if (value.id === 3) {
                return <td
                      className="table__td actions jobs-table__column__default"
                      key={index}>
                      <div className="jobs-table__locations">
                        <p className="manage-team__text--gray7 manage-team__text jobs-table__text__no-overflow">
                          {item?.score}
                        </p>
                      </div>
                    </td>
              }
              if (value.id === 4) {
                return <td
                      className="table__td actions jobs-table__column__default"
                      key={value.displayName}>
                      <div className="jobs-table__locations">
                        {
                          <p className="manage-team__text--gray7 manage-team__text ">
                            {item?.createdAt !== null ? item?.createdAt : ""}
                          </p>
                        }
                      </div>
                    </td>
              }
              if (value.id === 5) {
                return <td
                      className="table__td actions manage-team__column__middle"
                      key={value.displayName}>
                      {
                        <>
                          <img
                            src={play}
                            alt="resend-icon"
                            className="manageAssignment__table__icon 'manage-team__text manage-team__text--black"
                            onClick={() => {
                              navigate(
                                `/recruitment/answer-sheet/${item?.id}/${item?.candidate && item?.candidate}`
                              );
                            }}
                          />
                          <p
                            className="manage-team__text manage-team__text--green"
                            style={{ fontWeight: 600 }}>
                            Preview
                          </p>
                        </>
                      }
                    </td>
              }
            })}
          </tr>
        );
			})
		}
		</tbody>
		}
	</>)
}
export default memo(ManageCandidateDomainTBody)

