import { IPositionOption } from "../../../types/redux/position-workflow";
import { memo, useState } from "react";
import { WorkFlowEnums } from "../../../enums/workflow/WorkFlowEnums";
import { store, useTypedSelector } from "../../../store";
import { addInActiveOptions, removeActiveOptions, setDefaultValueOfAssessment } from "../../../store/reducers/positionWorkflowPopup";
import FunctionalAssessment from "./FunctionalAssessment";
import TechnicalAssessment from "./TechnicalAssesment";
const getLiveCodingFun=store=>store.position_workflow_popup.liveCoding.errors
const getTakeHomeTaskFun=store=>store.position_workflow_popup.takeHomeTask.errors
const getDomainsFunc=store=>store.position_workflow_popup.domains.errors
const DNDItem = ({item, listeners,transitionState}: { item: IPositionOption, listeners?: any,transitionState:boolean }) => {
	const [open, setOpen] = useState(false);
	const liveCodingError=useTypedSelector(getLiveCodingFun)
	const takeHomeTaskError=useTypedSelector(getTakeHomeTaskFun)
	const domainErrors=useTypedSelector(getDomainsFunc)
	return <div className={`workflow__selected__item ${transitionState?'none':''}`}
	style={{'touchAction': 'none'}}
	>

		<div className='workflow__selected__top'>
			<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"
			onClick={()=>{
				store.dispatch(addInActiveOptions(item))
				store.dispatch(removeActiveOptions(item))
				store.dispatch(setDefaultValueOfAssessment(item))
			}}
			>
				<path d="M8.00005 8H1.40039H14.5997" stroke="#999EA5" strokeWidth="1.5" strokeLinecap="round"
				      strokeLinejoin="round"/>
			</svg>
			<button className={`workflow__selected__button ${open && item.settings !== false&&!transitionState ? 'open' : ''} ${item.settings === false?'no-pointer':''} ${item.name===WorkFlowEnums.Functional_Domain_Assessment&&domainErrors.length>0?'error':''} ${item.name===WorkFlowEnums.Live_Task_Coding_Assessment&&liveCodingError.length>0 ?'error':''} ${item.name===WorkFlowEnums.Take_Home_Coding_Assessment&&takeHomeTaskError.length>0 ?'error':''}
			`} onClick={(e) => {
				e.preventDefault()
				setOpen(prev => !prev)
			}}>
				{item.name}
				{
					 item.settings !== false&&<svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M5.9999 0H1.41412C0.523211 0 0.0770421 1.07714 0.707007 1.70711L5.2928 6.29289C5.68332 6.68342 6.31648 6.68342 6.70701 6.29289L11.2928 1.70711C11.9228 1.07714 11.4766 0 10.5857 0H5.9999Z"
							fill="#999EA5"/>
					</svg>
				}
			</button>
			<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...listeners}>
				<path fillRule="evenodd" clipRule="evenodd"
				      d="M1.80005 9C1.80005 8.58579 2.13584 8.25 2.55005 8.25H15.15C15.5643 8.25 15.9 8.58579 15.9 9C15.9 9.41421 15.5643 9.75 15.15 9.75H2.55005C2.13584 9.75 1.80005 9.41421 1.80005 9Z"
				      fill="#C1C5CB"/>
				<path fillRule="evenodd" clipRule="evenodd"
				      d="M1.80005 4.5C1.80005 4.08579 2.13584 3.75 2.55005 3.75H15.15C15.5643 3.75 15.9 4.08579 15.9 4.5C15.9 4.91421 15.5643 5.25 15.15 5.25H2.55005C2.13584 5.25 1.80005 4.91421 1.80005 4.5Z"
				      fill="#C1C5CB"/>
				<path fillRule="evenodd" clipRule="evenodd"
				      d="M1.80005 13.5C1.80005 13.0858 2.13584 12.75 2.55005 12.75H15.15C15.5643 12.75 15.9 13.0858 15.9 13.5C15.9 13.9142 15.5643 14.25 15.15 14.25H2.55005C2.13584 14.25 1.80005 13.9142 1.80005 13.5Z"
				      fill="#C1C5CB"/>
			</svg>
		</div>
		{
			open&&item.name===WorkFlowEnums.Functional_Domain_Assessment&&<FunctionalAssessment/>
		}
		{
			open&&item.name===WorkFlowEnums.Live_Task_Coding_Assessment&&<TechnicalAssessment questionType={"live-task"}/>
		}
		{
			open&&item.name===WorkFlowEnums.Take_Home_Coding_Assessment&&<TechnicalAssessment questionType={"take-home-task"}/>
		}
	</div>
}

export default memo(DNDItem)