import type { PayloadAction } from '@reduxjs/toolkit';
// eslint-disable-next-line no-duplicate-imports
import { createSlice } from '@reduxjs/toolkit';
import { IAsyncCheckBoxList, ITabsFilter } from "../../types/global/global";
import { IDomainReviewAndScore } from "../../types/redux/domain-review-and-score";
import { DomainAssessmentFilterTabs, DomainReviewAndScoreTabs } from "../../enums/reviewAndScore/domainReviewAndScore";


export const defaultFiltersDomainReviewAndScoreScreen = {
	searchValue: '',
	sortBy: 'id',
	sortType: 'DESC',
	jobId:[],
}
const initialStateDomainAssessment:IDomainReviewAndScore = {
	emptySearch: false,
	emptyTable: false,
	isLoading: false,
	filters: defaultFiltersDomainReviewAndScoreScreen,
	tableItems: [],
	popupWithInstruction:{
		open:false,
		id:-1,
		instruction:'',
		title:null,
	},
	scoreTable:{
		assessmentId:null,
		jobId:null,
		assessmentName:""
	},
	fixedTab: {
		id: 1,
		displayName: DomainReviewAndScoreTabs.Name,
	},
	tabFilter: [
		{
			id: 2,
			displayName: DomainReviewAndScoreTabs.Job,
			active: true,
			dbName: ''
		},
		{
			id: 3,
			displayName: DomainReviewAndScoreTabs.Industry,
			active: true,
			dbName: ''
		},
		{
			id: 4,
			displayName: DomainReviewAndScoreTabs.Department,
			active: true,
			dbName: ''
		},
		{
			id: 5,
			displayName: DomainReviewAndScoreTabs.Candidates,
			active: true,
			dbName: ''
		},
		{
			id: 6,
			displayName: DomainReviewAndScoreTabs.AverageScore,
			active: true,
			dbName: ''
		},
	],
	pagination: {
		currentPage: 1,
		limit: 10,
		totalCount: 0
	},
	filterInfo: {
		tabs:
			[
				DomainAssessmentFilterTabs.Job,
			],
	}
}

export const DomainAssessment = createSlice({
	name: 'domain_assessment',
	initialState: initialStateDomainAssessment,
	reducers: {
		setIsLoadingDomainAssessment: (state, action: PayloadAction<boolean>) => {
			state.isLoading = action.payload
		},
		setFiltersInfoDomainAssessment: (state, action: PayloadAction<any[]>) => {
			state.filterInfo.tabs = action.payload
		},
		setLimitDomainAssessment: (state, action: PayloadAction<number>) => {
			state.pagination.limit = action.payload
		},
		setSearchValueDomainAssessment: (state, action: PayloadAction<string>) => {
			state.filters.searchValue = action.payload
		},
		setOrderDomainAssessmentReviewAndScore: (state, action: PayloadAction<Array<ITabsFilter>>) => {
			state.tabFilter = action.payload
		},
		setSortByDomainAssessment: (state, action: PayloadAction<string>) => {
			state.filters.sortBy = action.payload
		},
		setSortTypeDomainAssessment: (state, action: PayloadAction<string>) => {
			state.filters.sortType = action.payload
		},
		setEmptyTableDomainAssessment: (state, action: PayloadAction<boolean>) => {
			state.emptyTable = action.payload
		},
		setEmptySearchDomainAssessment: (state, action: PayloadAction<boolean>) => {
			state.emptySearch = action.payload
		},
		setTableItemsDomainAssessment: (state, action: PayloadAction<Array<any>>) => {
			state.tableItems = action.payload
		},
		setTotalCountDomainAssessment: (state, action: PayloadAction<number>) => {
			state.pagination.totalCount = action.payload
		},
		setCurrentPageDomainAssessment: (state, action: PayloadAction<number>) => {
			state.pagination.currentPage = action.payload
		},
		setPopupWithInstruction: (state, action: PayloadAction<{open: boolean, id: number, instruction:any, title:any}>) => {
			state.popupWithInstruction = action.payload
		},
		setScoreTable: (state, action: PayloadAction<{assessmentId: any, jobId:any, assessmentName:any}>) => {
			state.scoreTable = action.payload
		},
		setJobIdDomainAssessment: (state, action: PayloadAction<IAsyncCheckBoxList[]>) => {			
			state.filters.jobId = action.payload
		},
	},
})

export const {	
	setSortTypeDomainAssessment,
	setLimitDomainAssessment,
	setSortByDomainAssessment,
	setOrderDomainAssessmentReviewAndScore,
	setSearchValueDomainAssessment,
	setEmptyTableDomainAssessment,
	setEmptySearchDomainAssessment,
	setTableItemsDomainAssessment,
	setTotalCountDomainAssessment,
	setCurrentPageDomainAssessment,
	setPopupWithInstruction,
	setJobIdDomainAssessment,
	setScoreTable,
	setFiltersInfoDomainAssessment,
	setIsLoadingDomainAssessment
} = DomainAssessment.actions

export default DomainAssessment.reducer