export const emailPattern =
  "^(?:[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*\")@" +
  "(?:(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?|\\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\\.){3}" +
  "(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[A-Za-z0-9]:(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21-\\x5a\\53-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)\\])$";

export const validateEmail = (email: string): string | true => {
  if (!email) {
    return "Please provide an email address.";
  }

  if (email.length > 320) {
    return "The email address exceeds the maximum allowed length of 320 characters.";
  }

  if (!email.includes("@")) {
    return "The email address must contain the '@' symbol.";
  }

  const [localPart, domain] = email.split("@");

  if (!localPart) {
    return "The email address must include a valid local part before the '@' symbol.";
  }

  if (!domain) {
    return "The email address must include a valid domain after the '@' symbol.";
  }

  if (!domain.includes(".")) {
    return "The domain part of the email address must contain at least one '.' character.";
  }

  if (!email.match(emailPattern)) {
    return "The email address format is invalid. Please enter a valid email.";
  }

  return null; // Email is valid
};
