import { Fragment, useState } from "react";

export const Question = ({item}) => {
  const [open, setOpen] = useState(true);

  const optionHandler = (type, questions, index) => {
  const data = type === "text" ? questions : JSON?.parse(questions);  
  
    return (
      <Fragment>
        {type === "text" && (
          <div className="text">
            <textarea
              className="text__textarea"
              rows={5}
              defaultValue={data && data}
              placeholder="Type Answer"
              disabled
              ></textarea>
          </div>
        )}

        {type === "multiple" && (
          <Fragment>
              <div className="multiple">
                {data?.map((item, index) => {
                  return (
                    <div key={index}  className="multiple__options">
                      <input
                        type="checkbox"
                        className="checkbox-checked"
                        value={item?.id}
                        disabled
                        checked={item?.isCorrect === true} 
                      />
                      <label> {item?.name}</label>
                      <br></br>
                    </div>
                  );
                })}
              </div>
          </Fragment>
        )}

        {type === "single" && (
          <Fragment key={item.id}>
              <div className="single">
                {data?.map((item, index) => {
                  return (
                    <div key={index} style={{display:"flex",flexDirection:"row",background:item?.isCorrect === true ? "#ACD8D14D" :"",marginBottom:"6px"}}>
                     <input
                        type="radio"
                        className="custom-radio"
                        value={item?.id}
                        checked={item?.isCorrect === true  } 
                      />
                      <label> {item?.name}</label>
                      <br></br>
                    </div>
                  );
                })}
              </div>
          </Fragment>
        )}
      </Fragment>
    );
  }

  return (<>
<div className="domain">
      <div className="domain__container"  >
          <div  key={item.id}>
            <button className={`workflow__selected__button ${open ? 'open' : ''}`}
            onClick={(e) => {
              e.preventDefault()
              setOpen(prev => !prev)
            }}>
				{item.name}
				{
					<svg width="6" height="2" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M5.9999 0H1.41412C0.523211 0 0.0770421 1.07714 0.707007 1.70711L5.2928 6.29289C5.68332 6.68342 6.31648 6.68342 6.70701 6.29289L11.2928 1.70711C11.9228 1.07714 11.4766 0 10.5857 0H5.9999Z"
							fill="#999EA5"/>
					</svg>
				}
			</button>
           {open &&<div className="body">
           {optionHandler(item.type, item?.answers, item?.id)}
           </div>}
          </div>
      </div>
        
    </div>
      
  </>

  );
    }


