import { memo, useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import {
	PaginationComponent,
	SearchFieldComponent,
	TheadItemComponent,
	AuthGuard,
	useHasPermission,
	getConfig,
	SmallLoaderComponent
} from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
import { useTypedSelector } from "../store";
import { store } from "../store/index";
import {
	defaultFiltersMTM,
	setBirthSelectedMTM,
	setCreateMemberPopupMTM,
	setCurrentPageMTM,
	setDateJoinSelectedMTM,
	setDepartmentFilterMTM,
	setEducationalSelectedMTM,
	setIsLoadingMTM,
	setLimitMTM,
	setLocationFilterMTM,
	setNoUsersSearchMTM,
	setPositionFilterMTM,
	setRolesListMTM,
	setRolesSelectedMTM,
	setSearchValueMTM,
	setSortByFilterMTM,
	setSortTypeFilterMTM, setTableEmptyMTM,
	setTotalCountMTM,
	setUsersMTM
} from "../store/reducers/manageTeamMembersReducer";

import { CSSTransition } from "react-transition-group";
import useTableClickAndDragScroll from "../hook/useTableClickAndDragScroll";
import TBodyInnerMTM from "../components/ManageTeamMembers/Table/TBody/TBodyInnerMTM";
import axios from "axios";
import { IMTMUser } from "../types/redux/manage-team-members";
import DeletePopupMTM from "../components/ManageTeamMembers/Table/DeletePopup/DeletePopupMTM";
import MobileTable from "../components/ManageTeamMembers/Table/MobileTable/MobileTable";
import { getEnv } from "@urecruits/api";
import fetchData from "../hook/http";
import useClickOutside from "../hook/useClickOutside";
import { ManageTeamMembersTableTabsEnums } from "../enums/manage-team-members/ManageTeamMembersEnums";
import { MTMOrder } from "../components/HOCs/OrderPopupHOCs";
import { sortingFunc } from "../utils/sortingFuncForTables";
import TopFilterButton from "../components/Global/table/TopFilterButton";
import MemoOrderSVG from "../components/Global/table/MemoOrderSVG";
import NoResultsSearch from "../components/Global/table/NoResultsSearch";
import FilterWrapper from "../components/Global/table/FilterWrapper";
import FilterItemsMTM from "../components/ManageTeamMembers/Table/FilterPopup/FilterItemsMTM";
import ResendPopup from "../components/ManageTeamMembers/Table/ResendPopup/ResendPopup";
import TableEmpty from "../components/Global/table/TableEmpty";
import ManageTeamPopup from "../components/ManageTeamMembers/Popup/ManageTeamPopup";
import { EditMember } from "../components/HOCs/MTMPopupHoc";

const { API_RECRUITMENT } = getEnv()
const getTableMTM = store => store.team_members
const getFiltersFunc = state => state.team_members.filters
const getFilterDataFunc = state => state.team_members.filterInfo
const getPaginationFunc = state => state.team_members.pagination
const FilterItems = (state) => {
	return <FilterItemsMTM activeTab={state} />
}
const FilterPopupInnerWrap = (setState) => {
	const filter = useTypedSelector(getFiltersFunc)
	const filterData = useTypedSelector(getFilterDataFunc)
	const pagination = useTypedSelector(getPaginationFunc)
	return <FilterWrapper setFilterPopup={setState}
		filterData={filterData}
		filters={filter}
		limit={pagination.limit}
		resetFunc={resetFilters}
		submitFunc={setUsersHandlerMTM}
	>
		{FilterItems}
	</FilterWrapper>
}

const ManageTeamMembers = () => {

	const { checkUserPermission } = useHasPermission()
	const navigate = useNavigate()

	const table = useTypedSelector(getTableMTM)

	const [orderPopup, setOrderPopup] = useState(false);
	const [horizontalScrollState, setHorizontalScrollState] = useState(false);

	const orderPopupRef = useRef<null | HTMLButtonElement>(null);
	const tableRef = useRef(null);

	useLayoutEffect(() => {
		checkUserPermission('team-members', 'view') && setUsersHandlerMTM(table.pagination.currentPage, table.pagination.limit, table.filters)
	}, []);


	useEffect(() => {
		const getFiltersData = async () => {
			const rolesListData = await fetchData(`${API_RECRUITMENT}/api/company/roles`)
			store.dispatch(setRolesListMTM(rolesListData.map(item => {
				return {
					role: item.label.split("_")?.[0],
					auth0_id: item.value,
					id: item.id
				}
			}).filter(x => x.role !== 'Company Owner')))
		}
		checkUserPermission('team-members', 'view') && getFiltersData().then()

	}, []);
	const filterArray = [
		table.filters.roles, table.filters.education, table.filters.dateOfJoining, table.filters.dateOfBirth, table.filters.location,
		table.filters.position, table.filters.department, table.filters.searchValue, table.filters.sortBy, table.filters.sortType, table.pagination
	]
	useTableClickAndDragScroll(tableRef)
	useClickOutside(orderPopupRef, setOrderPopup)
	const tdOrderCallback = useCallback((value) => sortingFunc(table, value, setSortTypeFilterMTM, setSortByFilterMTM, setUsersHandlerMTM), filterArray,);
	const setSearchCallback = useCallback((value) => {
		store.dispatch(setSearchValueMTM(value))
		setUsersHandlerMTM(1, table.pagination.limit, { ...table.filters, searchValue: value })
	}, filterArray);
	const emptyTableCallback = useCallback(() => store.dispatch(setCreateMemberPopupMTM(true)), [])

	return <section>
		<Outlet />
		{
			table.deletePopupInfo.open && <DeletePopupMTM />
		}
		{
			table.resendMail.open && <ResendPopup />
		}
		{
			table.createMemberPopup && <ManageTeamPopup title={"Add New Member"} create />
		}
		{
			table.editMemberPopup.open && <EditMember title={"Edit Member"} edit id={table.editMemberPopup.id} />
		}
		<div className='table-screen-top'>
			<h2>Manage Team Members</h2>
			<AuthGuard module='team-members' permission='add'>
				<button className='button--filled table-screen-top__button' onClick={emptyTableCallback}>Add New</button>
			</AuthGuard>
		</div>
		<div className="table__wrapper">
			<div className="table__top">
				<div className='manage-team__top__left'>
					<AuthGuard module='team-members' permission='view'>
						<TopFilterButton>
							{FilterPopupInnerWrap}
						</TopFilterButton>
						<SearchFieldComponent searchValue={table.filters.searchValue} setSearchValue={setSearchCallback}
							placeholder={'Search by name, email, phone'} />
					</AuthGuard>
				</div>
				<button className={`manage-team__top__svg ${orderPopup ? 'active' : ''}`} ref={orderPopupRef}>
					<MemoOrderSVG setState={setOrderPopup} />
					{
						<CSSTransition in={orderPopup} timeout={300} classNames={'order-popup-mtm'} unmountOnExit mountOnEnter>
							<MTMOrder setOrderPopup={setOrderPopup} />
						</CSSTransition>
					}
				</button>
			</div>
			{
				table.isLoading ? <SmallLoaderComponent /> :
				table.noUsersSearch || table.tableEmpty ? (
					table.tableEmpty ? <TableEmpty
						handler={checkUserPermission('team-members', 'add') ? emptyTableCallback : () => navigate("/")}
						cta={checkUserPermission('team-members', 'add') ? 'Add New Member' : "Go to Dashboard"}
						title={'No Team Members Yet'}
						desc={checkUserPermission('team-members', 'add')  
							? "You haven't added any team members yet. Add your first team member."  
							: "No team members have been added yet."} /> :
						<NoResultsSearch limit={table.pagination.limit} resetFunc={resetFilters} />)
					:
					<AuthGuard module={'team-members'} permission='view'>
						<>
							<table className='table' ref={tableRef} onScroll={(e: any) => {
								if (e.target.scrollLeft > 10 && !horizontalScrollState) setHorizontalScrollState(() => true)
								else if (e.target.scrollLeft < 10 && horizontalScrollState) setHorizontalScrollState(() => false)
							}}>
								<thead className="table__thead">
									<td
										className={`table__td sticky ${horizontalScrollState ? 'moved' : ''} table__td--thead manage-team__column__middle`}>
										<TheadItemComponent title={table.nameTab.displayName} dbName={'firstname'} />
									</td>
									{
										table.userTabsFilter.map((item) => {
											if (item.displayName === 'Actions' && !checkUserPermission('team-members', ['edit', 'delete'])) {
												return
											}
											return item.active &&
												<td className={item.displayName === ManageTeamMembersTableTabsEnums.EMAIL ? `table__td  table__td--thead manage-team__column__middle` : `table__td  table__td--thead manage-team__column__default`}
													key={item.id}>
													<TheadItemComponent title={item.displayName}
														handler={item.dbName ? tdOrderCallback : null} dbName={item.dbName} />
												</td>
										})
									}
								</thead>
								<TBodyInnerMTM horizontalScrollState={horizontalScrollState} />
							</table>
							<MobileTable />
							<PaginationComponent
								limit={table.pagination.limit}
								currentPage={table.pagination.currentPage}
								totalCount={table.pagination.totalCount}
								setCurrentPage={setUsersHandlerMTM}
								setLimit={setLimitHandler}
								filters={table.filters}
							/>
						</>
					</AuthGuard>
			}
		</div>
	</section>
}
export const setUsersHandlerMTM = (page: number, limit: number, filters: any) => {
	store.dispatch(setCurrentPageMTM(page))
	store.dispatch(setIsLoadingMTM(true))
	const getData = async () => {
		await axios(`${API_RECRUITMENT}/api/company/members?limit=${limit}&offset=${(page - 1) * limit}
		${filters.roles.map(x => `&roles=${x.id}`).join('')}
		${filters.position.map(x => `&position=${x.id}`).join('')}
		${filters.location.map(x => `&locationId=${x.value}`).join('')}
		${filters.department.map(x => `&department=${decodeURI(x.label.replace('&', '%26'))}`).join('')}
		${filters.dateOfJoining.from ? '&dateJoiningFrom=' + filters.dateOfJoining.from : ''}
		${filters.dateOfJoining.to ? '&dateJoiningTo=' + filters.dateOfJoining.to : ''}
		${filters.dateOfBirth.from ? '&birthdayFrom=' + filters.dateOfBirth.from : ''}
		${filters.dateOfBirth.to ? '&birthdayTo=' + filters.dateOfBirth.to : ''}
		${filters.searchValue ? '&search=' + encodeURIComponent(filters.searchValue) : ''}
		${filters.education.map(x => `&degree=${x}`).join('')}
		${filters.sortBy ? '&sortBy=' + filters.sortBy : ''}
		${filters.sortType ? '&sortType=' + filters.sortType : ''}
		`, getConfig())
			.then((res) => {
				store.dispatch(setTotalCountMTM(res.data.count))
				const users = transformArrayToTableData(res.data.rows)
				store.dispatch(setUsersMTM(users))
				if (users.length === 0) {
					if (filters.searchValue === ''
						&& !filters.dateOfJoining.from
						&& !filters.dateOfJoining.to
						&& !filters.dateOfBirth.from
						&& !filters.dateOfBirth.to
						&& filters.roles.length === 0
						&& filters.education.length === 0
						&& filters.location.length === 0
						&& filters.position.length === 0
						&& filters.department.length === 0
					) {
						store.dispatch(setTableEmptyMTM(true))
					} else {
						store.dispatch(setNoUsersSearchMTM(true))
					}
				} else {
					store.dispatch(setTableEmptyMTM(false))
					store.dispatch(setNoUsersSearchMTM(false))
				}
			})
	}
	getData().finally(()=>store.dispatch(setIsLoadingMTM(false)))
}
const setLimitHandler = (value: any) => {
	store.dispatch(setLimitMTM(value))
}

const transformArrayToTableData = (array: any): Array<IMTMUser> => {
	return array.map(item => {
		return {
			id: item.id,
			userId: item.user?.id,
			firstname: item.user?.firstname,
			lastname: item.user?.lastname,
			middlename: item.user?.middlename,
			avatar: item.user?.avatar,
			email: item.officeEmail,
			phone: item.officePhone,
			department: item.department,
			jobTitle: item.position?.label,
			jobLocation: item?.location?.city ? `${item.location?.city}, ${item.location?.state}` : '',
			educational: item.degree,
			dateOfJoining: item.dateJoining,
			dateOfBirth: item.birthday,
			status: item.profileStatus,
			address: item.currentCity,
			role: item.user?.roles?.map(value => {
				return value.label
			}),
		}
	})
}

export default memo(ManageTeamMembers)


const resetFilters = (pagination) => {
	store.dispatch(setRolesSelectedMTM([]))
	store.dispatch(setEducationalSelectedMTM([]))
	store.dispatch(setBirthSelectedMTM({ from: null, to: null }))
	store.dispatch(setDateJoinSelectedMTM({ from: null, to: null }))
	store.dispatch(setLocationFilterMTM([]))
	store.dispatch(setDepartmentFilterMTM([]))
	store.dispatch(setPositionFilterMTM([]))
	store.dispatch(setSortByFilterMTM('id'))
	store.dispatch(setSortTypeFilterMTM('ASC'))
	store.dispatch(setSearchValueMTM(''))
	setUsersHandlerMTM(1, pagination, defaultFiltersMTM)
}

