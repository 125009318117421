import type { PayloadAction } from '@reduxjs/toolkit';
// eslint-disable-next-line no-duplicate-imports
import { createSlice } from '@reduxjs/toolkit';
import { IJobDetailView } from "../../types/redux/job-detail-view";
import { IAsyncCheckBoxList, ITabsFilter } from "../../types/global/global";
import { DetailViewJobFilters, DetailViewJobMatched } from "../../enums/detail-view-job/detail-view-job-matched";


export const defaultFiltersDetailViewJobs = {
	searchValue: '',
	sortBy: 'id',
	sortType: 'ASC',
	location: [],
	education: [],
	skills: [],
	experience: [0,50],
	salary_year: [0,1000000],
	percentage: [0,100],
	status:[],
	applyJob:false
}
const initialStateMTM: IJobDetailView = {
	title:'',
	emptySearch: false,
	emptyTable: false,
	filters: defaultFiltersDetailViewJobs,
	tableItems: [],
	fixedTab: {
		id: 1,
		displayName: DetailViewJobMatched.NAME,
	},
	selectedItems: [],
	tabFilter: [
		{
			id: 2,
			displayName: DetailViewJobMatched.PERCENT,
			active: true,
			dbName: 'match'
		},
		{
			id: 3,
			displayName: DetailViewJobMatched.Location,
			active: true,
			dbName: ''
		},
		{
			id: 4,
			displayName: DetailViewJobMatched.Experience,
			active: true,
			dbName: ''
		},
		{
			id: 5,
			displayName: DetailViewJobMatched.Salary,
			active: true,
			dbName: ''
		},
		{
			id: 6,
			displayName: DetailViewJobMatched.Phone,
			active: true,
			dbName: ''
		},
		{
			id: 7,
			displayName: DetailViewJobMatched.Email,
			active: true,
			dbName: ''
		},
		{
			id: 9,
			displayName: DetailViewJobMatched.Status,
			active: true,
			dbName: 'status'
		},
		{
			id: 10,
			displayName: DetailViewJobMatched.Score,
			active: true,
			dbName: 'totalScores'
		},
		{
			id: 11,
			displayName: DetailViewJobMatched.Actions,
			active: true,
			dbName: ''
		},
	],
	pagination: {
		currentPage: 1,
		limit: 10,
		totalCount: 0
	},
	filterInfo: {
		tabs:
			[
				DetailViewJobFilters.Location,
				DetailViewJobFilters.Experience,
				DetailViewJobFilters.Salary,
				DetailViewJobFilters.Education,
				DetailViewJobFilters.Key_Skills,
				DetailViewJobFilters.Percentage,
				DetailViewJobFilters.Status
			],
	},
	schedulePopup:{
		recruiterId: null,
		candidateId: null,
		jobId: null,
		roundName: null,
		eventId: null,
		eventTitle: null,
		interviewers: null,
	}
}

export const jobsDetailView = createSlice({
	name: 'jobs_matched',
	initialState: initialStateMTM,
	reducers: {
		setTitleDetailViewJob: (state, action: PayloadAction<string>) => {
			state.title=action.payload
		},
		setSchedulePopupDetailViewJob: (state, action: PayloadAction<any>) => {
			state.schedulePopup = {...state.schedulePopup, ...action.payload}
		},
		setApplyJobDetailViewJob: (state, action: PayloadAction<boolean>) => {
			state.filters.applyJob = action.payload
		},
		setEmptyTableDetailViewJob: (state, action: PayloadAction<boolean>) => {
			state.emptyTable = action.payload
		},
		seTableItemsDetailViewJob: (state, action: PayloadAction<Array<any>>) => {
			state.tableItems = action.payload
		},
		setSkillsFilterDetailViewJob: (state, action: PayloadAction<Array<string>>) => {
			state.filters.skills = action.payload
		},
		setPercentFilterDetailViewJob: (state, action: PayloadAction<Array<number>>) => {
			state.filters.percentage = action.payload
		},
		setEducationalFilterDetailViewJob: (state, action: PayloadAction<Array<string>>) => {
			state.filters.education = action.payload
		},
		setSalaryYearFilterDetailViewJob: (state, action: PayloadAction<Array<number>>) => {
			state.filters.salary_year = action.payload
		},
		setExperienceFilterDetailViewJob: (state, action: PayloadAction<Array<number>>) => {
			state.filters.experience = action.payload
		},
		setSelectedItemsDetailViewJob: (state, action: PayloadAction<Array<number>>) => {
			state.selectedItems = action.payload
		},
		setStatusFilterDetailViewJob: (state, action: PayloadAction<Array<string>>) => {
			state.filters.status = action.payload
		},
		setLocationFilterDetailViewJob: (state, action: PayloadAction<Array<IAsyncCheckBoxList>>) => {
			state.filters.location = action.payload
		},
		setEmptySearchDetailViewJob: (state, action: PayloadAction<boolean>) => {
			state.emptySearch = action.payload
		},
		setSearchValueDetailViewJob: (state, action: PayloadAction<string>) => {
			state.filters.searchValue = action.payload
		},
		setSortByDetailViewJob: (state, action: PayloadAction<string>) => {
			state.filters.sortBy = action.payload
		},
		setSortTypeDetailViewJob: (state, action: PayloadAction<string>) => {
			state.filters.sortType = action.payload
		},
		setOrderDetailViewJobs: (state, action: PayloadAction<Array<ITabsFilter>>) => {
			state.tabFilter = action.payload
		},
		setTotalCountDetailViewJobs: (state, action: PayloadAction<number>) => {
			state.pagination.totalCount = action.payload
		},
		setLimitDetailViewJobs: (state, action: PayloadAction<number>) => {
			state.pagination.limit = action.payload
		},
		setCurrentPageDetailViewJobs: (state, action: PayloadAction<number>) => {
			state.pagination.currentPage = action.payload
		},
	},
})


export const {
	setTitleDetailViewJob,
	setApplyJobDetailViewJob,
	setEmptyTableDetailViewJob,
	seTableItemsDetailViewJob,
	setSkillsFilterDetailViewJob,
	setPercentFilterDetailViewJob,
	setEducationalFilterDetailViewJob,
	setSalaryYearFilterDetailViewJob,
	setExperienceFilterDetailViewJob,
	setLocationFilterDetailViewJob,
	setSelectedItemsDetailViewJob,
	setLimitDetailViewJobs,
	setTotalCountDetailViewJobs,
	setCurrentPageDetailViewJobs,
	setOrderDetailViewJobs,
	setSortTypeDetailViewJob,
	setSortByDetailViewJob,
	setEmptySearchDetailViewJob,
	setSearchValueDetailViewJob,
	setStatusFilterDetailViewJob,
	setSchedulePopupDetailViewJob
} = jobsDetailView.actions

export default jobsDetailView.reducer