import { useState } from "react";
import { handleSendRequest } from "../../screen/OfferPreviewScreen";
import { useParams } from "react-router";

export const SaveOfferModal = ({ setModal, offerId, candidateName }) => {
  const { jobId, userId } = useParams();
  const [isLoading,setIsLoading] = useState(false);
  const handleSubmit=()=>{
    if(isLoading) return;
    setIsLoading(true)
    handleSendRequest(offerId, setModal, jobId, userId).then(data=>setIsLoading(false))
  }
  return (
    <>
      <div className="modal__body__signature">
        <p className="modal__body__text">
          Would you like to proceed with sending this offer to {candidateName}?
        </p>
      </div>
      <div className="modal__actions">
        <button
          className="modal__actions__cancel__text"
          onClick={() => setModal(false)}
        >
          Cancel
        </button>
        <button
          className="modal__actions__send"
          disabled={isLoading}
          onClick={handleSubmit}
        >
          Save & Send
        </button>
      </div>
    </>
  );
};
