import { memo, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import defaultUser from "../../image/temp-user.png";
import { getEnv } from "@urecruits/api";
import { Skeleton, Stack } from "@mui/material";
import { DashboardEmptyDataComponent } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
import fetchData from "../../hook/http";

const {API_ASSESSMENT,API_RECRUITMENT} = getEnv();
const BackgroundScreeningList = ({position}) => {
	const [data,setData] = useState([])
	const [isLoading,setIsLoading] = useState(true)
	const [isEmptyData,setIsEmptyData] = useState(false)

	useEffect(()=>{
		async function getInfo(){
			try{
			const list = await fetchData(`${API_RECRUITMENT}/api/background/company`).then(data=>data?.rows)
			if(list?.length){
				setData(list);
			}else{
				setIsEmptyData(true)
			}
			setIsLoading(false)
			}catch(error){
				console.log(error)
				setIsEmptyData(true)
				setIsLoading(false)
			}
		}

		getInfo().then()
	},[])

	return (
		<div className={`dashboard-list ${position}`}>
			<div className="dashboard-list__head">
				<p className="dashboard-list__head__headline">Background Screening</p>
                <div className="dashboard-list__links">
                    <Link to="/recruitment/background-screening/jobs" className="dashboard-list__head__link">more</Link>
                </div>
			</div>
			{
				isLoading && <Stack height={"100%"}>
                    <Skeleton animation="wave" />
                </Stack>
			}
            {isEmptyData ? <DashboardEmptyDataComponent/>
			:
				<div className="dashboard-list__list">
					{data.length>0 && 
						data.map(background=>{
							return <ShowListItem  background={background} key={background.id} /> 
						})
					}
			</div>
			}
		</div>
	);
};

export default memo(BackgroundScreeningList);

const ShowListItem = ({background})=>{

	const navigate = useNavigate()
	const date = new Date(background.createdAt).toString()

	return <div className="dashboard-list__item" >
	<div className="dashboard-list__item__inner">
        <div className="dashboard-list__item__top">
            <img src={background.users?.avatar || defaultUser} alt="" className="dashboard-list__item__avatar"/>
            <p className="dashboard-list__item__username">{(background.users?.firstname || "" ) + " " + (background.users?.lastname || "")}</p>
            <p className={`dashboard-list__item__status ${background.BGV_status === "Verified" ? "approved" : background.BGV_status === 'Rejected'? 'rejected' : "pending"}`}>{background.BGV_status || 'Pending'}</p>
        </div>
		<div className="dashboard-list__item__bottom">
			<p className="dashboard-list__item__dot">{background.vendor || ""}</p>
			<p className="dashboard-list__item__text"> Sent on {date.substring(4,10)+","+date.substring(11,15)|| ""}</p>
		</div>
	</div>
	{/* <div className="dashboard-list__item__arrow" onClick={()=>navigate(`/recruitment/background-screening/candidates/${background.jobId}`)}>
		<ArrowRight/>
	</div> */}
</div>
}

// const ArrowRight = memo(() => {

// 	return (
// 		<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
// 			<path d="M3 1L7 5L3 9" stroke="#B0BBCB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
// 		</svg>
// 	);
// });