import { Link } from "@mui/material";
import { getEnv } from "@urecruits/api";
import { useEffect, useState } from "react";
import fetchData from "../../api/fetchDataApi";
import { postData } from "../../hook/http";
import document from "../../image/document.svg";
import Loader from "../Loader/Loader";
const { API_RECRUITMENT } = getEnv();
const formatDate = (date) => {
  const updatedDate = new Date(date);
  return updatedDate.toLocaleString("en-US", {
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });
};


const MailPreview = ({
  selectedMailId,
  setIsMailPreviewOpen,
  state = "inbox",
}) => {
  const [mailData, setMailData] = useState({
    from: "",
    subject: "",
    body: "",
    date: "",
    to: "",
    s3File: "",
    type: "",
  });
  const [loading, setLoading] = useState(false);
  const [provider, setProvider] = useState("gmail");
  const [fileObject1, setFileObject1] = useState(null);
  const [fileType, setFileType] = useState("");

  const getProvider = async () => {
    const apiUrl = `${API_RECRUITMENT}/api/integrations/provider`;
    try {
      await fetchData(apiUrl).then((result) => {
        result.mailbox !== "" && setProvider(result.mailbox);
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProvider();
  }, []);

  useEffect(() => {
    const fetchDataAndHandleS3File = async () => {
      try {
        setLoading(true);
        const data = await fetchData(
          `${API_RECRUITMENT}/api/${provider}/getById/${selectedMailId}`
        );
  
        if (data.data && data.data?.s3File) {          
          const formData = {
            key: data.data.s3File,
          };
  
          const s3FileData = await postData(
            `${API_RECRUITMENT}/api/file/get-private`,
            formData
          );
          const date = formatDate(new Date(data.data?.date));
          setMailData({
            ...data.data,
            date,
            s3File: s3FileData,
          });
        }else {
          const date = formatDate(new Date(data.data?.date));
          setMailData({...data.data,date});
        }
  
        setLoading(false);
      } catch (error) {
        console.error("Error fetching mail data:", error);
        setLoading(false);
      }
    };

    if (provider) {
      fetchDataAndHandleS3File();
    }
  }, [API_RECRUITMENT, provider, selectedMailId]);

  const handleFile = async (s3File) => {
    try {
      const response = await fetch(`${s3File}`);
      if (!response.ok) {
        throw new Error(`Failed to fetch file: ${response.statusText}`);
      }
      const arrayBuffer = await response.arrayBuffer();
      const fileObject = new Blob([arrayBuffer]);
      setFileObject1(fileObject);
    } catch (error) {
      console.error("Error handling file:", error);
    }
  };

  useEffect(() => {
    if(mailData?.type !==""){
      const imageTypes = ['jpg', 'jpeg', 'png', 'gif'];
      const videoTypes = ['mp4', 'avi', 'mkv'];
      const documentTypes = ['pdf', 'doc', 'docx'];
      if (imageTypes.includes(mailData?.type)) {
        setFileType('image');
      } else if (videoTypes.includes(mailData?.type)) {
        setFileType('video');
      } else if (documentTypes.includes(mailData?.type)) {
        setFileType('document');
      } else {
        setFileType('unknown');
      }
    }
  }, [mailData?.type]);
  
  
  useEffect(() => {
    const { s3File } = mailData || {};
    if (s3File) {
      handleFile(s3File);
    }
  }, [mailData]);  
  
  const renderHTML = (htmlString) => {
    if(htmlString !==""){
        const doc = JSON?.parse(htmlString);
        return { __html: doc };}
    }

  return loading ? (
    <div className="loader">
      <Loader />
    </div>
  ) : (
    <div className="mail-preview-container">
      <div className="mail-preview-header">
        <div className="mail-preview-heading">
          <span className="mail-preview-heading-left">{mailData?.subject}</span>
          <span className="mail-preview-heading-right">{mailData?.date}</span>
        </div>
        <div className="mail-preview_input-box">
          <span className="mail-preview_sender-mail-input-text">{state === "draft" ? "To" : "From"}</span>
          <input
            value={state === "draft" ?mailData?.to :mailData?.from}
            className="mail-preview_sender-mail-input"
          />
        </div>
        <div className="mail-preview_input-box">
          <span className="mail-preview_sender-mail-input-text">Subject</span>
          <input
            value={mailData?.subject}
            className="mail-preview_sender-mail-input"
          />
        </div>
      </div>
      <div className="mail-preview-body-container">
        <div
          className="mail-preview-body-description"
          dangerouslySetInnerHTML={
            provider === "gmail"
              ? renderHTML(mailData.body)
              : { __html: mailData?.body }
          }></div>
        {mailData?.s3File && (
          <div className="mail-preview-body-attachment">
            <hr />
            <h6>Attachment : </h6>
            {mailData?.s3File && fileType === "image" ? (
              <div>
                <Link href={mailData?.s3File} title="Click to view Image">
                  <img
                    src={fileObject1 && URL.createObjectURL(fileObject1)}
                    alt="Image"
                    style={{width:'50%'}}
                  />{" "}
                </Link>
              </div>
            ) : (
              <div className="doc">
                <Link href={mailData?.s3File} title="Click to view document">
                  <img className="docImage" src={document} alt="Document" />
                </Link>
              </div>
            )}
          </div>
        )}
      </div>
      {/* {state === "draft" && (
        <SendMail action="draft" isOpen={true} draftId={selectedMailId} />
      )} */}
    </div>
  );
};

export default MailPreview;
