import { Outlet, useNavigate } from "react-router-dom";
import {
	PaginationComponent,
	SearchFieldComponent,
	TheadItemComponent,
	AuthGuard,
	useSubscription
} from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";
import TableEmpty from "../components/Global/table/TableEmpty";
import NoResultsSearch from "../components/Global/table/NoResultsSearch";
import { store, useTypedSelector } from "../store";
import { memo, useCallback, useLayoutEffect, useState } from "react";
import fetchData from "../hook/http";
import { sortingFunc } from "../utils/sortingFuncForTables";
import { IMTMUser } from "../types/redux/manage-team-members";
import {
	defaultWorkflowFilters, setCurrentPageReviewAndScore, setItemsReviewAndScore, setLimitReviewAndScore, setNoItemsSearchReviewAndScore,
	setSearchFilterReviewAndScore,
	setSortByFilterReviewAndScore,
	setSortTypeFilterReviewAndScore, setTableEmptyReviewAndScore, setTotalCountReviewAndScore
} from "../store/reducers/reviewAndScoreReducer";
import { getEnv } from "@urecruits/api";
import TBodyReviewAndScore from "../components/reviewAndScore/table/TBodyWorkflow";
import PopupWithList from "../components/reviewAndScore/table/PopupWithList";
import DeletePopupReviewAndScore from "../components/reviewAndScore/table/DeletePopup";
import WorkflowMobileTBody from "../components/reviewAndScore/table/WorkflowMobileTBody";
const {API_RECRUITMENT} = getEnv()

const getTableReviewAndScore = state => state.review_and_score
const ReviewAndScoreScreen = () => {
	const navigate = useNavigate()
	const table = useTypedSelector(getTableReviewAndScore)
	const [horizontalScrollState, setHorizontalScrollState] = useState(false);
	const { checkCurrentPackageType } = useSubscription()
	const hasAssessmentPackage = (checkCurrentPackageType(['assessment','fullcycle']))

	useLayoutEffect(() => {
		hasAssessmentPackage && setItemsReviewAndScores(table.pagination.currentPage, table.pagination.limit, table.filters)
	}, [hasAssessmentPackage]);

	const filterArray = [table.filters.searchValue, table.filters.sortBy, table.filters.sortType, table.pagination]

	const tdOrderCallback = useCallback((value) => sortingFunc(table, value, setSortTypeFilterReviewAndScore, setSortByFilterReviewAndScore, setItemsReviewAndScore), filterArray);
	const setSearchCallback = useCallback((value) => {
		store.dispatch(setSearchFilterReviewAndScore(value))
		hasAssessmentPackage && setItemsReviewAndScores(1, table.pagination.limit, {...table.filters, searchValue: value})
	}, filterArray);

	const emptyTableCallback = useCallback(() => navigate('/recruitment/review-score'), [])

	return <AuthGuard module={['assessment','job-post']} permission='view' option="AND">
	<section>
		<Outlet/>
		{
			table.popupWithList.open&&<PopupWithList/>
		}
		{
			table.deletePopup.open&&<DeletePopupReviewAndScore/>
		}
		<div className="table__wrapper">
			<div className="table__top">
				<div className='manage-team__top__left'>
					<SearchFieldComponent searchValue={table.filters.searchValue} setSearchValue={setSearchCallback}
					                      placeholder={'Search by Candidate first name'}/>
				</div>
			</div>
			{
				table.emptySearch || table.emptyTable ? (
						table.emptyTable ?
							<TableEmpty handler={emptyTableCallback} cta={''} title={'Table is empty'}
							            desc={'Review and score table is empty'}/> :
							<NoResultsSearch limit={table.pagination.limit} resetFunc={resetFilters}/>)
					:
					<>
						<table className='table' onScroll={(e: any) => {
							if (e.target.scrollLeft > 10 && !horizontalScrollState) setHorizontalScrollState(() => true)
							else if (e.target.scrollLeft < 10 && horizontalScrollState) setHorizontalScrollState(() => false)
						}}>
							<thead className="table__thead">
							<td
								className={`table__td sticky ${horizontalScrollState ? 'moved' : ''} table__td--thead manage-team__column__middle`}>
								<TheadItemComponent title={table.fixedTab.displayName} dbName={'title'}  handler={tdOrderCallback}/>
							</td>
							{
								table.tabFilter.map((item) => {
									return item.active &&
                      <td className={`table__td  table__td--thead manage-team__column__middle`} key={item.id}>
                          <TheadItemComponent title={item.displayName}
                                              handler={item.dbName && tdOrderCallback } dbName={item.dbName}/>
                      </td>
								})
							}
							</thead>
										<TBodyReviewAndScore horizontalScrollState={horizontalScrollState} />
						</table>
						<div className='table-mobile'>
							{
								table.tableItems.map(item => {
									return <WorkflowMobileTBody key={item.id} item={item}/>
								})
							}
						</div> 
						<PaginationComponent
							limit={table.pagination.limit}
							currentPage={table.pagination.currentPage}
							totalCount={table.pagination.totalCount}
							setCurrentPage={setItemsReviewAndScores}
							setLimit={setLimitHandler}
							filters={table.filters}
						/>
					</>
			}
		</div>
	</section>
	</AuthGuard>
}
export default memo(ReviewAndScoreScreen)

export const setItemsReviewAndScores = (page: number, limit: number, filters: any) => {
		store.dispatch(setCurrentPageReviewAndScore(page))
	const getData = async () => {
		fetchData(`${API_RECRUITMENT}/api/playback?limit=${limit}&offset=${(page - 1) * limit}
		${filters.searchValue ? '&search=' + encodeURIComponent(filters.searchValue) : ''}
		${filters.assessmentType ? '&assessmentType=' + encodeURIComponent(filters.assessmentType) : ''}`)
			.then((res) => {
				store.dispatch(setTotalCountReviewAndScore(res.total))
				const items = transformArrayToTableData(res?.data)
				store.dispatch(setItemsReviewAndScore(items))
				if (items.length === 0) {
					if (filters.searchValue === '') {
						store.dispatch(setTableEmptyReviewAndScore(true))
					} else {
						store.dispatch(setNoItemsSearchReviewAndScore(true))
					}
				} else {
					store.dispatch(setTableEmptyReviewAndScore(false))
					store.dispatch(setNoItemsSearchReviewAndScore(false))
				}
			})
	}
	getData().then()
}
const setLimitHandler = (value: any) => {
	store.dispatch(setLimitReviewAndScore(value))
}

const transformArrayToTableData = (array: any): Array<IMTMUser> => {
	return array?.map(item => {
		return {
			id:item.id,
			questionId: item?.questionId,
			userId: item.candidateId,
			candidateName: item.candidateName,
			jobTitle: item.title,
        	playbackId: item.playbackId,
        	assessmentType: item.assessmentType,
			assessmentId:item?.assessmentId,
			jobId:item?.jobId,
			questionName:item?.questionName,
		}
	})		
}


const resetFilters = (pagination) => {
	store.dispatch(setSortByFilterReviewAndScore('id'))
	store.dispatch(setSortTypeFilterReviewAndScore('DESC'))
	store.dispatch(setSearchFilterReviewAndScore(''))
	setItemsReviewAndScores(1, pagination, defaultWorkflowFilters)
}